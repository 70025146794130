<template>
  <Modal v-model="showModal" :mask-closable="false" width="1500px">
    <div slot="header">{{projectName}}------项目能耗详情</div>
    <div>
      开始时间：
      <DatePicker v-model="form.start" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="开始时间" style="width: 175px;margin-right:10px"></DatePicker>
      结束时间：
      <DatePicker v-model="form.end" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束时间" style="width: 175px;margin-right:10px"></DatePicker>
      <Button type="primary" @click="ajaxList(true)">搜索</Button>
      <div class="auth-tab mt10" ref="xTable1">
        <vxe-table border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}" :key="tabHeight">
          <vxe-column type="seq" width="60" title="序号"></vxe-column>
          <vxe-column field="day" width="160" title="安装日期"></vxe-column>
          <vxe-column field="powerboxAlarm" title="配电柜报警数量"></vxe-column>
          <vxe-column field="powerboxOnline" title="配电柜在线数量"></vxe-column>
          <vxe-column field="powerboxRepair" title="配电柜维修数量"></vxe-column>
          <vxe-column field="lightAlarm" title="单灯报警数量"></vxe-column>
          <vxe-column field="lightOnline" title="单灯在线数量"></vxe-column>
          <vxe-column field="lightRunning" title="单灯亮灯数量"></vxe-column>
          <vxe-column field="lightRepair" title="单灯维修数量"></vxe-column>
          <vxe-column field="before" title="改造前能耗/KWh"></vxe-column>
          <vxe-column field="energy" title="能耗/KWh"></vxe-column>

          <vxe-column width="120" fixed="right">
            <template #header>
              <Button size="small" type="primary" style="margin-right: 5px" @click="handleAdd">新增</Button>
            </template>
            <template #default="params">
              <Button size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">修改</Button>
              <Button size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">删除</Button>
            </template>
          </vxe-column>
        </vxe-table>
        <p>
          <vxe-pager background :current-page.sync="form.index" :page-size.sync="form.size" :total="totalResult" :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="formPage">
          </vxe-pager>
        </p>
      </div>
      <virtualEnergyEdit v-model="virtualEnergyEditShow" :item="editItem" @saved="ajaxList(true)" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>

import virtualEnergyEdit from './virtualEnergyEdit'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'virtualEnergy',
  components: {
    virtualEnergyEdit
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    Id: {
      type: Number,
      default: 0
    },
    projectName: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      virtualEnergyEditShow: false,
      list: [],
      tabHeight: 300,
      totalResult: 0,
      form: {
        "projectId": 2,
        "start": "2024-10-01",
        "end": "2024-12-21",
        "index": 1,
        "size": 20
      },
      editItem: {
        isAdd: true,
        projectId: 0,
        data: {},
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        let now = new Date();
        now.setDate(now.getDate() + 1);
        this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
        now.setDate(now.getDate() - 60);
        this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
        this.form.projectId = this.Id;
        this.ajaxList(true);
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),

  },
  mounted: function () {
  },
  methods: {
    formPage({ currentPage, pageSize }) {
      this.form.index = currentPage;
      this.form.size = pageSize;
      this.ajaxList(false)
    },
    ajaxList(is) {
      if (is) { this.form.index = 1 }
      this.$axios.post(`visual/home/QueryProjectEnergy`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'totalResult', res.data.count);
        }
      });
    },
    handleAdd() {
      this.editItem.isAdd = true;
      this.editItem.data = {};
      this.editItem.projectId = this.form.projectId;
      this.virtualEnergyEditShow = true;
    },
    handleEdit(el) {
      this.editItem.isAdd = false;
      this.editItem.data = el.row;
      this.editItem.projectId = this.form.projectId;
      this.virtualEnergyEditShow = true;
    },
    handleDelete(params) {
      this.$Modal.confirm({
        title: '提示',
        content: `确定要删除吗？`,
        onOk: async () => {
          this.$axios.post(`visual/home/DeleteProjectEnergy`, { id: params.row.id }).then(res => {
            if (res.code == 0) {
              this.ajaxList();
            }
          });
        }
      });
    },
    ok: async function () {
      this.showModal = false;
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>