<template>
  <bm-control :offset="item.offset">
    <div class="tool-container">
      <div class="map-tool-drag" style="margin-right: 5px">
        <span style="margin-right: 5px">{{showLang('gis.set.position')}}</span>
        <i-switch v-model="enableMoveDevice" size="large" true-color="#1B5FA8" false-color="#6D8194" :title="showLang('gis.switch.dev.position')">
          <span slot="open">{{showLang('com.state.open')}}</span>
          <span slot="close">{{showLang('com.state.prohibit')}}</span>
        </i-switch>
        <template v-if="enableMoveDevice">
          <i-switch  v-model="batchSetPos" size="large" true-color="#1B5FA8" false-color="#6D8194" class="ml10">
            <span slot="open">{{showLang('com.op.batch')}}</span>
            <span slot="close">{{showLang('com.op.single')}}</span>
          </i-switch>
        </template>
      </div>
      <div class="map-tool-drag" style="margin-right: 5px" v-if="item.setLine">
        <span style="margin-right: 5px">{{showLang('gis.set.cable')}}</span>
        <i-switch v-model="enableEditLine" size="large" true-color="#1B5FA8" style="margin-right: 5px" false-color="#6D8194" :title="showLang('gis.switch.dev.position')">
          <span slot="open">{{showLang('com.state.open')}}</span>
          <span slot="close">{{showLang('com.state.prohibit')}}</span>
        </i-switch>
        <Button v-if="enableEditLine" size="small" type="success" style="margin-right: 5px;height: 22px" @click="addLine">新增</Button>
        <Button v-if="enableEditLine" size="small" type="primary" style="margin-right: 5px;height: 22px" @click="saveLine">保存</Button>
        <Button v-if="enableEditLine" size="small" type="error" style="height: 22px" @click="delLine">移除</Button>
      </div>
      <div class="map-tool-drag" style="margin-right: 5px" v-if="item.setLux">
        <span style="margin-right: 5px" @click="setLux">{{showLang('gis.set.optical')}}</span>
        <i-switch v-model="enableEditLux" size="large" true-color="#1B5FA8" style="margin-right: 5px" false-color="#6D8194" :title="showLang('gis.switch.dev.position')">
          <span slot="open">{{showLang('com.state.open')}}</span>
          <span slot="close">{{showLang('com.state.prohibit')}}</span>
        </i-switch>
        <Button v-if="enableEditLux" size="small" style="height: 22px" type="primary" @click="saveLux">保存</Button>
      </div>
      <!-- <div class="map-tool-drag" style="margin-right: 5px">
        <span style="margin-right: 5px">{{item.pos.lat}}</span>
      </div>
      <div class="map-tool-drag" style="margin-right: 5px">
        <span style="margin-right: 5px">{{item.pos.lng}}</span>
      </div> -->
      <div class="map-tool-drag" style="margin-right: 5px">
        <span style="margin-right: 5px">{{item.pos.zoom}}</span>
      </div>
    </div>
  </bm-control>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BmControl,
} from '@/components/vue-baidu-map-v3/components'
export default {
  components: {
    BmControl
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          setLine: false,
          setLux: false,
          offset: {
            width: 20,
            height: 30
          },
          pos: {
            lat: 0,
            lng: 0
          }
        };
      },
    },
  },
  watch: {
    enableMoveDevice(){
      this.$emit("click", {method: this.enableMoveDevice ? 'setPos' : 'fixPos',isBatch:this.batchSetPos});
    },
    batchSetPos(){
      this.$emit("click", {method: this.enableMoveDevice ? 'setPos' : 'fixPos',isBatch:this.batchSetPos});
    },
    enableEditLine(){
      this.$emit("click", {method: this.enableEditLine ? 'setLine' : 'fixLine'});
    },
    enableEditLux(){
      this.$emit("click", {method: this.enableEditLux ? 'setLux' : 'fixLux'});
    },
  },
  data() {
    return {
      enableMoveDevice: false,
      enableEditLine: false,
      enableEditLux: false,
      batchSetPos: false,
      deviceType: 'all',
      deviceArray: [
        { name: "全部", type: "all", lang: 'com.state.all' },
        { name: "配电柜", type: "powerbox", lang: 'com.device.box' },
        { name: "单灯", type: "light", lang: 'com.device.lamp' },
        { name: "光控", type: "lux", lang: 'com.device.lightControl' },
        { name: "电缆", type: "cable", lang: 'com.device.cable' },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted() {
  },
  methods: {
    setLux: function () {
      this.$emit("click", {method: 'setLux'});
    },
    setLine: function () {
      this.$emit("click", {method: 'setLine'});
    },
    addLine: function(){
      this.$emit("click", {method: 'addLine'});
    },
    delLine: function(){
      this.$emit("click", {method: 'delLine'});
    },
    saveLine: function(){
      this.$emit("click", {method: 'saveLine'});
    },
    saveLux: function(){
      this.$emit("click", {method: 'saveLux'});
    },
  }
}
</script>

<style>
.tool-container{
  /* border: solid 1px red; */
  display:  flex;
}
.map-tool-drag {
  display: flex;
  align-items: center;
  background-color: white;
  height: 26px;
  padding: 0 5px;
  /* border-radius: 3px; */
  border: solid 1px #1b5fa8;
  cursor: pointer;
}
</style>