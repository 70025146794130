<template>
  <Modal v-model="showModal" :mask-closable="false" width="700px">
    <div slot="header">安装详情</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="200">
      <FormItem prop="name" label="安装日期" style="margin-right: 15px">
        <div class="flex-row">
          <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.day" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
        </div>
      </FormItem>
      <FormItem prop="powerboxAlarm" label="当日配电柜报警数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.powerboxAlarm" placeholder="当日配电柜报警数量" style="width:400px; "><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="powerboxOnline" label="当日配电柜在线数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.powerboxOnline" placeholder="当日配电柜在线数量" style="width:400px; "><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="powerboxRepair" label="当日配电柜维修数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.powerboxRepair" placeholder="当日配电柜维修数量" style="width:400px; "><span slot="append">%</span></Input>
        </div>
      </FormItem>
    
      <FormItem prop="lightAlarm" label="当日单灯报警数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.lightAlarm" placeholder="当日单灯报警数量" style="width:400px; "><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="lightOnline" label="当日单灯在线数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.lightOnline" placeholder="当日单灯在线数量" style="width:400px; "><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="lightRunning" label="当日单灯亮灯数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.lightRunning" placeholder="当日单灯亮灯数量" style="width:400px; "><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="lightRepair" label="当日单灯维修数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.lightRepair" placeholder="当日单灯维修数量" style="width:400px; "><span slot="append">%</span></Input>
        </div>
      </FormItem>
      <FormItem prop="before" label="改造前能耗" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.before" placeholder="改造前能耗" style="width:400px; "><span slot="append">KWh</span></Input>
        </div>
      </FormItem>
      <FormItem prop="energy" label="当日能耗" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.energy" placeholder="当日能耗" style="width:400px; "><span slot="append">KWh</span></Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>

import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        "id": 0,
        "projectId": 1, //所在项目
        "day": "2024-10-01", //安装日期
        "powerboxAlarm": 1, //当日配电柜报警数量
        "powerboxOnline": 20, //当日配电柜在线数量
        "powerboxRepair": 20, //当日配电柜维修数量
        "before": 20, //改造前能耗
        "energy": 20, //当日能耗
        "lightAlarm": 20, //当日单灯报警数量
        "lightOnline": 20, //当日单灯在线数量
        "lightRunning": 20, //当日单灯亮灯数量
        "lightRepair": 20, //当日单灯维修数量
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isAdd = this.item.isAdd;

        if (!this.isAdd) {
          this.$set(this, 'form', this.item.data);
        } else {
          this.form.id = 0;
          this.form.day = new Date().format('yyyy-MM-dd');
          this.form.powerboxAlarm = 1;//当日配电柜报警数量
          this.form.powerboxOnline = 20;//当日配电柜在线数量
          this.form.powerboxRepair = 20;//当日配电柜维修数量
          this.form.before = 20;//改造前能耗
          this.form.energy = 20;//当日能耗
          this.form.lightAlarm = 20;//当日单灯报警数量
          this.form.lightOnline = 20;//当日单灯在线数量
          this.form.lightRunning = 20;//当日单灯亮灯数量
          this.form.lightRepair = 20;//当日单灯维修数量
        }
        this.form.projectId = this.item.projectId
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),

  },
  mounted: function () {
  },
  methods: {
    ok: async function () {
      this.$axios.post(`visual/home/SaveProjectEnergy`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel() {
      this.showModal = false;
      this.$emit('saved');
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>