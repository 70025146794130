<template>
<div class="alarm-rule-container">
  <div class="config-tree-area">
    <GroupTree />
  </div>
  <div class="device-data-area">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.device.pole.name')"
        style="width: 200px; margin-right: 10px;"></Input>
      <!-- {{showLang('com.import.cn.pole.type')}}：
      <Input type="text" v-model="filter.poleType" clearable style="width: 200px; margin-right: 10px;"></Input> -->
      杆臂数量：
      <Select :placeholder="showLang('save.select')" v-model="filter.armCount" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <template v-for="n in 20">
          <Option :value="n" :key="n">{{n}}</Option>
        </template>
      </Select>
      {{showLang('com.min.height')}}(M)：
      <Input type="text" v-model="filter.heightMin" clearable style="width: 150px; margin-right: 10px;"></Input>
      {{showLang('com.max.height')}}(M)：
      <Input type="text" v-model="filter.heightMax" clearable style="width: 150px; margin-right: 10px;"></Input>
      <Button size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <Button size="default" type="success" @click="handleExport1" style="margin: 0 5px;">导出</Button>
    </div>
    <div class="query-data-area">
      <Button v-if="funCodes(2031)" size="default" type="info" @click="getImportTemplate" style="margin: 0 5px;">{{showLang('import.template.file')}}</Button>
      <Button v-if="funCodes(2031)" size="default" type="success" @click="handleImport" style="margin: 0 5px;">{{showLang('import.pole.info')}}</Button>
      <Button v-if="funCodes(2031)" type="primary" size="default" style="margin: 0 5px" @click="fastAdd">{{showLang('com.op.entry')}}</Button>
      <Button v-if="funCodes(2032)" type="primary" style="margin-right: 5px" @click="handleBatchSetArgs('height')">{{showLang('batch.modify.height')}}</Button>
      <!-- <Button v-if="funCodes(2032)" type="primary" style="margin-right: 5px" @click="handleBatchSetArgs('poleType')">{{showLang('batch.modify.poleType')}}</Button> -->
      <Button v-if="funCodes(2032)" type="primary" style="margin-right: 5px" @click="handleBatchSetArgs('armCount')">批量修改杆臂数量</Button>
      <Button v-if="funCodes(2032)" type="primary" style="margin-right: 5px" @click="handleBatchSetArgs('group')">批量修改所在分区</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" @checkbox-change="selectChangeEvent" :data="list" height="auto"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}" @checkbox-all="selectAllEvent"
        :seq-config="{startIndex: (filter.index-1)*filter.size}" row-id="id" :key="dataRefresh">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="200" header-align="center" fixed="left"></vxe-column>
        <vxe-column field="groupName" title="所在分区" sortable width="200" header-align="center"></vxe-column>
        <vxe-column field="height" :title="showLang('com.import.cn.pole.height')" sortable width="110" header-align="center"></vxe-column>
        <vxe-column field="armCount" title="杆臂数量" sortable width="110" header-align="center"></vxe-column>
        <vxe-column field="lng" :title="showLang('com.import.cn.pole.lng')" sortable width="150" header-align="center"></vxe-column>
        <vxe-column field="lat" :title="showLang('com.import.cn.pole.lat')" sortable width="150" header-align="center"></vxe-column>
        <vxe-column field="code" :title="showLang('com.device.pole.code')" width="270" header-align="center"></vxe-column>
        <vxe-column field="location" title="安装地址" width="280" header-align="center"></vxe-column>
        <vxe-column field="buildDateName" title="安装日期" width="110" header-align="center"></vxe-column>
        <vxe-column width="200" fixed="right">
          <template #header>
            <Button v-if="funCodes(2031)" size="default" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
            <Button v-if="funCodes(2033)" size="default" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</Button>
          </template>
          <template #default="{ row }">
            <Button v-if="funCodes(2032)" type="primary" size="small" style="margin-right: 5px" @click="setPicture(row)">{{showLang('com.op.picture')}}</Button>
            <Button v-if="funCodes(2032)" type="primary" size="small" style="margin-right: 5px" @click="handleEdit(row)">{{showLang('com.op.edit')}}</Button>
            <Button v-if="funCodes(2033)" type="error" size="small" @click="handleDelete(row)">{{showLang('com.op.del')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <ModalPoleEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
      <ModalBatchAdd v-model="showBatchModal" :item="editItem" @saved="itemSaved" />
      <ModalImport v-model="showImportModal" :item="editItem" @saved="itemSaved" />
      <ModalPicture v-model="showPicture" :item="editItem" @saved="itemSaved" />
      <ModalSetArgs v-model="showArgsModal" :item="editItem" @saved="argsSaved" />
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import GroupTree from '../../common/treeGroup/Index'
import ModalPoleEdit from './ModalPoleEdit'
import ModalBatchAdd from './ModalBatchAdd'
import ModalImport from './ModalImport'
import ModalPicture from './ModalPicture'
import ModalSetArgs from './ModalSetArgs'
export default {
  name: 'ConfigLampPoleIndex',
  components: {
    ModalPoleEdit,
    ModalBatchAdd,
    ModalImport,
    ModalPicture,
    ModalSetArgs,
    GroupTree,
  },
  props: {
  },
  data() {
    return {
      list: [],
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgsModal: false,
      showImportModal: false,
      showBatchModal: false,
      showPicture: false,
      filter: {
        groupId: 0,
        name: "",
        armCount: 0,
        heightMin: 0,
        heightMax: 100,
        latMin: 0,
        latMax: 90,
        lngMin: 0,
        lngMax: 180,
        size: 20,
        index: 1,
      },
      total: 0,
      dataRefresh: 0,
      exportExcel: {
        data: [],
        isAll: false,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    groupTreeSelectedNode() {
      this.search();
    }
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    search: function(){
      this.filter.index=1;
      this.getList();
    },
    argsSaved: function(params){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let list = chks.map(p => p.id);
      params.ids = list;
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: "确定要批量编辑属性吗？",
        onOk: async () => {
          this.$axios.post(`common/pole/BatchEditPoleInfo`, params).then(res => {
            if (res.code == 0) {
              this.$Message.success(this.showLang('com.ins.success'));
              this.getList();
            }
          });
        }
      });
    },
    handleBatchSetArgs: function(type){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.wherein.One.item'));
        return;
      }
      this.editItem = {type: type};
      this.showArgsModal = true;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    selectAllEvent({ checked }) {//全选
      this.exportExcel.isAll = checked;
      if (!checked) {
        this.exportExcel.data = [];
      }
    },
    checkRowKeys() {//默认选中
      let table = this.$refs.chkTable;
      this.exportExcel.data.forEach(el => {
        this.list.forEach((ele) => {
          if (ele.id == el.id) {
            table.setCheckboxRow(table.getRowById(ele.id), true);
          }
        });
      })
      this.dataRefresh++;
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
      let leng = this.exportExcel.data.filter((el) => el.id == this.list[rowIndex].id)
       if (leng.length == 0 && this.list[rowIndex].checked) {
        this.exportExcel.data.push(this.list[rowIndex])
      }
      if(!this.list[rowIndex].checked && leng.length >0){
        this.exportExcel.data= this.exportExcel.data.filter(item=>item.id!==this.list[rowIndex].id)
      }
    },
    handleImport: function () {
      this.showImportModal = true;
    },
    getImportTemplate: function () {
      this.$axios.request({
        url: `common/pole/GetImportTemplate`,
        method: 'post',
        data: {},
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${this.showLang('com.import.temp.pole')}.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    handleExport: function () {
      // let chks = this.$refs.chkTable.getCheckboxRecords();
      if (!this.exportExcel.isAll && this.exportExcel.data.length == 0) {
        this.$Message.warning(this.showLang('com.export.err.record'));
        return;
      }
      // let list = chks.map(p => p.id);
      let ajaxData = {
        "groupId": 0,
        "stationId": 0,
        "name": this.filter.name,
        "poleType": this.filter.poleType,
        "armType": this.filter.armType,
        "heightMin": this.filter.heightMin,
        "heightMax": this.filter.heightMax,
        "latMin": this.filter.latMin,
        "latMax": this.filter.latMax,
        "lngMin": this.filter.lngMin,
        "lngMax": this.filter.lngMax,
        "isAll": this.exportExcel.isAll,
        "ids": [],
        // "url": 'http://' + this.domains.trans,
        "url": window.location.protocol + '//' + window.location.host + '/alarm/upload',
      }
      if (this.selectedNode.type == this.productCodes.station) {
        ajaxData.stationId = this.selectedNode.id;
      } else {
        ajaxData.groupId = this.selectedNode.id;
      }
      this.exportExcel.data.forEach(el => {
        ajaxData.ids.push(el.id)
      })
      this.$axios.request({
        url: `//${this.domains.trans}/station/config/ExportPoleQrCodeByFilter`,
        method: 'post',
        data: ajaxData,
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${this.showLang('com.export.fn.pole')}.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    handleExport1: function () {
      this.filter.url = `${this.domains.baseURL}common/pole/ViewPole`;
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.$axios.request({
        url: `common/pole/ExportByFilter`,
        method: 'post',
        data: this.filter,
        responseType: 'blob'
      }).then(res => {
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${this.showLang('com.export.fn.pole')}.xlsx`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    fastAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showBatchModal = true;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`common/pole/DeletePole`, { ids: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    setPicture(params) {
      this.editItem = { isAdd: false, data: params };
      this.showPicture = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.name),
        onOk: async () => {
          this.$axios.post(`common/pole/DeletePole`, { ids: [params.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.$axios.post(`common/pole/QueryByFilter`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
          setTimeout(() => {
            this.checkRowKeys();
          }, 100);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  background-color: #F6F6FA;
  border-radius: 8px;
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px !important;
  flex: none;
  border-radius: 8px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding-left: 10px;
  
  background: #fff;
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>