<template>
  <div class="home-container">
    <div class="alarm-box">
      <div class="alarm-header">欢迎使用扫码报障功能</div>
      <div class="pole-info">
        <div class="info-item">
          <div class="info-title-spot"></div>
          <div class="info-title-name">报警信息</div>
        </div>
        <div>
          <div class="info-item-header"><i class="star">*</i>路名加灯杆偏号</div>
          <Input v-model="form.id" placeholder="请输入路名加灯杆偏号" />
        </div>
        <div>
          <div class="info-item-header"><i class="star">*</i>报修内容【多选】</div>
          <div class="mt5" v-for="el,index in  multiple" :key="index">
            <Checkbox v-model="el.code">{{el.name}}</Checkbox>
            <Input v-if="index==multiple.length-1" v-model="content" placeholder="先勾选 '其他' 在描述报修内容" />
          </div>
        </div>

      </div>
    </div>
    <div class="alarm-box">
      <div class="pole-info">
        <div class="info-item">
          <div class="info-title-spot"></div>
          <div class="info-title-name">上传照片</div>
        </div>
        <div class="alarm-img-area">
          <div class="showList" v-if="url.length>0">
            <template v-for="(el,inx) in url">
              <div class="showList-box" :key="inx">
                <img ref="img" :src="el" />
              </div>
            </template>
            <div class="upload-box-Small" v-if="url.length<6">
              <img ref="img" :src="img.mochaITOM.uploadImg" class="uploadImgSmall" @click="selectImage" />
            </div>
          </div>
          <div v-else class="upload-box" @click="selectImage">
            <img ref="img" :src="img.mochaITOM.uploadImg" class="uploadImg" />
            <div class="file-open">点击上传故障图片</div>
          </div>
          <input class="file-control" ref="file" type="file" accept=".png,.jpeg,.ipg" @change="fileChanged" />
        </div>
      </div>
    </div>
    <div class="alarm-box">
      <div class="pole-info">
        <div class="info-item">
          <div class="info-title-spot"></div>
          <div class="info-title-name">联系信息</div>
        </div>
        <!-- <Input type="textarea" v-model="form.content" maxlength="100" :rows="5" show-word-limit placeholder="请输入故障现象" style="margin-bottom: 10px"></Input> -->
        <Input type="text" v-model="form.name" maxlength="20" placeholder="请输入称呼" style="margin-bottom: 10px"></Input>
        <Input type="text" v-model="form.mobile" maxlength="20" placeholder="请输入联系方式，用于回访" style="margin-bottom: 10px"></Input>
      </div>

    </div>
    <div class="alarm-upload" v-if="!success">
      <Button type="primary" :loading="loading" long @click="ok">上报故障</Button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'AlarmUpload',
  components: {
  },
  data() {
    return {
      loading: false,
      multiple: [
        { name: '路灯不亮', code: false },
        { name: '白天亮灯', code: false },
        { name: '灯杆倾斜', code: false },
        { name: '路灯损坏', code: false },
        { name: '灯杆小广告', code: false },
        { name: '其他', code: false },
      ],
      url: [],
      success: false,
      content: '',
      form: {
        id: '',
        content: '',
        file: [],
        name: '', //联系人
        mobile: '', //联系方式，用于回访
      },
    }
  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appTypes']),
  },
  beforeCreate: function () {
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    openx: function () {
      navigator.mediaDevices.getUserMedia({
        video: {
          height: 500,
          width: 500,
        }
      }).then(res => {
        this.$refs.camera.srcObject = res;
        this.$refs.camera.play();
      }).catch(err => {
        this.$Modal.error(`出错：${err}`);
      })
    },
    capture: function () {
      this.$refs.pic.getContext("2d").drawImage(this.$refs.camera, 0, 0, 500, 500)
    },
    exitx: function () {
      this.$refs.camera.srcObject.getTracks()[0].stop();
    },

    selectImage: function () {
      this.$refs.file.click();
    },
    fileChanged: function (e) {
      let file = e.target.files[0];
      this.url.push(window.webkitURL.createObjectURL(file));
      this.form.file.push(file);
    },
    ok: function () {
      let frm = new FormData();
      for (let index = 0; index < this.form.file.length; index++) {
        frm.append(`file` + index, this.form.file[index]);
      }
      let content = '';
      for (let index = 0; index < this.multiple.length; index++) {
        if (this.multiple[index].code) {
          if (this.multiple[index].name != '其他') {
            content = `${content}${content.length == 0 ? '' : '；'}${this.multiple[index].name}`;
          } else {
            content = content + '；其他:' + this.content
          }

        }
      }
      //source
      frm.append("source", this.$route.query.source);
      frm.append("code", this.form.id);
      frm.append("content", content);
      frm.append("name", this.form.name);
      frm.append("mobile", this.form.mobile);
      this.loading = true;
      this.$axios.request({
        url: `//${this.domains.trans}/station/alarm/SaveUploadAlarmByCode`,
        method: 'post',
        data: frm,
        responseType: 'json',
        myType: 'file'
      }).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.success = true;
          this.$Modal.info({ title: `报障成功`, content: '报障成功' });
        } else {
          this.$Modal.error({ title: `报障`, content: '上传报障失败' });
        }
      });
    },
  }
}
</script>
<style scoped>
.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #eff3fb;
  overflow-y: auto;
}
.alarm-box {
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.alarm-header {
  height: 22px;
  flex: none;
  font-size: 22px;
  font-weight: bold;
  color: #021629;
  line-height: 22px;
  text-align: center;
  margin-top: 20px;
}
.info-title-spot {
  width: 5px;
  height: 5px;
  background: #324252;
  border-radius: 50%;
}
.info-title-name {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #324252;
  line-height: 44px;
  margin-left: 5px;
}
.showList-box {
  width: calc(100% / 3 - 10px);
  height: 113px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
  border: 1px solid #8693a0;
}
.showList-box img {
  max-width: 100%;
  max-height: 113px;
  border-radius: 5px;
}
.upload-box-Small {
  width: calc(100% / 3 - 10px);
  height: 113px;
  text-align: center;
  background: #ecf1f7;
  border: 1px solid #8693a0;
  border-radius: 6px;
  float: left;
  margin: 5px;
  line-height: 126px;
}
.uploadImgSmall {
  width: 30px;
  height: 30px;
}
.pole-info {
  flex: none;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 25px;
}
.info-item {
  height: 35px;
  flex: none;
  display: flex;
  align-items: center;
}
.info-item-header {
  width: 160px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #68737d;
  margin-top: 10px;
}
.info-item-content {
  width: calc(100% - 60px);
  height: 35px;
  background: #ecf1f7;
  border: 1px solid #8693a0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #021629;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
}
.alarm-img-area {
  width: 100%;
  min-height: 200px;
}
.upload-box {
  padding-top: 50px;
  text-align: center;
}
.uploadImg {
  width: 40px;
  height: 40px;
}
.file-open {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #68737d;
  line-height: 44px;
}
.media-player {
  width: 100%;
  height: 100%;
}
.file-control {
  display: none;
}
.alarm-upload {
  height: 100px;
  flex: none;
  padding: 10px 30px 0 30px;
}
.star {
  color: #f00;
}
</style>
