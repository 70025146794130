<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <Button v-for="(item, idx) in btns" :key="idx" type="primary" size="default" :loading="loading" style="margin-right: 5px" @click="clickBtn(item)">{{item.name}}</Button>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="areas" height="auto" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="checkbox" width="43"></vxe-column>
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="name" title="名称" width="250" sortable header-align="center">
        </vxe-column>
        <vxe-column field="code" title="组号" width="80" sortable header-align="center">
        </vxe-column>
        <vxe-column field="count" title="单灯总数" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="online" title="在线数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="running" title="亮灯数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="alarm" title="报警数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="power" title="总额定功率(W)" width="150" sortable header-align="center">
        </vxe-column>
        <vxe-column field="send" title="发送数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="success" title="成功数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="failed" title="失败数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="timeout" title="超时数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="message" title="执行情况" sortable header-align="center">
        </vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from '@/components/control/light/args/ModalArgEdit'
export default {
  name: 'GroupLampControl4G',
  components: {
    ModalArgEdit
  },
  props: {
  },
  data() {
    return {
      timer: null,
      loading: false,
      dataRefresh: 0,
      showArgModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      areas: [],
      data: [
        { name: '测试1', num1: 500, num2: 500, num3: 300, num4: 200 },
        { name: '测试2', num1: 500, num2: 500, num3: 300, num4: 200 },
        { name: '测试3', num1: 500, num2: 500, num3: 300, num4: 200 },
        { name: '测试4', num1: 500, num2: 500, num3: 300, num4: 200 },
      ],
      btns: [
        { name: '召测数据', code: 'forceRead', pswd: false, args: false},
        { name: '调光', code: 'handSingle', pswd: true, args: true },
        { name: '开灯', code: 'openSingle', pswd: true, args: true},
        { name: '关灯', code: 'closeSingle', pswd: true, args: true},
        { name: '设置计时日表', code: 'setDayPlan1', pswd: true, args: true},
        { name: '设置准时日表', code: 'setDayPlan2', pswd: true, args: true},
        { name: '设置日表类型', code: 'setPlanType', pswd: true, args: true},
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs', 'monitorGroupCommandFlag']),
    ...mapState('common', ['deviceTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    // selectedNode() {
    //   this.initList();
    // },

  },
  mounted: function () {
    window.eventBus.$on('groupCommandStateChanged', params => {
      // console.log('get cmd reply', params);
      if(params.flag != this.monitorGroupCommandFlag) return;
      let ds = this.areas.filter(p => p.id == params.groupId);
      if(ds.length == 0)return;
      this.$set(ds[0], 'success', params.success);
      this.$set(ds[0], 'failed', params.failed);
      this.$set(ds[0], 'timeout', params.timeout);
      if(params.success + params.failed + params.timeout >= ds[0].send){
        this.$set(ds[0], 'message', '执行完毕');
      }
    })
    // this.initList();
    this.queryAreas();
    // this.timer = setInterval(this.setCmdResult, 500);
    // window.eventBus.$on('paramCommandComing', params => {
    //   // this.cmdResults[params.cmdId] = params;
    //   params.time = new Date().getTime();
    //   this.cmdResults[params.commandId] = params;
    // })
  },
  destroyed: function () {
    window.eventBus.$off('groupCommandStateChanged');
    clearInterval(this.timer);
  },
  methods: {
    clickBtn: function(item){
      if(this.loading)return;
      if(!item.args){
        let groupList = this.$refs.chkTable.getCheckboxRecords();
        let ids = groupList.map(p => p.id);
        let args = {
          code: item.code,
          list: ids,
          args: {}
        };
        this.sendCommand(args);
        // for(let item of this.areas){
        //   this.$set(item, 'send', 0);
        //   this.$set(item, 'success', 0);
        //   this.$set(item, 'failed', 0);
        //   this.$set(item, 'timeout', 0);
        //   this.$set(item, 'message', '准备发送指令');
        // }
        // this.loading = true;
        // this.$axios.post(`//${this.domains.trans}/station/command/SendLightGroup4G`, args).then(res => {
        //   this.loading = false;
        //   if (res.code == 0) {
        //     this.$store.commit('cmd/monitorGroupCommand', res.data.flag);
        //     for(let item of res.data.list){
        //       let ds = this.areas.filter(p => p.id == item.id);
        //       if(ds.length == 0)continue;
        //       this.$set(ds[0], 'send', item.message);
        //       this.$set(ds[0], 'success', 0);
        //       this.$set(ds[0], 'failed', 0);
        //       this.$set(ds[0], 'timeout', 0);
        //       this.$set(ds[0], 'message', item.message == 0 ? '无可发送' : '指令已发送');
        //     }
        //   }
        // });
      }else{
        this.cmdItem = { paramsCode: item.code, code: item.code, name: item.name, pswd: item.pswd, args: {} };
        this.showArgModal = true;
      }
    },
    sendCommand: function(args){
      for(let item of this.areas){
        let ar = args.list.filter(p => p.id == item.id);
        if(ar.length == 0)continue;
        this.$set(item, 'send', 0);
        this.$set(item, 'success', 0);
        this.$set(item, 'failed', 0);
        this.$set(item, 'timeout', 0);
        this.$set(item, 'message', '准备发送指令');
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/command/SendLightGroup4G`, args).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$store.commit('cmd/monitorGroupCommand', res.data.flag);
          for(let item of res.data.list){
            let ds = this.areas.filter(p => p.id == item.id);
            if(ds.length == 0)continue;
            this.$set(ds[0], 'send', item.message);
            this.$set(ds[0], 'success', 0);
            this.$set(ds[0], 'failed', 0);
            this.$set(ds[0], 'timeout', 0);
            this.$set(ds[0], 'message', item.message == 0 ? '无在线设备' : '指令已发送');
          }
        }
      });
    },
    queryAreas: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryLightGroup4G`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'areas', res.data);
        }
      });
    },
    argsSaved(params) {
      // console.log('args saved', params)
      this.showArgModal = false;
      let groupList = this.$refs.chkTable.getCheckboxRecords();
      let ids = groupList.map(p => p.id);
      let code = params.code;
        if (params.code == 'openSingle' || params.code == 'closeSingle') {
          code = 'handSingle';
        }
      let args = {
        code: code,
        list: ids,
        args: params.args
      };
      this.sendCommand(args);
      // for(let item of this.areas){
      //   this.$set(item, 'send', 0);
      //   this.$set(item, 'success', 0);
      //   this.$set(item, 'failed', 0);
      //   this.$set(item, 'timeout', 0);
      //   this.$set(item, 'message', '准备发送指令');
      // }
      // this.loading = true;
      // // console.log(params, groupList, args)
      // this.$axios.post(`//${this.domains.trans}/station/command/SendLightGroup4G`, args).then(res => {
      //   this.loading = false;
      //   if (res.code == 0) {
      //     this.$store.commit('cmd/monitorGroupCommand', res.data.flag);
      //     for(let item of res.data.list){
      //       let ds = this.areas.filter(p => p.id == item.id);
      //       if(ds.length == 0)continue;
      //       this.$set(ds[0], 'send', item.message);
      //       this.$set(ds[0], 'success', 0);
      //       this.$set(ds[0], 'failed', 0);
      //       this.$set(ds[0], 'timeout', 0);
      //       this.$set(ds[0], 'message', item.message == 0 ? '无可发送' : '指令已发送');
      //     }
      //   }
      // });
    },
    groupControl() {
      let groupList = this.$refs.chkTable.getCheckboxRecords();
      if (groupList.length == 0) {
        this.$Message.warning('至少一个分组进行操作');
        return;
      }
      this.cmdItem = { paramsCode: 'handSingle', code: 'handSingle', name: '调光', pswd: true, args: {} };
      this.showArgModal = true;
    },
    calcSuccessRate() {

    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.devices.filter(p => p.cmdId == cmdId);
        if (ds.length == 0) {
          if (new Date().getTime() - this.cmdResults[cmdId].time > 100000) {
            console.log('cmd id time out');
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
  padding-bottom: 10px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
</style>