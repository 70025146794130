<template>
  <bm-marker :key="`${item.id}_${item.type}`" :dragging="enableMoveDevice" :icon="{ url: getImgType(item), size: { width: 26, height: 36 } }" :position="{ lng: item.lng, lat: item.lat }" :name="item.name" @click="showMakerInfo" @dragend="makerPosChanged">
    <bm-label :content="item.name" :offset="{ width: -60, height: 35 }" :labelStyle="labelStyle" />
    <bm-circle
      :key="`${item.id}_${item.type}_circle`"
      :center="{ lng: item.lng, lat: item.lat }"
      :radius="parseInt(item.path.radius)"
      stroke-color="blue"
      :stroke-opacity="0.3"
      :clicking="true"
      :editing="editLuxId == item.id"
      @click="showMakerInfo"
      @lineupdate="updateCirclePath"
      :stroke-weight="1"
    ></bm-circle>
  </bm-marker>
</template>

<script>
// import { BmOverlay } from 'vue-baidu-map'
import { BmCircle, BmMarker, BmLabel } from '@/components/vue-baidu-map-v3/components'
export default {
  components: {
    BmCircle,
    BmMarker,
    BmLabel
  },
  // inject: ['injectObj'],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {
          lat: 0,
          lng: 0,
          id: 0,
          type: 0,
          alarm: false,
          online: false,
          running: false,
          code: '',
          name: '',
          path: {},
          status: 1,
          single: true,
          sid: 0
        }
      }
    },
    editLuxId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      labelStyle: {
        width: '150px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        color: 'red',
        border: 0,
        fontSize: '12px'
      },
      offsetWidth: 80,
      keyIndex: 1,
      timer: 0
    }
  },
  computed: {
    enableMoveDevice() {
      return true //this.injectObj.enableMoveDevice
    }
  },
  watch: {
    item() {
      console.log('map group total data changed', this.item)
    },
    'item.lat'() {
      console.log('lux lat data changed', this.item)
    }
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  mounted() {
    this.sample = this.item
  },
  methods: {
    showMakerInfo: function(e) {
      // console.log('lux click', e)
      this.$emit('click', this.item)
      e.domEvent.stopPropagation()
    },
    getImgType: function(dev) {
      if (!dev.online) {
        return this.img.light.status.offline
      } else if (dev.alarm) {
        return this.img.light.status.alarm
      } else {
        return this.img.light.status.online
      }
    },
    groupInfo() {
      this.$emit('overLayBut', this.sample)
    },
    makerPosChanged: function(params) {
      this.$emit('posChanged', { id: this.item.id, lat: params.point.lat, lng: params.point.lng })
    },
    updateCirclePath(e) {
      // this.circlePath.center = e.target.getCenter()
      // this.circlePath.radius = e.target.getRadius()
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        // console.log('Circle line path update', e.target.getRadius(), this.item);
        this.$set(this.item.path, 'radius', e.target.getRadius())
        // this.$emit('radiusChanged', {id:this.item.id, radius:e.target.getRadius()});
      }, 500)
    }
  }
}
</script>

<style>
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}
.overlay-box {
  display: flex;
  /* width: auto; */
  height: 34px;
  background: #3880fc;
  border-radius: 17px;
  line-height: 34px;
  padding-right: 10px;
  /* position: relative; */
  /* border: solid 1px red; */
}
.overlay-img {
  width: 28px;
  height: 28px;
  flex: none;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 3px;
  margin-top: 3px;
}
.overlay-img img {
  width: 14px;
  height: 18px;
}
.overlay-line {
  flex: none;
  width: 1px;
  height: 28px;
  background: #ffffff;
  display: inline-block;
}
.overlay-name {
  white-space: nowrap;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  /* margin-left: 9px; */
  vertical-align: top;
  border-right: 1px solid #fff;
  padding: 0 15px;
}
.overlay-num {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  vertical-align: top;
  margin: 0 9px;
}
.overlay-triangle {
  width: 0;
  height: 0;
  border-top: 8px solid #3880fc;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  position: absolute;
  top: 34px;
  left: 60px;
  /* border: solid 1px red; */
}
</style>
