<template>
  <div class="energyPage">
    <div class="energy-box">
      <allTotalPage />
      <sortBox1Page />
    </div>
    <div class="energy-box">
      <contrastPage />
      <sortBox2Page />
    </div>
  </div>
</template>
<script>

import allTotalPage from './allTotal'
import contrastPage from './contrast'
import sortBox1Page from './sortBox1'
import sortBox2Page from './sortBox2'
export default {
  name: 'energyPage',
  components: {
    allTotalPage,
    contrastPage,
    sortBox1Page,
    sortBox2Page,
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.energyPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 20;
}
.energy-box {
  width: 678px;
}
</style>
