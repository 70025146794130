<template>
  <Modal v-model="showModal" :mask-closable="false" width="900px">
    <div slot="header">安装详情</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="200">
      <FormItem prop="name" label="安装日期" style="margin-right: 15px">
        <div class="flex-row">
          <DatePicker ref="buildDate"  type="date" format="yyyy-MM-dd" v-model="form.day" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
        </div>
      </FormItem>
      <FormItem prop="powerbox" label="配电柜数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.powerbox" placeholder="配电柜数量" style="width:600px; margin-right: 38px;"><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="light" label="单灯数量" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.light" placeholder="单灯数量" style="width:600px; margin-right: 38px;"><span slot="append">个</span></Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>

import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalLampEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      form: {
        "id": 0,
        "projectId": 2, //所在项目
        "day": "", //安装日期
        "powerbox": 120, //配电柜数量
        "light": 2400 //单灯数量
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isAdd = this.item.isAdd;
        
        if (!this.isAdd) {
          this.$set(this, 'form', this.item.data);
        } else {
          this.form.id = 0;
          this.form.day = new Date().format('yyyy-MM-dd'); 
          this.form.powerbox = 1; 
          this.form.light = 1
        }
        this.form.projectId=this.item.projectId
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),

  },
  mounted: function () {
  },
  methods: {
    ok: async function () {
      this.$axios.post(`visual/home/SaveProjectInstall`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel() {
      this.showModal = false;
      this.$emit('saved');
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>