<template>
  <div :class="['control-table', showMore?'hideShita':'element']">
    <div class="info-header">
      <div class="info-header-title">{{showLang('com.lighting.common.operate')}}</div>
      <img :src="!showMore?`${img.light.road.single.header.down}`:`${img.light.road.single.header.upward}`" alt="" @click="switchMenu">
    </div>
    <div class="control-area">
      <template v-for="item in items" >
        <div v-if=" funCodes(item.opcode)" class="control-item" :key="'a'+item.code" @click="doPowerCmd(item)">
          <img :src="item.img" />
          {{showLang(item.lang)}}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: 'PowerControlMenu',
  components: {
  },
  props: {
    onlyLeakage: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      spin: {
        show: false,
        content: "",
      },
      filter: {
        stationId: 0,
        channel: 0,
        timeId: 0,
      },
      showMore: false,
      showControlOutputModal: false,
      title: "操作",
      userPassword: "",
      loading: true,
      //弹窗 .format('yyyy-MM-dd HH:mm:ss')
      modalPws: false,//填写密码
      modalSetForm: false,//读取时间读取
      setForm: {
        date: "",
        channel: "",
      },
      showResult: false,
      currentOperation: {},
      leakageMenus: ['openControl', 'getYearPlan', 'setYearPlan'],

      //召测数据 回路调光 读取时间表 下发时间表 校准时钟 读取时钟
      items: [
        { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', name: '召测数据', lang: 'com.but.survey', img: this.img.light.road.single.ctrl.forceRead },
        { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', name: '读取时钟', lang: 'com.but.get.read.clock', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', name: '校准时钟', lang: 'com.but.get.Calibrate', img: this.img.light.road.single.ctrl.writeClock },
        { needArgs: true, needPswd: false, code: 'getYearPlan', opcode: 'dcs', name: '读取时间表', lang: 'com.but.get.time.tab', img: this.img.light.road.single.ctrl.readTime },
        { needArgs: true, needPswd: true, code: 'setYearPlan', opcode: 'dcc', name: '下发时间表', lang: 'com.but.set.time.tab', img: this.img.light.road.single.ctrl.writeTime },
        { needArgs: true, needPswd: true, code: 'handAdjust', opcode: 'dcc', name: '回路调光', lang: 'com.crowd.dimming', img: this.img.light.road.single.ctrl.open },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    doPowerCmd: function (item) {
      item.paramsCode = item.code;
      this.$emit('command', item);
    },
    switchMenu: function () {
      this.showMore = !this.showMore
    },
  }
}
</script>
<style scoped>
.info-header {
  border-bottom: solid 1px #dee5ed;
  width: 100%;
  height: 38px;
  flex: none;
  display: flex;
  align-items: center;
}
.info-header-title {
  width: 300px;
  flex: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
.control-table {
  /* border: solid 1px red; */
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: white;
  /* margin-bottom: 15px; */
}
.control-area {
  /* border: solid 1px red; */
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-content: flex-start;
}
.control-item {
  /* border: solid 1px red; */
  width: 170px;
  height: 90px;
  flex: none;
  margin-left: 80px;
  margin-top: 15px;
  z-index: 2;
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  cursor: pointer;
}
.control-item img {
  margin-right: 10px;
}
.control-box {
  width: 120px;
  height: 112px;
  margin-top: 80px;
  /* margin-left: 30px; */
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  position: relative;
}
.control-table.hideShita {
  height: 410px;
  transition: height 0.5s;
}
.control-table.element {
  height: 195px;
  transition: height 0.5s;
}
</style>