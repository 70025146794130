<template>
  <div class="mapPage">
    <div class="map-container">
      <div id="mapGL" style="height: 100%;width: 100%;" ref="divStyle" :key="mapGLIndex"></div>
    </div>
    <div class="switch-project">
      <div class="project-name" @click="switchProject">
        <div class="project-border"></div>
        <div class="project-text">
          <div class="project-text-name">{{projectTitle()}}</div>
          <Icon type="md-arrow-dropdown" class="project-text-icon" v-if="!projectShow" />
          <Icon type="md-arrow-dropup" class="project-text-icon" v-if="projectShow" />
        </div>
        <div class="project-border"></div>
      </div>
      <div class="project-list" v-if="projectShow">
        <div class="project-list-box" @click="projectList({id:0})">
          <div class="box-con">
            <div :class="['box-circle',projectId==0?'box-circle3':'']">
              <div class="box-circle2" v-if="projectId==0"></div>
            </div>
            <div :class="['box-con-name',projectId==0?'box-con-name2':'']">全部</div>
          </div>
          <div class="box-border1"></div>
          <div class="box-border2"></div>
        </div>
        <div class="project-list-box" v-for="el,index in list" :key="index" @click="projectList(el)">
          <div class="box-con">
            <div :class="['box-circle',projectId==el.id?'box-circle3':'']">
              <div class="box-circle2" v-if="projectId==el.id"></div>
            </div>
            <div :class="['box-con-name',projectId==el.id?'box-con-name2':'']">{{el.name}}</div>
          </div>
          <div class="box-border1"></div>
          <div class="box-border2"></div>
        </div>
      </div>
    </div>
    <div ref="infoWindowContent" style="display: none;" class="infoWindow">
      <div class="infoWindow-stat">
        <div class="infoWindow-list">
          <div class="infoWindow-con">
            <i class='infoWindow-con-drop'></i>
            <span class='infoWindow-con-name'>配电箱</span>
          </div>
          <div class="infoWindow-con">
            <span class='infoWindow-con-num'>{{infoWindowData.powerboxCount}}</span>
            <span class='infoWindow-con-unit'>个</span>
          </div>
        </div>
        <div class="infoWindow-list">
          <div class="infoWindow-con">
            <i class='infoWindow-con-drop'></i>
            <span class='infoWindow-con-name'>单灯</span>
          </div>
          <div class="infoWindow-con">
            <span class='infoWindow-con-num'>{{infoWindowData.lightCount}}</span>
            <span class='infoWindow-con-unit'>个</span>
          </div>
        </div>
        <div class="infoWindow-list">
          <div class="infoWindow-con">
            <i class='infoWindow-con-drop'></i>
            <span class='infoWindow-con-name'>能耗</span>
          </div>
          <div class="infoWindow-con">
            <span class='infoWindow-con-num'>{{infoWindowData.energyTotal}}</span>
            <span class='infoWindow-con-unit'>KWh</span>
          </div>
        </div>
        <div class="infoWindow-list">
          <div class="infoWindow-con">
            <i class='infoWindow-con-drop'></i>
            <span class='infoWindow-con-name'>节能</span>
          </div>
          <div class="infoWindow-con">
            <span class='infoWindow-con-num'>{{infoWindowData.energyConservation}}</span>
            <span class='infoWindow-con-unit'>KWh</span>
          </div>
        </div>
        <div class="infoWindow-list">
          <div class="infoWindow-con">
            <i class='infoWindow-con-drop'></i>
            <span class='infoWindow-con-name'>减碳</span>
          </div>
          <div class="infoWindow-con">
            <span class='infoWindow-con-num'>{{infoWindowData.carbonReduction}}</span>
            <span class='infoWindow-con-unit'>千克</span>
          </div>
        </div>
        <div class="infoWindow-list">
          <div class="infoWindow-con">
            <i class='infoWindow-con-drop'></i>
            <span class='infoWindow-con-name'>减少砍伐树木</span>
          </div>
          <div class="infoWindow-con">
            <span class='infoWindow-con-num'>{{infoWindowData.treeReduction}}</span>
            <span class='infoWindow-con-unit'>棵</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ticyJson from './ticy'
import { BMPGL } from '@/plugins/bmpgl'
// import BMapGL from 'vue-bmap-gl';
export default {
  name: 'mapPage',
  components: {
  },
  props: {
  },
  data() {
    return {
      center: { lng: 113.828333, lat: 22.770866, zoom: 6 },
      map: null,
      BMap: null,
      styleJson: require('@/views/customized/public/customized_ver1.json'),
      list: [],
      mapGLIndex: 2,
      mapgl: null,
      timerId: null,
      cityData: [],
      projectId: 0,
      projectShow: false,
      circlesTime: null,
      BMapGL: null,
      infoWindow: null,
      clearCover: [],
      infoWindowData: {
        carbonReduction: 0,
        energyConservation: 0,
        energyTotal: 0,
        lightCount: 0,
        powerboxCount: 0,
        projectCount: 0,
        treeReduction: 0
      },
    }
  },
  mounted: function () {
    this.getProject();
    this.mapGLIndex = new Date().getTime();
  },
  methods: {
    initMap() {
      BMPGL().then((BMapGL) => {
        this.BMapGL = BMapGL;
        this.mapgl = new BMapGL.Map('mapGL');
        this.mapgl.enableScrollWheelZoom(true);// 鼠标滚轮缩放
        let minLat = 90, minLng = 180, maxLat = -90, maxLng = -180;
        this.list.forEach(el => {
          if (el.lat > 0 && el.lng > 0) {
            if (el.lat < minLat) minLat = el.lat;
            if (el.lat > maxLat) maxLat = el.lat;
            if (el.lng < minLng) minLng = el.lng;
            if (el.lng > maxLng) maxLng = el.lng;
          }
        })
        let centerlat = (minLat + maxLat) / 2;
        let centerlng = (minLng + maxLng) / 2;
        let point = new BMapGL.Point(centerlng, centerlat);
        this.mapgl.centerAndZoom(point, 6);
        this.mapgl.setMapStyleV2({ styleJson: this.styleJson });
        let this_ = this;
        this.timerId = setTimeout(() => {
          this_.cityData.forEach(el => {
            // if (index > 15) { return false }
            const count = new BMapGL.Boundary();
            count.get(el, function (rs) {
              if (rs.boundaries && rs.boundaries.length > 0) {
                let hole = new BMapGL.Polygon(rs.boundaries, {
                  fillColor: 'rgba(79, 156, 242, 0.7)',
                  strokeColor: '#4F9CF2',
                  strokeWeight: 2,
                  fillOpacity: 0.1
                });
                this_.mapgl.addOverlay(hole);
                this_.clearCover.push(hole)
              }
            });
          });
        }, 200);
        let circles = [];
        this_.list.forEach(el => {
          let circle = new BMapGL.Circle(new BMapGL.Point(el.lng, el.lat), 50000, {
            radius: 60,// 边框宽度
            strokeColor: "#3690FF", // 边框颜色
            fillColor: "#3690FF", // 填充颜色
            strokeWeight: 16, // 边框宽度
            strokeOpacity: 0.3, // 边框透明度
            fillOpacity: 1 // 填充透明度
          })
          this_.mapgl.addOverlay(circle); // 将圆添加到地图
          // let offset=el.city.replace('市', '').length*(18 - 1);
          let opts = {
            position: new BMapGL.Point(el.lng, el.lat), // 指定文本标注所在的地理位置
            offset: new BMapGL.Size(-18, -15) // 设置文本偏移量
          };
          let label = new BMapGL.Label(el.city.replace('市', ''), opts);
          label.setStyle({
            color: '#fff',
            borderColor: 'transparent',
            backgroundColor:'transparent',
            fontSize: '16px',
          });
          this_.mapgl.addOverlay(label); 
          circles.push(circle)
          this_.clearCover.push(circle)
        })
        // let opacity = 0.5;
        // let isIncreasing = true;
        // this.circlesTime = setInterval(() => {
        //   if (isIncreasing) {
        //     opacity += 0.1;
        //     if (opacity >= 1) isIncreasing = false;
        //   } else {
        //     opacity -= 0.1;
        //     if (opacity <= 0.3) isIncreasing = true;
        //   }
        //   circles.forEach(circle => circle.setStrokeOpacity(opacity));
        // }, 100);
      })
    },
    clearBoundaries() {
      this.clearCover.forEach(el => {
        this.mapgl.removeOverlay(el);
      });
      this.clearCover = [];
    },
    projectMap(el) {
      let this_ = this;
      let BMapGL = this.BMapGL;
      this.clearBoundaries();
      let point = new BMapGL.Point(el.lng, el.lat);
      this.mapgl.centerAndZoom(point, 9);
      const count = new BMapGL.Boundary();
      count.get(el.city, function (rs) {
        if (rs.boundaries && rs.boundaries.length > 0) {
          let hole = new BMapGL.Polygon(rs.boundaries, {
            fillColor: 'rgba(79, 156, 242, 0.7)',
            strokeColor: '#4F9CF2',
            strokeWeight: 2,
            fillOpacity: 0.1
          });
          this_.mapgl.addOverlay(hole);
          this_.clearCover.push(hole)
        }
      });
      // this.img.customized.ver1.lampAi=//www.amdm.top/imgs/customized/ver1/lampAi.gif'
      // let myIcon = new BMapGL.Icon(this.img.customized.ver1.lampAi, new BMapGL.Size(42, 56));
      // // let myIcon = new BMapGL.Icon('./lampAi.gif', new BMapGL.Size(42, 56));
      // let pt = new BMapGL.Point(el.lng, el.lat);
      // let markerImg = new BMapGL.Marker(pt, {
      //   icon: myIcon
      // });
      // this.mapgl.addOverlay(markerImg);
      // this.clearCover.push(markerImg)
      // let circles = [];
      let circle = new BMapGL.Circle(new BMapGL.Point(el.lng, el.lat), 5000, {
        radius: 60,// 边框宽度
        strokeColor: "#3690FF", // 边框颜色
        fillColor: "#3690FF", // 填充颜色
        strokeWeight: 16, // 边框宽度
        strokeOpacity: 0.3, // 边框透明度
        fillOpacity: 1 // 填充透明度
      })
      this.mapgl.addOverlay(circle); // 将圆添加到地图

      // this.infoWindow = new BMapGL.InfoWindow("这里是信息窗口的内容", {
      //   width: 250,
      //   height: 100,
      //   title: "信息窗口标题"
      // });
      // console.log('this.infoWindow-------');
      // console.log(this.infoWindow);
      // circle.addEventListener('click', () => {
      //   const circleCenter = circle.getCenter();
      //   this.infoWindow.open(this.mapgl, circleCenter);
      // });

      // this.infoWindow = new BMapGL.InfoWindow();
      // console.log('this.infoWindow-------');
      // console.log(this.infoWindow);
      // circle.addEventListener('click', () => {
      //   this.infoWindow.setContent(this.$refs.infoWindowContent.innerHTML);
      //   const circleCenter = circle.getCenter();
      //   debugger;
      //   console.log(this.infoWindow);
      //   this.infoWindow.open(this.mapgl, circleCenter);
      // });
      let infoWindow = new BMapGL.InfoWindow(this.$refs.infoWindowContent.innerHTML, {
        width: 300,
        height: 200,
        title: el.name
      });
      this_.mapgl.openInfoWindow(infoWindow, point);
      console.log('this_.mapgl--------------')
      console.log(this_.mapgl)
      // circles.push(circle)
      // let opacity = 0.5;
      // let isIncreasing = true;
      // this.circlesTime = setInterval(() => {
      //   if (isIncreasing) {
      //     opacity += 0.1;
      //     if (opacity >= 1) isIncreasing = false;
      //   } else {
      //     opacity -= 0.1;
      //     if (opacity <= 0.3) isIncreasing = true;
      //   }
      //   circles.forEach(circle => circle.setStrokeOpacity(opacity));
      // }, 100);
    },
    handleClick() {

    },
    openInfoWindow(event) {
      const content = this.$refs.infoWindowContent.innerHTML;
      this.infoWindow.setContent(content);
      this.infoWindow.open(this.mapgl, event.point);
    },
    getProject() {
      this.$axios.post(`visual/home/QueryProject`, {}).then(res => {
        if (res.code == 0) {
          this.mapGLIndex = new Date().getTime();
          this.$set(this, 'list', res.data);
          const onlyChineseChars = /^[\u4e00-\u9fa5]+$/;
          this.cityData = [...new Set(this.list.map(item => item.city))].filter(city => onlyChineseChars.test(city));
          this.initMap()
          let cityArr = [];
          this.cityData.forEach(el => {
            cityArr.push(this.findProvince(el))
          });
          this.cityData = [...new Set(cityArr)];
        }
      })
    },
    findProvince(city) {
      let provinceName = city;
      for (const province of ticyJson) {
        if (province.city) {
          for (const cityData of province.city) {
            if (cityData.name === city) {
              provinceName = province.name;
              return provinceName;
            }
          }
        }
      }
      return provinceName;
    },
    switchProject() {
      this.projectShow = !this.projectShow;
    },
    projectList(el) {
      this.projectShow = false;
      if (this.projectId == el.id) return false;
      this.projectId = el.id;
      if (el.id == 0) {
        this.initMap();
      } else {
        this.$axios.post(`visual/home/QueryProjectDeviceAndEnergy`, { id: el.id, isVirtual: el.isVirtual }).then(res => {
          if (res.code == 0) {
            this.$set(this, 'infoWindowData', res.data);
          }
        })
        this.projectMap(el);
      }
    },
    projectTitle() {
      let name = '全部'
      let nameArr = this.list.filter(el => el.id == this.projectId);
      if (nameArr.length > 0) {
        name = nameArr[0].name;
      }
      return name;
    }
  },

  beforeDestroy() {
    clearTimeout(this.timerId);
    clearTimeout(this.circlesTime);
    this.mapgl = null;
  }
}
</script>
<style scoped>
.mapPage {
  width: 100%;
  height: 100%;
  position: relative;
}
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}
.map {
  width: 100%;
  height: 100%;
}
.switch-project {
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 50;
}
.project-name {
  width: 180px;
  height: 40px;
  background: linear-gradient(90deg, #071121, #206297, #071121);
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.project-border {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #0e1222, #8cdfff, #0e1222);
}
.project-text-icon {
  color: #4a76a6;

  font-size: 38px;
  margin-left: 5px;
}
.project-text {
  width: 100%;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #f6fafd;
  display: flex;
  justify-content: center;
}
.project-list {
  position: absolute;
  width: 298px;
  height: 220px;
  right: -50px;
  top: 51px;
  max-height: 300px;
  overflow-y: auto;
  background: linear-gradient(
    90deg,
    rgba(6, 37, 62, 0.8),
    rgba(37, 107, 162, 0.8),
    rgba(6, 37, 62, 0.8)
  );
  border-radius: 3px;
  cursor: pointer;
  padding: 0 20px 20px;
}
.project-text-name {
  max-width: 100px;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-list-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 40px;
}
.box-con {
  text-align: left;
  display: flex;
  align-items: center;
  height: 36px;
}
.box-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #87a4c1;
  margin-right: 5px;
}
.box-con-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #9ab9d7;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-con-name2 {
  color: #5ed7ff;
}
.box-circle2 {
  width: 5px;
  height: 5px;
  background: #5ed7ff;
  border-radius: 50%;
  margin-top: 1px;
  margin-left: 1px;
}
.box-circle3 {
  border: 1px solid #5ed7ff;
}
.box-border1 {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #062037, #072c49, #062037);
}
.box-border2 {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #062037, #6191b9, #062037);
}
.infoWindow-stat {
  display: flex;
  padding: 5px;
  flex-direction: column;
}
.infoWindow-list {
  display: flex;
  justify-content: space-between;
}
.infoWindow-con {
  display: flex;
  align-items: center;
}
.infoWindow-con-drop {
  width: 6px;
  height: 6px;
  background: #39c9ff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}
.infoWindow-con-unit {
  width: 40px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #7fa1c0;
  line-height: 30px;
  display: block;
  margin-right: 5px;
}
.infoWindow-con-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #aaccec;
  line-height: 30px;
}
.infoWindow-con-num {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 20px;
  color: #39c9ff;
  line-height: 30px;
}
</style>
