<template>
  <bm-control :offset="offset" anchor="BMAP_ANCHOR_TOP_RIGHT" :key="offsetIndex">
    <div class="map-data-stat">
      <div class="map-data-but">
        <img :src="img.newProject.map.location" alt="" @click="butModular(6)">
        <!-- <img :src="img.newProject.map.screen" alt=""> -->
        <!-- <img :src="img.newProject.map.retract" alt=""> 4-19-->
        <div class="map-but-zoom">
          <div class="zoom-but" @click="butModular(8)">+</div>
          <div style="overflow: hidden;">
            <div class="zoom-strip">
              <div class="zoom-strip-cover" :style="{bottom:(-(19-zoomNum)*5+'px')}"></div>
              <div class="zoom-strip-circular" :style="{bottom:((zoomNum-4)*4.5+'px')}"></div>
            </div>
          </div>
          <div class="zoom-but" style="font-size: 14px;font-weight: bold;" @click="butModular(9)">—</div>
        </div>
      </div>
      <div class="map-data-box">
        <div class="map-data-top">
          <div class="top-con-box">
            <div class="top-con-title">正在报警</div>
            <div class="top-con-num" style="color: #f24848;">8</div>
          </div>
          <div class="top-con-line"></div>
          <div class="top-con-box">
            <div class="top-con-title">本周报警</div>
            <div class="top-con-num" style="color: #FFA31F;">11</div>
          </div>
          <div class="top-con-line"></div>
          <div class="top-con-box">
            <div class="top-con-title">本月报警</div>
            <div class="top-con-num" style="color: #FFCD1F;">62</div>
          </div>
          <div class="top-con-line"></div>
          <div class="top-con-box">
            <div class="top-con-title">近三个月报警</div>
            <div class="top-con-num" style="color: #000000;">210</div>
          </div>
        </div>
        <div class="map-data-total mt10">
          <div class="map-data-retract" :style="{left:(retractShow?'-25px':'-50px')}" @click="retract()">
            <img :src="img.newProject.map.retract" alt="" :style="{transform:(retractShow?'rotate(0)':'rotate(180deg)')}">
          </div>
          <MenuMapTotal></MenuMapTotal>
        </div>
        <div class="map-data-police mt10">
          <MenuPolice></MenuPolice>
        </div>
      </div>
    </div>
  </bm-control>
</template>
<script>
import { mapGetters } from 'vuex'
import { BmControl } from '@/components/vue-baidu-map-v3/components'
import MenuMapTotal from './MenuMapTotal'
import MenuPolice from './MenuPolice'
export default {
  components: {
    BmControl,
    MenuMapTotal,
    MenuPolice,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item:{
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      retractShow: true,
      offsetIndex: 0,
      zoomNum:0,
      offset: {
        width: 14,
        height: 14
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  watch: {
    item(){
      this.zoomNum=this.item;
    }
  },
  mounted() {
    this.zoomNum=this.item;
  },
  methods: {
    butModular: function (num) {
      if(num==8 && this.zoomNum<19){
        this.zoomNum++;
      }
      if(num==9 &&  this.zoomNum>4){
        this.zoomNum--;
      }
      let data = {};
      this.$emit('changed', { num, data });
    },
    retract() {
      this.retractShow = !this.retractShow;
      if (this.retractShow) {
        this.offset.width = 14;
        this.offset.height = 14;
      } else {
        this.offset.width = -455;
        this.offset.height = 14;
      }
      this.offsetIndex++;
    }
  }
}
</script>

<style>
.map-data-stat {
  display: flex;
}
.map-data-but {
  margin-right: 14px;
  display: flex;
  flex-direction: column;
}
.map-data-but > img {
  width: 47px;
  height: 47px;
  cursor: pointer;
}
.map-data-top {
  width: 453px;
  height: 86px;
  background: #ffffff;
  border-radius: 6px;
  padding: 15px 20px 20px;
  display: flex;
  justify-content: space-between;
}
.top-con-title {
  height: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  text-align: center;
}

.top-con-num {
  height: 20px;
  font-family: Bahnschrift;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  margin-top: 10px;
}
.top-con-line {
  width: 1px;
  height: 50px;
  background: #e3e7ee;
  margin-top: 4px;
}
.map-data-total {
  width: 454px;
  height: 350px;
  background: #ffffff;
  position: relative;
}
.map-data-police {
  width: 454px;
  height: 300px;
  background: #ffffff;
}
.map-data-retract {
  width: 42px;
  height: 42px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 52px;
  text-align: center;
  box-shadow: 0px 5px 7px 0px rgba(51, 121, 251, 0.2);
  cursor: pointer;
}
.map-data-retract img {
  width: 12px;
  height: 21px;
  margin-top: 9px;
}
.map-but-zoom {
  width: 34px;
  height: 132px;
  background: #ffffff;
  box-shadow: 0px 5px 7px 0px rgba(51, 121, 251, 0.2);
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}
.zoom-strip {
  width: 4px;
  height: 78px;
  background: #e9f3ff;
  border-radius: 2px;
  margin: 5px auto 0px;
  position: relative;
}
.zoom-but {
  font-size: 26px;
  color: #2e2d3c;
  height: 12px;
  line-height: 12px;
  cursor: pointer;
}
.zoom-strip-circular {
  width: 12px;
  height: 12px;
  background: #2f5fff;
  box-shadow: 0px 1px 7px 0px rgba(51, 121, 251, 0.4);
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin-left: -4px;
  position: absolute;
  bottom: 0;
}
.zoom-strip-cover {
  width: 4px;
  height: 76.5px;
  background: #2f5fff;
  border-radius: 2px;
  position: absolute;
  bottom: -76.5px;
}
</style>