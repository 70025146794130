<template>
  <div class="devicePage">
    <div>
      <countPage />
    </div>
    <div>
      <installPage />
    </div>
  </div>
</template>
<script>

import countPage from './count'
import installPage from './install'
export default {
  name: 'devicePage',
  components: {
    countPage,
    installPage,
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.mapPage {
  width: 100%;
  height: 100%;
}
</style>
