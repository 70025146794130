<template>
  <div class="config-box" :key="dataRefresh">
    <table class="tunnelTable" border="0" cellspacing="0" cellpadding="0">
      <tr>
        <th style="height:60px;width:150px">状态</th>
        <th>IP和端口</th>
        <th>连接时间</th>
        <th>最后通信时间</th>
      </tr>
      <tr v-for="el,index in data" :key="'a'+index" align='center'>
        <td style="height:60px;">{{el.online?'在线':'离线'}}</td>
        <td>{{el.online?el.connectionCode:'---'}}</td>
        <td>{{el.online?el.connectTime:'---'}}</td>
        <td>{{el.online?el.lastTransTime:'---'}}</td>
      </tr>
    </table>
    <table class="tunnelTable" border="0" cellspacing="0" cellpadding="0">
      <tr>
        <th>最后状态时间：{{data[0] && data[0].fireTime }}</th>
        <th style="height:60px;">设备温度：{{data[0] && data[0].temp }}℃</th>
        <th>车流量：{{data[0] && data[0].flow }}辆</th>
      </tr>
    </table>
    <table class="tunnelTable" border="0" cellspacing="0" cellpadding="0">
      <tr>
        <th style="height:40px;width:70px"></th>
        <th class="wid8">1路</th>
        <th class="wid8">2路</th>
        <th class="wid8">3路</th>
        <th class="wid8">4路</th>
        <th class="wid8">5路</th>
        <th class="wid8">6路</th>
        <th class="wid8">7路</th>
        <th class="wid8">8路</th>
      </tr>
      <tr v-for="el,index in data" :key="'a'+index" align='center'>
        <td style="height:40px;">亮度</td>
        <td>{{el.bright1}}%</td>
        <td>{{el.bright2}}%</td>
        <td>{{el.bright3}}%</td>
        <td>{{el.bright4}}%</td>
        <td>{{el.bright5}}%</td>
        <td>{{el.bright6}}%</td>
        <td>{{el.bright7}}%</td>
        <td>{{el.bright8}}%</td>
      </tr>
      <tr v-for="el,index in data" :key="'b'+index" align='center'>
        <td style="height:40px;">时间表</td>
        <td>{{el.timeName1}}</td>
        <td>{{el.timeName2}}</td>
        <td>{{el.timeName3}}</td>
        <td>{{el.timeName4}}</td>
        <td>{{el.timeName5}}</td>
        <td>{{el.timeName6}}</td>
        <td>{{el.timeName7}}</td>
        <td>{{el.timeName8}}</td>
      </tr>
      <template v-for="num in 6">
        <tr :key="'q'+num" align='left'>
          <td style="height:40px;" align='center'>时段{{num}}</td>
          <td class="padd5">{{modeName(num,1)}}</td>
          <td class="padd5">{{modeName(num,2)}}</td>
          <td class="padd5">{{modeName(num,3)}}</td>
          <td class="padd5">{{modeName(num,4)}}</td>
          <td class="padd5">{{modeName(num,5)}}</td>
          <td class="padd5">{{modeName(num,6)}}</td>
          <td class="padd5">{{modeName(num,7)}}</td>
          <td class="padd5">{{modeName(num,8)}}</td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'tunnelData',
  components: {
    // PowerVoltageTable,
  },
  props: {
  },
  data() {
    return {
      data: [],
      dataRefresh: 0,
    }
  },
  computed: {
    ...mapState("cmd", ["cmdResultRefresh", "commands", 'windowOnResize', "singleCmdResult"]),
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'tunnelId']),
  },
  watch: {
    selectedNode() {
      this.initData();
    },
    tunnelId() {
      if (this.tunnelId == -1) { this.initData(); }
    }
  },
  mounted: function () {
    this.initData();
  },
  methods: {
    modeName(num, index) {//\
      if (this.data.length == 0) { return false }
      let el = this.data[0][`timeContent${index}`];
      if (el == null) { return '--' }
      let typeName = ''
      switch (el[`a${num}`]) {
        case 0:
          typeName = el[`t${num}`] + '，定时，功率:' + el[`v${num}`] + '%'
          break;
        case 1:
          typeName = el[`t${num}`] + '，传感器'
          break;
        case 2:
          typeName = el[`t${num}`] + '，混合，光强:' + el[`v${num}`] + 'lux'
          break;
      }
      return typeName
    },
    initData: function () {
      this.data = []
      this.$axios.post(`//${this.domains.trans}/device/tunnel/QueryGatewayState`, { stationId: this.selectedNode.id }).then(res => {
        if (res.code == 0) {
          this.dataRefresh++;
          this.data.push(res.data);
          this.$store.commit('group/setTunnelId', res.data.deviceId);
        }
      });
    },
  }
}
</script>
<style scoped>
.config-box {
  width: 100%;
  height: 100%;
  min-height: 700px;
}
.tunnelTable {
  width: 100%;
  border: 1px solid #eee;
}
.tunnelTable td,
.tunnelTable th {
  border: 1px solid #eee;
}
.padd5 {
  padding-left: 5px;
}
.wid8 {
  width: calc((100% - 78px) / 8);
}
</style>