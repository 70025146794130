<template>
  <div class="rankingPage">
    <div class="ranking-title">{{option.title}}</div>
    <div class="area-list">
      <template v-for="el,index in list">
        <div class="area-box" :key="index" v-if="index<5">
          <div class="area-name" :title="el.name">
            {{index+1}}.{{el.name}}
          </div>
          <div class="area-effect">
            <div class="effect-show">
              <i v-for="r in 20" :key="r" class="show1"></i>
            </div>
            <div class="effect-show effect-show2">
              <i v-for="t in el.ratio" :key="t" :class="[option.type==0?'show2':'show3']"></i>
            </div>
          </div>
          <div class="area-num">{{el.count}}</div>
        </div>
      </template>

    </div>
  </div>
</template>
<script>
export default {
  name: 'rankingPage',
  components: {
  },
  props: {
    // option:{title:'',type:''}
    option: {
      type: Object,
      default() { return { title: '', type: '' }; }
    },
    sorts: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {
      list: [],
      ratio: 10
    }
  },
  watch: {
    option: {
      handler(newVal, oldVal) {
        this.checkTypeChange(newVal, oldVal);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted: function () {

  },
  methods: {
    checkTypeChange() {
      this.list = this.sorts.slice(0, 5);
      this.list.forEach(el => {
        el.ratio = Math.ceil(el.rate / 100 * 20);
      });
    }
  }
}
</script>
<style scoped>
.rankingPage {
  width: 100%;
  height: 100%;
}
.ranking-title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #bbdeff;
}
.area-box {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.area-name {
  width: 84px !important;
  font-family: Source Han Sans SC;
  font-weight: 400;
  font-size: 14px;
  color: #9ab9d7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.area-box .show1 {
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #244968;
  border-radius: 2px;
  margin-right: 4px;
}
.area-effect {
  position: relative;
}
.effect-show {
  display: flex;
}
.effect-show2 {
  position: absolute;
  top: 0;
}
.area-box .show2 {
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #39c0ff;
  box-shadow: 0px 0px 7px 1px #0e398c;
  border-radius: 2px;
  margin-right: 4px;
}
.area-box .show3 {
  width: 4px;
  height: 14px;
  background: #52ffd4;
  box-shadow: 0px 0px 7px 1px #0e398c;
  border-radius: 2px;
  margin-right: 4px;
}
.area-num {
  font-family: Bahnschrift;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  text-shadow: 0px 0px 7px #0e398c;
  margin-left: 10px;
}
</style>
