<template>
  <!-- fullBg -->
  <div class="all-page" :style="{backgroundImage: `url(${img.customized.ver1.fullBg})`}">
    <div class="made-page" ref="fullscreenElement">
      <div class="made-head">
        <headPage />
        <div class='gisMask4' :style="{backgroundImage: `url(${img.customized.ver1.gisMask4})`}"></div>
      </div>
      <div class="made-box">
        <div class="made-left">
          <settingPage class="left-box" />
          <device class="left-box" />
          <div class='gisMask1' :style="{backgroundImage: `url(${img.customized.ver1.gisMask1})`}">
          </div>
        </div>
        <div class="made-gis">
          <gisPage />
        </div>
        <div class="made-right">
          <energyPage />
          <div class='gisMask3' :style="{backgroundImage: `url(${img.customized.ver1.gisMask3})`}">
          </div>
          <!-- <device class="left-box" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import headPage from './head/Index'
import settingPage from './left/setting'
import energyPage from './right/energy'
import device from './left/device'
import gisPage from './gis/index'
export default {
  name: 'customizedHome',
  components: {
    headPage,
    settingPage,
    device,
    energyPage,
    gisPage,
  },
  props: {
  },
  data() {
    return {
      projectId: 0,
      menus: [],
    }
  },
  mounted: function () {
    // this.enterFullscreen();
  },
  methods: {
    enterFullscreen() {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    },
  }
}
</script>
<style scoped>
.all-page {
  width: 4320px;
  height: 100%;
  min-height: 880px;
  background-color: rgba(6, 13, 24, 1);
}
.made-page {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.made-head {
  width: 100%;
  height: 92px;
}
.made-box {
  width: calc(100%);
  height: calc(100% - 92px);
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
}
.made-left,
.made-right {
  display: flex;
  width: 1382px;
  height: 100%;
  justify-content: space-between;
  z-index: 40;
  position: relative;
}
.left-box {
  width: 678px;
  height: 100%;
  z-index: 20;
}
.made-gis {
  width: calc(100% - 2764px);
  height: 100%;
}
.gisMask4 {
  width: 1690px;
  height: 169px;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -1060px;
  z-index: 30;
}
.gisMask1 {
  width: 328px;
  height: 100%;
  position: fixed;
  left: 1285px;
  z-index: 10;
  background-size: 100% 100%;
}
.gisMask3 {
  width: 445px;
  height: 100%;
  position: absolute;
  left: -237px;
  z-index: 10;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
