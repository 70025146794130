export function BMPGL() {
  return new Promise(function (resolve, reject) {
    window.init = function () {
      resolve(window.BMapGL)
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://api.map.baidu.com/api?v=3.0&type=webgl&ak=MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88&callback=init`
    script.onerror = reject
    document.head.appendChild(script);
  })
}
// function weather() {
//   return weatherData;
// }

// export {
//   weather
// }