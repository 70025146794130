<template>
  <div class="titlePage">
    <img :src="img.visual.tunnel.leftAi" alt=""  class="leftAi"/>
    <div  class="title-con" :style="{ backgroundImage: `url(${img.visual.tunnel.titleBg})` }" >
      <span class="title-cn">{{ item && item.cn }} </span>
      <i class="title-slash">/</i>
      <span class="title-en">{{ item && item.en }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "titlePage",
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  beforeCreate: function () {},
  created: function () {},
  mounted: function () {},
  methods: {},
};
</script>
<style scoped>
.titlePage {
  width: 100%;
  height: 36px;
  display: flex;
  position: relative;
}
.leftAi{
  position: absolute;
  left:  -20px;
}
.title-cn {
  font-family: aliheiti;
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  text-shadow: 2px 1px 2px #093358;
  font-style: italic;
  margin-left: 13px;
  line-height: 36px;
}

.title-slash {
  /* box-shadow: 1px 1px 1px 0px rgba(9, 51, 88, 0.8);
  border: 2px solid #94AFC2; */
  font-weight: bold;
  font-size: 16px;
  color: #799fb6;
}

.title-en {
  font-family: aliheiti;
  font-weight: bold;
  font-size: 14px;
  color: #799fb6;
  text-shadow: 2px 1px 2px rgba(9, 51, 88, 0.5);
  font-style: italic;
}
.title-con {
  background-size: 100% 100%;
    width: 100%;
}
</style>
