import Vue from 'vue';
import './plugins/axios';
import ws from './plugins/ws';
// import './plugins/config';
import './plugins/images';
// import './plugins/prods';
import App from './App.vue';
// import 'default-passive-events';
import router from './router';
import store from './store'
import eventBus from './plugins/eventBus'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import dateUtil from './plugins/dateUtil';
import './plugins/XTXAPP';
import AuthButton from '@/components/common/AuthButton';
import AuthDropdownItem from '@/components/common/AuthDropdownItem';
import AuthMenuDiv from '@/components/common/AuthMenuDiv';
// const AuthButton = resolve => require.ensure([], () => resolve(require('@/components/common/AuthButton')), 'AuthButton');
// const AuthDropdownItem = resolve => require.ensure([], () => resolve(require('@/components/common/AuthDropdownItem')), 'AuthDropdownItem');
// const AuthMenuDiv = resolve => require.ensure([], () => resolve(require('@/components/common/AuthMenuDiv')), 'AuthMenuDiv');
import UmyUi from 'umy-ui'
// import VXETable from 'vxe-table'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import VueAMap from 'vue-amap';
import BMapGL from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css'
// import 'vxe-table/lib/style.css'
import VxeUI from 'vxe-pc-ui'
import 'vxe-pc-ui/lib/style.css'
import 'umy-ui/lib/theme-chalk/index.css'; // 引入样式
import '@/assets/css/scrollBar.css'; // 引入滚动条样式
import '@/assets/css/layout.css'; //引入布局样式
import '@/assets/css/margin.css'; //引入对外偏移样式
import '@/assets/css/padding.css'; //引入向内偏移样式
import '@/assets/css/border.css'; //引入边框样式
import '@/assets/css/text.css'; //引入字体样式
// import 'default-passive-events' //Chrome51 版本以后，Chrome 增加了新的事件捕获机制－Passive Event Listeners，导致触发了告警

// charts 饼图 pie   折线图 line  柱状图 bar 仪表盘 gauge
const chartsPie = resolve => require.ensure([], () => resolve(require('@/components/charts/pie')), 'chartsPie');
const chartsLine = resolve => require.ensure([], () => resolve(require('@/components/charts/line')), 'chartsLine');
const chartsBar = resolve => require.ensure([], () => resolve(require('@/components/charts/bar')), 'chartsBar');
const chartsGauge = resolve => require.ensure([], () => resolve(require('@/components/charts/gauge')), 'chartsGauge');

Vue.config.productionTip = false;
Vue.use(ViewUI);
Vue.use(dateUtil);
Vue.use(VueAMap);
Vue.component('AuthButton', AuthButton);
Vue.component('AuthDropdownItem', AuthDropdownItem);
Vue.component('AuthMenuDiv', AuthMenuDiv);
Vue.component('chartsPie', chartsPie);
Vue.component('chartsLine', chartsLine);
Vue.component('chartsBar', chartsBar);
Vue.component('chartsGauge', chartsGauge);
Vue.use(UmyUi);
BMapGL.initBMapApiLoader({
  ak: 'MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88'
})
Vue.use(BMapGL);

Vue.use(VxeUI);
// Vue.use(VXETable);
Vue.use(VXETable)
VueAMap.initAMapApiLoader({
  key: 'e63909bc52070768fd72e902c7d67da8',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
})
window._AMapSecurityConfig = {
  securityJsCode: '3c6032e54cdb1b50ed2a66ebc5b3d220'
};
Vue.prototype.dhweb = new window.DHAlarmWeb();
// Vue.prototype.dhweb.onLogin = (params) => {
//   console.log('zhu hai san run login ed')
//   vue.$store.commit('cmd/zhuhaiSanrunLogin', params)
// };
// Vue.prototype.dhweb.onDeviceList = (params) => { vue.$store.commit('cmd/zhuhaiSanrunDeviceList', params) };
// Vue.prototype.dhweb.onNotify = (params) => { vue.$store.commit('cmd/zhuhaiSanrunNotify', params) };
// Vue.prototype.dhweb.onGroupList = (params) => { vue.$store.commit('cmd/zhuhaiSanrunGroupList', params) };

let vue = new Vue({
  router,
  store,
  // eventBus,
  render: h => h(App)
});
window.eventBus = eventBus;
vue.$mount('#app');
// console.log('cur page load', vue.$route, window.location, window.location.pathname)
if (window.location.pathname != '/alarm/upload') {
  ws.start1(vue);
  window.onresize = function () {
    vue.$store.commit('cmd/setWindowOnResize')
  }
  window.vue = vue;
  window.vue.dhweb.onLogin = (params) => {
    vue.$store.commit('cmd/zhuhaiSanrunLogin', params)
  };
  window.vue.dhweb.onGroupList = (params) => {
    vue.$store.commit('cmd/zhuhaiSanrunGroupList', params)
  };
  window.vue.dhweb.onDeviceList = (params) => {
    vue.$store.commit('cmd/zhuhaiSanrunDeviceList', params)
  };
  window.vue.dhweb.onNotify = (params) => {
    vue.$store.commit('cmd/zhuhaiSanrunNotify', params)
  };
  window.vue.dhweb.onAlarmServerClosed = (params) => {
    vue.$store.commit('cmd/zhuhaiSanrunAlarmServerClosed', params)
  };
}