<template>
  <div class="totalPage">
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon1" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">项目总数(个)</div>
        <div class="stat-box-num textSiz">
          {{statData.projectCount}}
          <!-- <count-up :end-val="conversion(statData.projectCount)" :start-val="0" :key="countIndex"></count-up>{{statData.projectCount>100000?'万':''}} -->
        </div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon2" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">配电箱总数(个)</div>
        <div class="stat-box-num textSiz">
          <count-up :end-val="conversion(statData.powerboxCount)" :start-val="0" :key="countIndex"></count-up>{{statData.powerboxCount>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon3" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">单灯总数(个)</div>
        <div class="stat-box-num textSiz">
          <count-up :end-val="conversion(statData.lightCount)" :start-val="0" :key="countIndex"></count-up>{{statData.lightCount>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon4" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">能耗总数(KW·h)</div>
        <div class="stat-box-num textSiz">
          <count-up :end-val="conversion(statData.energyTotal)" :start-val="0" :key="countIndex"></count-up>{{statData.energyTotal>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon5" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">节能总数(KW·h)</div>
        <div class="stat-box-num textSiz">
          <count-up :end-val="conversion(statData.energyConservation)" :start-val="0" :key="countIndex"></count-up>{{statData.energyConservation>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon6" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">减碳总数(千克)</div>
        <div class="stat-box-num textSiz">
          <count-up :end-val="conversion(statData.carbonReduction)" :start-val="0" :key="countIndex"></count-up>{{statData.carbonReduction>100000?'万':''}}</div>
      </div>
    </div>
    <div class="stat-box">
      <img :src="img.customized.ver1.totalIcon7" alt="" class="stat-box-img">
      <div class="stat-box-total">
        <div class="stat-box-name">减少砍伐树木(棵)</div>
        <div class="stat-box-num textSiz">
          <count-up :end-val="conversion(statData.treeReduction)" :start-val="0" :key="countIndex"></count-up>{{statData.treeReduction>100000?'万':''}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import CountUp from 'vue-countup-v2';
export default {
  name: 'totalPage',
  components: {
    CountUp
  },
  props: {
  },
  data() {
    return {
      statData: {
        carbonReduction: 194556440.59,
        energyConservation: 349418894.74,
        energyTotal: 34419595.26,
        lightCount: 29228,
        powerboxCount: 1613,
        projectCount: 410,
        treeReduction: 17186965,
      },
      countIndex:0,
      stopTime: null,
    }
  },
  mounted: function () {
    this.getStat()
    this.stopTime = setInterval(() => {
      this.countIndex++
    }, 7000);
  },
  methods: {
    getStat() {
      this.$axios.post(`visual/home/QueryDeviceAndEnergy`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'statData', res.data);
        }
      })
    },
    conversion(num) {
      if (num < 100000) {
        return num*1;
      }
      let digit = ''
      digit = (num / 10000).toFixed(2) 
      return digit*1;
    }
  },
  beforeDestroy() {
    clearTimeout(this.stopTime);
  }
}
</script>
<style scoped>
.totalPage {
  width: 100%;
  padding: 10px 50px;
  display: flex;
  /* background: rgba(25, 60, 96, 0.6); */

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 3%,
    rgba(25, 60, 96, 0.4) 12%,
    rgba(25, 60, 96, 0.6) 50%,
    rgba(25, 60, 96, 0.6) 75%,
    rgba(25, 60, 96, 0.4) 85%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 0 auto;
  justify-content: space-between;
  position: absolute;
  z-index: 40;
  bottom: 20px;
}
.stat-box-img {
  width: 56px;
  height: 68px;
}
.stat-box {
  display: flex;
}
.stat-box-total {
  margin-left: 10px;
  align-content: center;
}
.stat-box-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #9fc4de;
}
.stat-box-num {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 26px;
  color: #f6fafd;
  text-shadow: 0px 0px 18px #023657;
  background: -webkit-linear-gradient(
    -90deg,
    #fef9f2 16.6748046875%,
    #f6bc69 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textSiz {
  display: inline-block;
  animation: jump 1s 1;
}

@keyframes jump {
  20% {
    -webkit-transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-15deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}
/* .textSiz {
  display: inline-block;
  animation: jump 2s 3;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
} */
/* 
.textSiz {
  position: relative;
  color: transparent;
  background-color: #e8a95b;
  background-clip: text;
}
.textSiz::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 6rem,
    white 11rem,
    transparent 11.15rem,
    transparent 15rem,
    rgba(255, 255, 255, 0.3) 20rem,
    transparent 25rem,
    transparent 27rem,
    rgba(255, 255, 255, 0.6) 32rem,
    white 33rem,
    rgba(255, 255, 255, 0.3) 33.15rem,
    transparent 38rem,
    transparent 40rem,
    rgba(255, 255, 255, 0.3) 45rem,
    transparent 50rem,
    transparent 100%
  );
  background-clip: text;
  background-size: 150% 100%;
  background-repeat: no-repeat;
  animation: shine 3s infinite linear
}
@keyframes shine {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -190% 0;
  }
} */
</style>
