<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <span class="alarm-data-name">
        {{showLang('com.ins.ch.order')}}：
      </span>
      <Select :placeholder="showLang('save.select')" v-model="filter.channel" style="width: 100px; margin-right:5px;">
        <Option :value="0">{{showLang('com.ins.ch.all')}}</Option>
        <Option v-for="n in 7" :value="n" :key="n">K{{n}}</Option>
      </Select>
      <span class="alarm-data-name">
        {{showLang('com.ins.timeTable')}}：
      </span>
      <template>
        <Select :placeholder="showLang('save.select')" v-model="filter.timeIds" multiple
          style="width: 160px; margin-right:5px;">
          <Option v-for="(item, idx) in timeTables" :value="item.id" :key="idx">
            [{{item.mode}}][{{item.type}}]{{item.name}}</Option>
        </Select>
      </template>
      <AuthButton v-for="(cmd, idx) in canDoCmds" :key="idx" :opCode="cmd.opcode" size="default"
        style="margin-right: 3px" @click="clickCmd(cmd)">
        {{showLang(cmd.lang)}}</AuthButton>
      <Button type="success" size="default" style="margin-right: 5px"
        @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      <Button type="success" size="default" style="margin-right: 5px"
        @click="exportResult">{{showLang('com.op.export.results')}}</Button>
      <Checkbox v-model="clearOldLog">{{showLang('com.lamp.is.ins')}}</Checkbox>
      <Checkbox v-model="isScreenSelected">{{showLang('com.lamp.is.screen')}}</Checkbox>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="devices" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="pname" :title="showLang('com.ins.ch.name')" sortable width="250" header-align="center">
        </vxe-column>
        <vxe-column field="sname" :title="showLang('com.site.name')" :filters="[{ data: '' }]"
          :filter-method="filterStationMethod" sortable width="150" header-align="center">
          <template #filter="{ $panel, column }">
            <input type="type" v-for="(option, index) in column.filters" :key="index" v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)">
          </template>
        </vxe-column>
        <vxe-column field="channel" :title="showLang('com.ins.ch.order')" sortable width="100" header-align="center">
          <template #default="{ row }">
            K{{row.channel}}
          </template>
        </vxe-column>
        <vxe-column field="timeName" :title="showLang('com.export.cn.plan.relate')" sortable width="200"
          header-align="center">
        </vxe-column>
        <vxe-column field="message" :title="showLang('com.ins.result')" header-align="center" sortable></vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
    <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from './ModalArgEdit'
import exportXlsx from '@/components/xlsx/exportXlsx'
import ModalCheckAuth from './ModalCheckAuth'
export default {
  name: 'CommandChannelIndex',
  components: {
    ModalArgEdit,
    ModalCheckAuth,
  },
  props: {
  },
  data() {
    return {
      filter: {
        stationId: 0,
        channel: 0,
        timeIds: [],
      },
      timeTables: [],
      clearOldLog: this.clearOldLogs,
      cmdType: 'output',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      tabHeight: 600,
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      items: [
        { needArgs: false, needPswd: true, code: 'handControl', opcode: 'dco', name: '立即开关灯', lang: 'com.but.soon.switch.lamp', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: true, needPswd: false, code: 'getYearPlan', opcode: 'dcs', name: '读取时间表', lang: 'com.but.get.time.tab', img: this.img.light.road.single.ctrl.readTime },
        { needArgs: false, needPswd: true, code: 'setYearPlan', opcode: 'dcc', name: '下发时间表', lang: 'com.but.set.time.tab', img: this.img.light.road.single.ctrl.writeTime },
        { needArgs: true, needPswd: true, code: 'setWeekControl', opcode: 'dcc', name: '设置周计划', lang: 'com.date.plan.set.weekly', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: true, needPswd: true, code: 'getWeekControl', opcode: 'dcs', name: '查询周计划', lang: 'com.date.plan.get.weekly', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: true, needPswd: true, code: 'setTimeEnable', opcode: 'dcc', name: '设置时段', lang: 'com.date.set.period', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: true, needPswd: true, code: 'getTimeEnable', opcode: 'dcs', name: '查询时段', lang: 'com.date.get.period', img: this.img.light.road.single.ctrl.readClock },
        // {needArgs: false, needPswd: true, code: 'closeLight', name: '立即关灯', img: this.img.light.road.single.ctrl.writeClock},
        // {needArgs: false, needPswd: true, code: 'openPlan', name: '开关灯计划', img: this.img.light.road.single.ctrl.writeClock},
        // {needArgs: false, needPswd: true, code: 'planLog', name: '计划记录', img: this.img.light.road.single.ctrl.writeClock},
      ],
      devices: [],
      dataRefresh: 0,
      arrCmds: [],
      cmdResults: {},
      timer: null,
      originalDevices: [],
      isScreenSelected: false,
      powerTableActions: {
        0: '关闭',
        1: '开启',
        2: '无效'
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'weeks1', 'ampm', 'monitorActionTypes',]),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.items) {
        if (this.config.outputShowCommands.indexOf(cmd.code) == -1) continue;
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    }
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    isScreenSelected() {
      this.screenSelected();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    'filter.stationId'() {
      this.initList();
    },
    'filter.channel'() {
      this.initList();
    },
    'filter.timeIds'() {
      this.initList();
    },
    selectedNode() {
      this.initList();
    },
  },
  mounted: function () {
    this.clearOldLog = this.clearOldLogs;
    setTimeout(this.setTableHeight, 100);
    this.getUseablePlan();
    this.initList();
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      // this.cmdResults[params.cmdId] = params;
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    })
    this.powerTableActions = {
      0: this.showLang('com.op.close'),// '关闭',
      1: this.showLang('com.state.open'),// '开启',
      2: this.showLang('com.state.invalid'),// '无效'
    }
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    screenSelected() {
      if (this.isScreenSelected) {
        this.devices = this.devices.filter(p => p.checked);
      } else {
        this.devices = this.originalDevices;
      }
    },
    filterStationMethod({ option, row }) {
      return row.sname.indexOf(option.data) != -1;
    },
    setCmdResult: function () {
      // console.log('set cmd result', this.cmdResults)
      for (let cmdId in this.cmdResults) {
        let ds = this.devices.filter(p => p.cmdId == cmdId);
        if (ds.length == 0) {
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            console.log('cmd id time out');
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    calcSuccessRate: function () {
      let total = this.devices.length;
      let success = this.devices.filter(p => p.status == 9).length;
      let rate = 0;
      if (total <= 0) {
        rate = 0;
      } else {
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', { total, success, rate });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryChannelPlan`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    exportResult: function () {
      let cmdData = [];
      this.devices.map(item => {
        cmdData.push({
          name: item.pname,
          sname: item.sname,
          channel: `K${item.channel}`,
          timeId: item.timeName,
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        name: this.showLang('com.export.cn.devname'),
        sname: this.showLang('com.site.name'),
        channel: this.showLang('com.ins.ch.order'),
        timeId: this.showLang('com.export.cn.plan.relate'),
        result: this.showLang('com.ins.result')
      }
      exportXlsx(cmdData, tabHead, this.showLang('com.ins.result'));
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 5;
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      let code = '';
      let args = {};
      for (let d of this.devices) {
        if ((d._status == 3 || d._status == 7 || d._status == 8) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          // this.$set(d, 'result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, 'message', '准备重发')
          // cmds.push({ id: d.id, code: d.cmd, name: d.name, deviceId: d.devId, args: d.args });
          cmds.push(d.id);
          code = d.cmd;
          args = d.args;
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      let cmd = {
        code: code,
        list: cmds,
        checkUserId: 0,
        args: args
      };
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    initList: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryOutput`, { groupId, stationId, channel: this.filter.channel, timeIds: this.filter.timeIds }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          let devs = [];
          for (let p of res.data) {
            devs.push({ id: p.id, pname: p.name, devId: p.deviceId, sname: p.stationName, devType: p.devType, timeId: p.content.timeId, timeName: p.timeName, channel: p.content.oc, cmdId: 0, cmd: '', name: '', args: {}, content: {}, time: 0, status: 0, result: '', message: '' })
          }
          this.$set(this, 'originalDevices', devs);
          this.$set(this, 'devices', devs);
          this.calcSuccessRate();
        }
      });
    },
    cmdAuthUserChecked: function(params){
      // console.log('auth user check result', params, this.checkAuthModalSuccessFunction);
      if(this.checkAuthModalSuccessFunction){
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function(type, cmdCode, isOpen, checkOkFun){
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, {type, code: cmdCode, open: isOpen}).then(res => {
        if(res.code != 0)return;
        if(!res.data.need){
          checkOkFun({userId: 0});
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    clickCmd: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.ins.ch.select'));
        return;
      }
      if (params.code == 'getYearPlan') {
        let now = new Date().format('yyyy-MM-dd');
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: { single: true, date: now } };
        this.showArgModal = true;
        return;
      } else if (params.code == 'setYearPlan') {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else if (params.code == 'handControl') {
        let now = new Date();
        now.setMinutes(now.getMinutes() + 60);
        this.cmdItem = { paramsCode: params.code, code: 'handControl', name: params.name, pswd: params.needPswd, args: { open: true, expire: now.format('yyyy-MM-dd HH:mm:ss') } }
        this.showArgModal = true;
        return;
      } else if (params.code == 'setWeekControl') {
        let now = new Date();
        now.setMinutes(now.getMinutes() + 60);
        this.cmdItem = { paramsCode: params.code, code: 'setWeekControl', name: params.name, pswd: params.needPswd, args: { open: true, expire: now.format('yyyy-MM-dd HH:mm:ss') } }
        this.showArgModal = true;
        return;
      } else if (params.code == 'setTimeEnable') {
        this.cmdItem = { paramsCode: params.code, code: 'setTimeEnable', name: params.name, pswd: params.needPswd, args: { list: [true, true, true, true] } }
        this.showArgModal = true;
        return;
      } else if (params.code == 'closeLight') {
        let now = new Date();
        now.setMinutes(now.getMinutes() + 60);
        this.cmdItem = { paramsCode: params.code, code: 'handControl', name: params.name, pswd: params.needPswd, args: { open: false, expire: now.format('yyyy-MM-dd HH:mm:ss') } }
        this.showArgModal = true;
        return;
      } else if (params.code == 'openPlan') {
        this.showCreateModal = true;
      } else if (params.code == 'planLog') {
        this.showPlanModal = true;
      } else {
        this.checkCmdAuth(this.cmdType, params.code, false, (checkResult) => {
          if (this.clearOldLog) {
            for (let item of this.devices) {
              item.result = '';
              item.status = 0;
            }
          }
          let cmds = []
          let ds = {};
          for (let chk of chks) {
            if (chk.topId == 0) {
              ds[chk.sname] = chk.devId;
            } else {
              ds[chk.sname] = chk.topId;
            }
            cmds.push(chk.id);
          }
          let cmd = {
            code: params.code,
            list: cmds,
            checkUserId: checkResult.userId,
            args: params.args
          };
          this.sendCommand(cmd);
        });
      }
    },
    argsSaved: function (params) {
      this.checkCmdAuth(this.cmdType, params.paramsCode, params.args.open == true, (checkResult) => {
        this.showArgModal = false;
        if (this.clearOldLog) {
          for (let item of this.devices) {
            item.result = '';
            item.status = 0;
          }
        }
        let chks = this.$refs.chkTable.getCheckboxRecords();
        let cmds = []
        let ds = {};
        for (let chk of chks) {
          ds[chk.sname] = chk.topId;
          cmds.push(chk.id);
        }
        let cmd = {
          code: params.code,
          list: cmds,
          checkUserId: checkResult.userId,
          args: params.args
        };
        this.sendCommand(cmd);
      });
    },
    getCmdStatus: function (cmd) {
          console.log('getCmdStatus', cmd)
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 2: return this.showLang('cmd.has.sent') + this.showLang('com.ins.dev.reply');
        case 3: return cmd.result;
        case 4: return this.showLang('com.ins.execution') + `：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd.result}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail') + `：${cmd.result}`;
        case 9: {
          // let cs = this.commands.filter(p => p.id == cmd.id);
          // if(cs.length == 0)return '';
          switch (cmd.cmd) {
            case 'getTimeEnable': {
              let ch = cmd.channel;
              let content = cmd.content[`channel${ch}`];
              let chs = [];
              for (let i = 0; i < 4; i++) {
                chs.push(`${this.showLang('com.date.period')}${i + 1}: ${content[i] ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}`);
              }
              return chs.join(', ');
            }
            case 'getWeekControl': {
              let re = `${this.showLang('com.ins.ch')}【K${cmd.channel}】`;
              for (let i = 1; i <= 7; i++) {
                for (let j = 0; j < 2; j++) {
                  let idx = (i - 1) * 2 + j + 1;
                  let enable = cmd.content[`enable${idx}`] ? this.showLang('com.state.enable') : this.showLang('com.state.disable');
                  re += `, ${this.weeks1[i]}${this.ampm[j]}${enable}`
                }
              }
              return re;
            }
            case 'getclock': return `${this.showLang('com.ins.success')}：${cmd.content.nowTime}`;
            case 'handControl': {
              let r = `${this.showLang('com.ins.success')}：${cmd.args.open ? this.showLang('com.but.open.lamp') : this.showLang('com.but.close.lamp')}`;
              if (cmd.devType != 'st6036') {
                r += ` ${this.showLang('com.date.to')}${this.showLang('com.date.time')} ${cmd.args.expire}`;
              }
              return r;
            }
            case 'getYearPlan': {
              // console.log('get year plan', cmd, cs[0])
              if (cmd.args.month != cmd.content.month) {
                return `${this.showLang('com.ins.success.WrongMonth', cmd.args.month)}${cmd.content.month}`;
              } else {
                let strs = [];
                let mode = cmd.content.mode;
                let obj = cmd.content[`day${cmd.args.day}`];
                if (mode == 4) { //北京模式
                  for (let i = 1; i <= 4; i++) {
                    let ao = this.powerTableActions[obj[`a${i}`]];
                    let to = obj[`o${i}`];
                    // let ac = this.powerTableActions[obj[`ac${i}`]];
                    let tc = obj[`c${i}`];
                    strs.push(`${to}-${tc}:${ao}`);
                  }
                } else if (mode == 5) { //集中器模式
                  for (let i = 1; i <= 6; i++) {
                    let a = obj[`a${i}`]; //this.monitorActionTypes[];
                    let t = obj[`t${i}`];
                    let v = this.monitorActionTypes[a]; //a == 1 ? '' : obj[`v${i}`];
                    if (a != 1) {
                      v += ` - ${obj[`v${i}`]}`
                    }
                    strs.push(`${t} - ${v}`);
                  }
                } else {
                  for (let i = 1; i <= 4; i++) {
                    let a = this.powerTableActions[obj[`a${i}`]];
                    let t = obj[`t${i}`];
                    strs.push(`${t} - ${a}`);
                  }
                }
                return `${this.showLang('com.ins.success')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}${this.showLang('com.date.plan')}：${strs.join(', ')}`
              }
            }
            default: return this.showLang('com.ins.success');

          }
        }
      }
    },
    sendCommand: function(cmd){
      if(cmd.list.length == 0)return;
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`//${this.domains.trans}/station/command/SendOutputOld`, cmd).then(res => {
        if (res.code == 0) {
          // console.log('send cmd result', res.data, this.list);
          for (let item of res.data.list) {
            let ds = this.devices.filter(p => p.id == item.id);
            // console.log('send cmd result', ds)
            if (ds.length == 0) continue;
            this.$set(ds[0], 'cmd', cmd.code);
            this.$set(ds[0], 'args', cmd.args);
            this.$set(ds[0], 'cmdId', item.success ? item.message : '');
            this.$set(ds[0], 'status', item.success ? 1 : 8);
            this.$set(ds[0], 'content', {});
            this.$set(ds[0], 'message', item.success ? '指令已进入发送队列' : item.message);
            // this.spanData();
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
}
.alarm-data-name {
  display: inline-block;
  height: 40px;
  white-space: nowrap;
  line-height: 40px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>