<template>
  <bm-control :offset="offset">
    <div class="map-tree-box" >
      <CommonTree />
    </div>
  </bm-control>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BmControl,
} from '@/components/vue-baidu-map-v3/components'

import CommonTree from '../../common/treeGroup/Index.vue'
export default {
  components: {
    BmControl,
    CommonTree
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
  },
  data() {
    return {
      offset: {
        width: 10,
        height: 10
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted() {
  },
  methods: {
   
  }
}
</script>

<style>
.map-tree-box {
  width: 300px;
  height: 100%;
}
</style>