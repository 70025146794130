<template>
<bm-marker 
  :dragging="false"
  :icon="{ url: getImgType(item), size: { width: 26, height: 36 } }"
  :position="{ lng: item.lng, lat: item.lat }"
  :name="item.name"
  @click="showMakerInfo">
  <bm-label
    :content="item.name"
    :offset="{ width: -60, height: 30 }"
    :labelStyle="{
      width: '150px',
      textAlign: 'center',
      backgroundColor: 'transparent',
      color: 'red',
      border: 0,
      fontSize: '12px',
    }"
  />
</bm-marker>
</template>
<script>
import {mapState} from 'vuex'
import { BmLabel, BmMarker, } from "@/components/vue-baidu-map-v3/components";
export default {
  name: "PointMaker",
  components: {
    BmMarker,
    BmLabel,
  },
  props: {
    item: {
      type: Object,
      default(){return {};}
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('common', ['poleTypeNames'])
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    getImgType: function () {
      let ts = this.poleTypeNames.filter(p => p.type == this.item.type);
      if(ts.length == 0)return this.img.pole.icons.pole.offline;
      let imgs = this.img.pole.icons[ts[0].code];
      if (!imgs) return this.img.pole.icons.pole.offline;
      if (!this.item.online) {
        return imgs.offline;
      } else if (this.item.alarm) {
        return imgs.alarm30;
      } else {
        return imgs.online;
      }
    },
    showMakerInfo: function () {
      this.$emit('click', this.item);
    },
  },
};
</script>
<style scoped>
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  /* padding: 10px 0 0 10px; */
}
.map {
  width: 100%;
  height: 100%;
}
.item-status-filter {
  /* width: 325px; */
  height: 45px;
  background-color: white;
  display: flex;
}
.filter-item {
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  cursor: pointer;
  user-select: none;
}
.filter-item img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.filter-active {
  color: #007eff;
}
.menu-btn {
  margin: 5px;
  float: right;
}
</style>