<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">{{showLang('com.edit.box.info.batch')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="100">
      <FormItem prop="groupId" :label="showLang('com.device.zone')">
        <Select :placeholder="showLang('save.select')" v-model="form.groupId" style="width:220px" :key="RefreshData">
          <template v-for="(item, idx) in groups">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        <Button style="margin-left: 10px" @click="selectGroupShow=true">{{showLang('com.op.select.zone')}}</Button>
      </FormItem>
      <FormItem prop="type" :label="showLang('com.site.type')">
        <Select :placeholder="showLang('save.select')" v-model="form.type">
          <template v-for="(v, k) in types">
            <Option :value="parseInt(k)" :key="k">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="name" :label="showLang('com.tab.title')">
        <Input ref="name" type="text" v-model="form.name" :placeholder="showLang('save.name')">
        </Input>
      </FormItem>
      <FormItem prop="area" :label="showLang('com.site.info.region')">
        <Input ref="area" type="text" v-model="form.area" :placeholder="showLang('com.site.info.region')">
        </Input>
      </FormItem>
      <FormItem prop="location" :label="showLang('com.Installation.position')">
        <Input ref="location" type="text" v-model="form.location" :placeholder="showLang('com.enter.Installation.position')">
        </Input>
      </FormItem>
      <FormItem prop="lng" :label="showLang('com.import.cn.lng')">
        <Input ref="lng" type="text" v-model="form.lng" :placeholder="showLang('import.lng.installation.location')">
        </Input>
      </FormItem>
      <FormItem prop="lat" :label="showLang('com.import.cn.lat')">
        <Input ref="lat" type="text" v-model="form.lat" :placeholder="showLang('import.lat.installation.location')">
        </Input>
      </FormItem>
      <FormItem prop="install" :label="showLang('com.date.installation')">
        <DatePicker ref="install" type="date" format="yyyy-MM-dd" v-model="form.install" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
      </FormItem>
      <FormItem prop="flowmode" :label="showLang('station.flow.mode')">
        <div style="display:flex;">
          <Checkbox v-model="form.lowFlow" title="低流量模式下，会在设置时段内禁止站点下所有集中器的巡检单灯镜像等大流量动作">是否启用低流量？</Checkbox>
          <template v-if="form.lowFlow">
            启用时段：
            <TimePicker style="width: 80px;margin-right:5px;" v-model="form.lowStart" format="HH:mm" :clearable="false"></TimePicker>
            至
            <TimePicker style="width: 80px;margin-left: 5px;" v-model="form.lowEnd" format="HH:mm" :clearable="false"></TimePicker>
          </template>
        </div>
      </FormItem>
      <FormItem prop="enableGis" label="是否配电箱">
        <div style="display:flex;">
          <Checkbox v-model="form.enableGis" title="">{{form.enableGis?'是':'否'}}</Checkbox>
          <span class="20 red" v-if="!form.enableGis">否的情况下，默认此为道路</span>
        </div>
      </FormItem>
      <FormItem prop="isTunnel" label="是否隧道业务">
        <div style="display:flex;">
          <Checkbox v-model="form.isTunnel" title="">{{form.isTunnel?'是':'否'}}</Checkbox>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <Modal v-model="selectGroupShow" :mask-closable="false" width="300px" :title="showLang('com.op.select.group')" @on-ok="okTree">
      <Tree :data="groupsArr" :show-checkbox="true" :check-strictly="true" @on-check-change="treeChange" :key="RefreshData"></Tree>
    </Modal>
  </Modal>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'ModalStationEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    stationType: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      groupName: '',
      form: {
        id: 0,
        groupId: 0,
        type: 0,
        name: '',
        location: '',
        install: '',
        stype: 0,
        area: '',
        lng: '',
        lat: '',
        lowFlow: false,
        lowStart: '08:00',
        lowEnd: '18:00',
        enableGis: true,
        isTunnel: false,
      },
      rules: {
        name: { required: true, type: 'string', max: 30, message: '名称不能为空且长度必须少于30个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        area: { required: false, type: 'string', max: 30, message: '所属区域长度必须少于30个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
      types: {},
      groupsArr: [],
      selectGroupShow: false,
      RefreshData: 0,
      checkedId: '',
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.form.stype = this.stationType;
        // if (this.appType == 'pole') {
        //   this.$set(this, 'types', this.poleType)
        // } else {
        //   this.$set(this, 'types', this.powerBoxTypes)
        // }
        this.$set(this, 'types', this.powerBoxTypes)
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.form.id = this.item.data.id;
          this.form.groupId = this.item.data.groupId;
          this.form.name = this.item.data.name;
          this.form.type = this.item.data.stationType;
          this.form.location = this.item.data.location;
          this.form.lng = this.item.data.lng;
          this.form.lat = this.item.data.lat;
          this.form.area = this.item.data.area;
          this.form.install = new Date(this.item.data.install).format('yyyy-MM-dd');
          this.form.lowFlow = this.item.data.lowFlow;
          this.form.lowStart = this.item.data.lowStart;
          this.form.lowEnd = this.item.data.lowEnd;
          this.form.enableGis = this.item.data.enableGis;
          this.form.isTunnel = this.item.data.isTunnel;
          if(!this.form.lowStart){
            this.form.lowStart = '08:00';
          }
          if(!this.form.lowEnd){
            this.form.lowEnd = '18:00';
          }
        } else {
          this.form.id = '';
          this.form.groupId = this.item.data.groupId;
          this.form.name = '';
          this.form.type = 1;
          this.form.location = '';
          this.form.area = '';
          this.form.lng = "";
          this.form.lat = "";
          this.form.install = new Date().format('yyyy-MM-dd');
          this.form.lowFlow = false;
          this.form.lowStart = "08:00";
          this.form.lowEnd = "18:00";
          this.form.enableGis = true;
          this.form.isTunnel = false;
        }
      }
      this.groupsArr = this.treeData(this.groups, 0)
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('auth', ['user', 'appType']),
    ...mapState('common', ['poleType', 'powerBoxTypes']),
    ...mapState('group', ['groups']),
  },
  mounted: function () {
    this.rules.name.message=this.showLang('com.save.err.len.name',30)
    this.rules.location.message=this.showLang('com.save.err.len.install',50)
  },
  methods: {
    okTree() {
      this.selectGroupShow = false;
      if (this.checkedId > -1) {
        this.form.groupId = this.checkedId;
        this.RefreshData++;
      }
    },
    treeChange(data, checked) {
      if (checked.checked) {
        this.checkedId = checked.id;
        for (let index = 0; index < data.length; index++) {
          if (data[index].id != checked.id) {
            data[index].checked = false
          }
        }
      } else {
        this.checkedId = -1
      }
    },
    treeData(list, root) {
      const arr = [];
      list.forEach((item) => {
        item.title = item.name;
        if (item.parentId === root) {
          const children = this.treeData(list, item.id)
          if (children.length > 0) {
            item.children = children
          }
          arr.push(item)
        }
      })
      this.RefreshData++;
      return arr
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      if (this.form.groupId == 0) {
        this.$Message.warning(this.showLang('com.select.non.root.node'));
        return;
      }
      if(this.form.lowFlow){
        if(!this.form.lowStart){
          this.$Message.warning(this.showLang('time.start.need'));
          return;
        }
        if(!this.form.lowEnd){
          this.$Message.warning(this.showLang('time.end.need'));
          return;
        }
      }
      this.form.install = new Date(this.form.install).format('yyyy-MM-dd');
      this.$store.dispatch('group/saveStation', { isAdd: this.isAdd, form: this.form }).then(res => {
        if (res.code == 0) {
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          window.eventBus.$emit('stationChanged');
        } else {
          // this.$Message.error(res.data);
        }
      });
    },
    cancel() {
      this.showModal = false;
      this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
</style>