<template>
  <div class="setting">
    <titlePage :option='title' />
    <div class="setting-box">
      <div class="item-info">
        <div class="info-box">
          <div class="info-name" :title="showData.name">{{showData.name}}</div>
          <div class="info-weather">
            <img :src="weather.url" alt="" class="weather">
            <div class="info-heat"><count-up :end-val="weather.degree*1" :start-val="0" :key="countIndex"></count-up><i>℃</i></div>
            <div class="info-con">
              <div class="con-site">{{showData.city}}</div>
              <div class="con-sky">{{weather.type}}</div>
            </div>
          </div>
        </div>
        <div class="info-box">
          <div class="update-time">更新时间：{{today.day}}</div>
          <div class="year-light">全年亮灯(小时)</div>
          <div class="year-num"><count-up :end-val="listLightOn.lightOnYear*1" :start-val="0" :key="countIndex"></count-up></div>
        </div>
      </div>
      <div class="time-list">
        <div class="time-box">
          <div class="time-icon">
            <img :src="img.customized.ver1.timeGo" alt="">
            <div>日出时间</div>
          </div>
          <div class="time-num">{{today.sunRise}}</div>
        </div>
        <div class="time-box">
          <div class="time-icon">
            <img :src="img.customized.ver1.timeOut" alt="">
            <div>日落时间</div>
          </div>
          <div class="time-num">{{today.sunSet}}</div>
        </div>
        <div class="time-box">
          <div class="time-icon">
            <img :src="img.customized.ver1.timeOpen" alt="">
            <div>开灯时间</div>
          </div>
          <div class="time-num">{{today.lightOn}}</div>
        </div>
        <div class="time-box">
          <div class="time-icon">
            <img :src="img.customized.ver1.timeClose" alt="">
            <div>关灯时间</div>
          </div>
          <div class="time-num">{{today.lightOff}}</div>
        </div>
      </div>
      <div class="time-curve">
        <div class="time-title">
          <div class="time-title-unit">(单位:h)</div>
          <div class="time-title-show">
            <div class="show-con"><i style="background: #4F9DFC;"></i><span>日出时间</span></div>
            <div class="show-con"><i style="background: #6674FF;"></i><span>日落时间</span></div>
            <div class="show-con"><i style="background: #DEB475;"></i><span>开灯时间</span></div>
            <div class="show-con"><i style="background: #3FEEBE;"></i><span>关灯时间</span></div>
          </div>
        </div>
        <div class="chartsLine">
          <chartsLine :option="timeLine" id="settingtimeLine" :key="timer"></chartsLine>
        </div>
      </div>
      <div class="light-curve">
        <div class="light-title">
          <div class="light-title-name">亮灯率</div>
          <div class="light-title-unit">(单位:%)</div>
        </div>
        <div class="chartsLine">
          <chartsLine :option="lightLine" id="settinglightLine" :key="timer"></chartsLine>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titlePage from '../../public/title'
import { weatherVer1Data } from '@/plugins/weather'
// import * as echarts from "echarts";
import CountUp from 'vue-countup-v2';
export default {
  name: 'setting',
  components: {
    titlePage,
    CountUp
  },
  props: {
  },
  data() {
    return {
      title: {
        zh: '环境信息', en: 'ENVIRONMENTAL INFORMATION'
      },
      weather: {
        icon: '--',
        degree: '--',
        type: '--',
        url: `//${this.domains.imgs}/imgs/customized/weather1/yin.png`,
      },
      showData: {},
      timer: 0,
      timeLine: {
        color: ['#4F9DFC', '#6674FF', '#DEB475', '#3FEEBE'],
        title: '',
        isAnimation: false,
        xColor: '#B5C1CC',
        yColor: '#99A8B6',
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        x: [],
        unit: '',
        series: [//
          {
            name: '日出时间',
            data: []
          },
          {
            name: '日落时间',
            data: []
          },
          {
            name: '开灯时间',
            data: []
          },
          {
            name: '关灯时间',
            data: []
          }
        ],
        cover: {
          // xAxis.axisLabel. formatter
          // type: 'time',
          // xAxis: {
          //   axisLabel: {
          //     formatter: function (value, index) {
          //       console.log(value, index)
          //       return value;
          //     }
          //   }
          // },
          yAxis: {
            type: 'time',
            name: "",
            splitLine: {
              lineStyle: {
                color: 'rgba(34, 51, 83, 0.4)'//rgba(3, 16, 33, 0.9) rgba(34, 51, 83, 0.8)
              }
            },
            axisLabel: {
              textStyle: {
                color: '#7587A0',
                fontSize: 12
              },
              formatter: '{HH}:{mm}',
            }
          },
        },
        // setSeries: {
        //   smooth: true, //平滑折线图
        // }
      },
      lightLine: { //
        color: ['#40F3FB'],
        title: '',
        isAnimation: false,
        x: [],
        unit: '',
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        series: [
          {
            name: '亮灯率',
            data: []
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#40F3FB", // 0% 处的颜色
                },
                {
                  offset: 0.5,
                  color: 'rgba(64, 243, 251, 0.6)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255, 0)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          } //下阴影
        }
      },
      list: [],
      listLightOn: {},
      listIndex: 0,
      projectTimeout: null,
      today: {
        day: "--",
        lightOff: "--",
        lightOn: "--",
        sunRise: "--",
        sunSet: "--"
      },
      countIndex: 0,
      stopTime: null,
    }
  },
  mounted: function () {
    // console.log(weatherData)
    this.QueryProject()
    this.stopTime = setInterval(() => {
      this.countIndex++
    }, 10000);
  },
  methods: {
    QueryProject() {
      this.$axios.post(`visual/home/QueryProject`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
          // res.data.forEach(el => {
          //   if (el.name == '亚美达现代') {
          //     console.log('亚美达现代')
          //     console.log(el)
          //   }
          // });
          this.QueryProjectLightOn(this.list[0])
        }
      })
    },
    QueryProjectLightOn(el) {
      //id: 4, "isVirtual": false
      this.showData = el;
      this.QueryWeather(el)
      this.$axios.post(`visual/home/QueryProjectLightOn`, { id: el.id, "isVirtual": el.isVirtual }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'listLightOn', res.data);
          if (res.data.today == null) {
            this.today = { day: "--", lightOff: "--", lightOn: "--", sunRise: "--", sunSet: "--" }
          } else {
            this.$set(this, 'today', res.data.today);
          }
          this.lightLine.x = [];
          this.lightLine.series[0].data = [];

          this.timeLine.x = [];
          this.timeLine.series[0].data = [];
          this.timeLine.series[1].data = [];
          this.timeLine.series[2].data = [];
          this.timeLine.series[3].data = [];
          if (res.data.rates.length != 0) {
            res.data.rates.forEach(el => {
              this.lightLine.x.push(el.date)
              this.lightLine.series[0].data.push(el.rate.toFixed(2))
            });
          }
          if (res.data.times.length != 0) {
            res.data.times.forEach((el, index) => {
              this.timeLine.x.push(el.day)
              // this.timeLine.series[0].data.push(el.sunRise)
              // this.timeLine.series[1].data.push(el.sunSet)
              // this.timeLine.series[2].data.push(el.lightOn)
              // this.timeLine.series[3].data.push(el.lightOff)
              this.timeLine.series[0].data.push(this.timeToMinutes(el.sunRise))
              this.timeLine.series[1].data.push(this.timeToMinutes(el.sunSet))
              this.timeLine.series[2].data.push(this.timeToMinutes(el.lightOn))
              this.timeLine.series[3].data.push(this.timeToMinutes(el.lightOff))
              if (index == 364) {
                this.timer++;
              }
            });
          }
          // console.log('this.timeLine.series-----------')
          // console.log(this.timeLine.series)
          // setTimeout(() => {
          //   this.timer++;
          // }, 500);
          this.projectTimeout = setTimeout(() => {
            this.listIndex++;
            if (this.listIndex == this.list.length) {
              this.listIndex = 0
            }
            this.QueryProjectLightOn(this.list[this.listIndex]);
          }, 10000);
        }
      });
    },
    timeToMinutes(time) {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1; // 月份是从0开始的，所以需要加1
      let day = today.getDate();
      // console.log(day2 + ' ' + time)
      return year + '-' + month + '-' + day + ' ' + time;
    },

    QueryWeather(el) {
      this.$axios.post(`visual/home/QueryWeather`, { city: el.city }).then(res => {
        if (res.code == 0) {
          let city = res.data
          this.weather.degree = res.data.temperature //天气
          this.weather.type = city.weather
          weatherVer1Data.forEach(el => {
            el.have.forEach(ele => {
              if (ele == city.weather) {
                this.weather.url = el.url
              }
            })
          })
        } else {
          this.weather.degree = '----'
          this.weather.type = '----'
          this.weather.url = weatherVer1Data[0].url //默认
        }
      })
    },

  },
  beforeDestroy() {
    clearTimeout(this.projectTimeout);
    clearTimeout(this.stopTime);
  }
}
</script>
<style scoped>
.setting {
  width: 100%;
  height: 100%;
}
.setting-box {
  width: calc(100%);
  padding-left: 22px;
}
.item-info {
  display: flex;
  padding-top: 20px;
}
.info-box {
  width: 50%;
}
.weather {
  width: 58px;
  height: 48px;
}
.info-name {
  width: 100%;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #a3d2fe;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.info-weather {
  display: flex;
  height: 44px;
  margin-top: 20px;
}
.info-heat {
  font-family: Bahnschrift;
  font-weight: 400;
  color: #f7fbfd;
  font-size: 60px;
  text-shadow: 0px 0px 12px #0e398c;
  line-height: 44px;
  position: relative;
  top: 2px;
  margin: 0 20px;
  filter: blur(0.2px);
  filter: brightness(0.4); /**调整亮度。**/
  filter: contrast(300%); /**调整对比度。**/
}
.info-heat i {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 26px;
  color: #9ab9d7;
  position: relative;
  top: -22px;
  font-style: inherit;
  font-family: cursive;
}
.con-site {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #c7e1fb;
}
.con-sky {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #9ab9d7;
}
.update-time {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #516f8d;
}
.year-light {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #9ab9d7;
  margin-top: 20px;
  filter: blur(0.6px);
}
.year-num {
  font-family: Bahnschrift;
  font-weight: 400;
  font-size: 24px;
  color: #f7fbfd;
  text-shadow: 0px 0px 14px #002e66;
  background: -webkit-linear-gradient(
    -90deg,
    #fef9f2 15%,
    rgba(90, 146, 215, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.time-list {
  width: calc(100%);
  height: 71px;
  background: linear-gradient(180deg, #274979, #111b2c);
  opacity: 0.8;
  padding: 0 30px;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}
.time-box {
  align-content: center;
}
.time-icon {
  display: flex;
}
.time-icon div {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #9ab9d7;
  margin-left: 5px;
}
.time-num {
  font-family: Bahnschrift;
  font-size: 22px;
  color: #fff;
  text-indent: 28px;
}
.time-curve {
  width: 100%;
}

.time-title {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.time-title-unit {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #6989b3;
}
.show-con i {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #4f9dfc;
  margin: 0 8px 0 18px;
}
.show-con span {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #7c92af;
}
.time-title-show {
  display: flex;
}
.light-curve {
  width: 100%;
}
.light-title {
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.light-title-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #a3d2fe;
}
.light-title-unit {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #6989b3;
}
.chartsLine {
  width: 100%;
  height: 198px;
}
</style>
