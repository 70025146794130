// import Vue from 'vue';
export const weatherData = [{
  name: "默认",
  have: ['默认', '未知'],
  url: `//${window.domains.imgs}/imgs/weather/yin.png`,
},
{
  name: "阵雨",
  have: ["阵雨", '强阵雨', '极端降雨'],
  url: `//${window.domains.imgs}/imgs/weather/zhenyu.png`,
},
{
  name: "雷阵雨",
  have: ["雷阵雨", '强雷阵雨'],
  url: `//${window.domains.imgs}/imgs/weather/leizhenyu.png`,
},
{
  name: "雷阵雨伴冰雹",
  have: ["雷阵雨伴冰雹", "雷阵雨并伴有冰雹"],
  url: `//${window.domains.imgs}/imgs/weather/leiyubingbao.png`,
},
{
  name: "小雨",
  have: ["小雨", '毛毛雨/细雨'],
  url: `//${window.domains.imgs}/imgs/weather/xiaoyu.png`,
},
{
  name: "中雨",
  have: ["中雨"],
  url: `//${window.domains.imgs}/imgs/weather/zhongyu.png`,
},
{
  name: "大雨",
  have: ["大雨"],
  url: `//${window.domains.imgs}/imgs/weather/dayu.png`,
},
{
  name: "暴雨",
  have: ['暴雨', '大雨-暴雨'],
  url: `//${window.domains.imgs}/imgs/weather/baoyu.png`,
},
{
  name: "大暴雨",
  have: ["大暴雨", '暴雨-大暴雨'],
  url: `//${window.domains.imgs}/imgs/weather/dabaoyu.png`,
},
{
  name: "特大暴雨",
  have: ["特大暴雨", '大暴雨-特大暴雨'],
  url: `//${window.domains.imgs}/imgs/weather/tedabaoyu.png`,
},

{
  name: "雨",
  have: ["雨"],
  url: `//${window.domains.imgs}/imgs/weather/yu.png`,
},
{
  name: "小到中雨",
  have: ["小到中雨", '小雨-中雨'],
  url: `//${window.domains.imgs}/imgs/weather/xiaozhongyu.png`,
},

{
  name: "中到大雨",
  have: ["中到大雨", '中雨-大雨'],
  url: `//${window.domains.imgs}/imgs/weather/zhongdayu.png`,
},
{
  name: "雨夹雪",
  have: ["雨夹雪", '阵雨夹雪', '冻雨', '雨雪天气'],
  url: `//${window.domains.imgs}/imgs/weather/yujiaxue.png`,
},
{
  name: "雪",
  have: ["雪", '冷'],
  url: `//${window.domains.imgs}/imgs/weather/xue.png`,
},
{
  name: "降雪",
  have: ["降雪"],
  url: `//${window.domains.imgs}/imgs/weather/jiangxue.png`,
},
{
  name: "小雪",
  have: ["小雪"],
  url: `//${window.domains.imgs}/imgs/weather/xiaoxue.png`,
},
{
  name: "大雪",
  have: ["大雪", '中雪-大雪'],
  url: `//${window.domains.imgs}/imgs/weather/daxue.png`,
},
{
  name: "中雪",
  have: ["中雪", '小雪-中雪'],
  url: `//${window.domains.imgs}/imgs/weather/zhongxue.png`,
},
{
  name: "暴雪",
  have: ["暴雪", '大雪-暴雪'],
  url: `//${window.domains.imgs}/imgs/weather/baoxue.png`,
},
{
  name: "沙尘",
  have: ["沙尘", '浮尘', '扬沙', '沙尘暴', '强沙尘暴'],
  url: `//${window.domains.imgs}/imgs/weather/shachen.png`,
},
{
  name: "多云",
  have: ["多云", '晴间多云'],
  url: `//${window.domains.imgs}/imgs/weather/duoyun.png`,
},
{
  name: "晴",
  have: ["晴天", '晴', '热'],
  url: `//${window.domains.imgs}/imgs/weather/qingtian.png`,
},
{
  name: "阴",
  have: ["阴", '少云'],
  url: `//${window.domains.imgs}/imgs/weather/yin.png`,
},
{
  name: "风",
  have: ["风", '有风', '平静', '微风', '和风', '清风'],
  url: `//${window.domains.imgs}/imgs/weather/feng.png`,
},
{
  name: "大风",
  have: ["大风", '强风/劲风', '疾风', '大风', '烈风', '风暴', '狂爆风', '飓风', '热带风暴'],
  url: `//${window.domains.imgs}/imgs/weather/dafeng.png`,
},
{
  name: "龙卷风",
  have: ["龙卷风"],
  url: `//${window.domains.imgs}/imgs/weather/longjuanfeng.png`,
},
{
  name: "雾霾",
  have: ["雾霾", "霾", '中度霾'],
  url: `//${window.domains.imgs}/imgs/weather/wumai.png`,
},
{
  name: "严重霾",
  have: ["严重霾", '重度霾', '严重霾'],
  url: `//${window.domains.imgs}/imgs/weather/yanzhongwu.png`,
},
{
  name: "雾",
  have: ["雾", '轻雾'],
  url: `//${window.domains.imgs}/imgs/weather/wu.png`,
},
{
  name: "大雾",
  have: ["大雾", '浓雾', '强浓雾', '特强浓雾'],
  url: `//${window.domains.imgs}/imgs/weather/dawu.png`,
},


]
export const weatherVer1Data = [{
  name: "默认",
  have: ['默认', '未知'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/yin.png`,
},
{
  name: "阵雨",
  have: ["阵雨", '强阵雨', '极端降雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/zhenyu.png`,
},
{
  name: "雷阵雨",
  have: ["雷阵雨", '强雷阵雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/leizhenyu.png`,
},
{
  name: "雷阵雨伴冰雹",
  have: ["雷阵雨伴冰雹", "雷阵雨并伴有冰雹"],
  url: `//${window.domains.imgs}/imgs/customized/weather1/leiyubingbao.png`,
},
{
  name: "小雨",
  have: ["小雨", '毛毛雨/细雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/xiaoyu.png`,
},
{
  name: "中雨",
  have: ["中雨"],
  url: `//${window.domains.imgs}/imgs/customized/weather1/zhongyu.png`,
},
{
  name: "大雨",
  have: ["大雨"],
  url: `//${window.domains.imgs}/imgs/customized/weather1/dayu.png`,
},
{
  name: "暴雨",
  have: ['暴雨', '大雨-暴雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/baoyu.png`,
},
{
  name: "大暴雨",
  have: ["大暴雨", '暴雨-大暴雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/dabaoyu.png`,
},
{
  name: "特大暴雨",
  have: ["特大暴雨", '大暴雨-特大暴雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/tedabaoyu.png`,
},

{
  name: "雨",
  have: ["雨"],
  url: `//${window.domains.imgs}/imgs/customized/weather1/yu.png`,
},
{
  name: "小到中雨",
  have: ["小到中雨", '小雨-中雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/xiaozhongyu.png`,
},

{
  name: "中到大雨",
  have: ["中到大雨", '中雨-大雨'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/zhongdayu.png`,
},
{
  name: "雨夹雪",
  have: ["雨夹雪", '阵雨夹雪', '冻雨', '雨雪天气'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/yujiaxue.png`,
},
{
  name: "雪",
  have: ["雪", '冷'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/xue.png`,
},
{
  name: "降雪",
  have: ["降雪"],
  url: `//${window.domains.imgs}/imgs/customized/weather1/jiangxue.png`,
},
{
  name: "小雪",
  have: ["小雪"],
  url: `//${window.domains.imgs}/imgs/customized/weather1/xiaoxue.png`,
},
{
  name: "大雪",
  have: ["大雪", '中雪-大雪'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/daxue.png`,
},
{
  name: "中雪",
  have: ["中雪", '小雪-中雪'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/zhongxue.png`,
},
{
  name: "暴雪",
  have: ["暴雪", '大雪-暴雪'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/baoxue.png`,
},
{
  name: "沙尘",
  have: ["沙尘", '浮尘', '扬沙', '沙尘暴', '强沙尘暴'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/shachen.png`,
},
{
  name: "多云",
  have: ["多云", '晴间多云'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/duoyun.png`,
},
{
  name: "晴",
  have: ["晴天", '晴', '热'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/qingtian.png`,
},
{
  name: "阴",
  have: ["阴", '少云'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/yin.png`,
},
{
  name: "风",
  have: ["风", '有风', '平静', '微风', '和风', '清风'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/feng.png`,
},
{
  name: "大风",
  have: ["大风", '强风/劲风', '疾风', '大风', '烈风', '风暴', '狂爆风', '飓风', '热带风暴'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/dafeng.png`,
},
{
  name: "龙卷风",
  have: ["龙卷风"],
  url: `//${window.domains.imgs}/imgs/customized/weather1/longjuanfeng.png`,
},
{
  name: "雾霾",
  have: ["雾霾", "霾", '中度霾'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/wumai.png`,
},
{
  name: "严重霾",
  have: ["严重霾", '重度霾', '严重霾'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/yanzhongwu.png`,
},
{
  name: "雾",
  have: ["雾", '轻雾'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/wu.png`,
},
{
  name: "大雾",
  have: ["大雾", '浓雾', '强浓雾', '特强浓雾'],
  url: `//${window.domains.imgs}/imgs/customized/weather1/dawu.png`,
},
]

function weather() {
  return weatherData;
}
function weatherVer1() {
  return weatherVer1Data;
}

export {
  weather,
  weatherVer1
}