<template>
  <bm-control :offset="offset">
    <div class="map-search-box">
      <div class="map-search-choice">
        <RadioGroup v-model="modeType" type="button" button-style="solid" size="small">
          <Radio label="BMAP_NORMAL_MAP">{{showLang('gis.show.type.map')}}</Radio>
          <Radio label="BMAP_SATELLITE_MAP">{{showLang('gis.show.type.moon')}}</Radio>
        </RadioGroup>
        <RadioGroup v-model="deviceType" type="button" button-style="solid" size="small" class="ml20">
          <template v-for="(menu,index) in deviceArray">
            <Radio :label="menu.type" :key="index"> {{menu.lang?showLang(menu.lang):menu.name}}</Radio>
          </template>
        </RadioGroup>
      </div>
      <div class="map-search-set mt10">
        <!-- <RadioGroup v-model="modeMap" type="button" button-style="solid" size="small">
          <Radio label="true">精简</Radio>
          <Radio label="false">原本</Radio>
        </RadioGroup> -->
        <div class="map-tool-drag ">
          <span class="map-tool-drag-name mr5">{{showLang('gis.set.position')}}</span>
          <i-switch v-model="enableMoveDevice" size="large" style="width:54px;" true-color="#1B5FA8" false-color="#6D8194" :title="showLang('gis.switch.dev.position')">
            <span slot="open">{{showLang('com.state.open')}}</span>
            <span slot="close">{{showLang('com.state.prohibit')}}</span>
          </i-switch>
          <template v-if="enableMoveDevice">
            <i-switch v-model="batchSetPos" size="large" style="width:54px;" true-color="#1B5FA8" false-color="#6D8194" class="ml5">
              <span slot="open">{{showLang('com.op.batch')}}</span>
              <span slot="close">{{showLang('com.op.single')}}</span>
            </i-switch>
          </template>
        </div>
      </div>
      <div class="map-search-inp mt10">
        <input type="text" v-model="form.name" class="map-search-input" placeholder="设备名称">
        <input type="text" v-model="form.id" class="map-search-input ml20" placeholder="设备ID">
        <a href="javascript:(0)" class="map-search-but ml20" @click="switchDevice">搜索</a>
        <!-- <Input v-model="form.name" placeholder="设备名称" style="width: 115px;height:26px"></Input>
        <Input v-model="form.id" placeholder="设备ID" style="width: 115px;height:26px" class="ml15"></Input>
        <Button type="primary" @click="switchDevice" class="ml15">搜索</Button> -->
      </div>
      <div></div>
    </div>
  </bm-control>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BmControl,
} from '@/components/vue-baidu-map-v3/components'
export default {
  components: {
    BmControl
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          type: 'BMAP_NORMAL_MAP',
          offset: {
            width: 20,
            height: 30
          }
        };
      },
    },
  },
  watch: {
    modeType() {
      this.emitChanged(0)
    },
    deviceType() {
      this.emitChanged(1)
    },
    modeMap() {
      this.emitChanged(2)
    },
    enableMoveDevice() {
      this.emitChanged(3)
    },
    batchSetPos() {
      this.emitChanged(4)
    },
  },
  data() {
    return {
      modeType: 'BMAP_NORMAL_MAP',
      modeMap: 'true',
      deviceType: 'all',
      enableMoveDevice: false,
      batchSetPos: false,
      deviceArray: [
        { name: "全部", type: "all", lang: 'com.state.all' },
        { name: "配电柜", type: "powerbox", lang: 'com.device.box' },
        { name: "灯杆", type: "pole", lang: 'com.device.lamp' },
        // { name: "光控", type: "lux", lang: 'com.device.lightControl' },
        // { name: "电缆", type: "cable", lang: 'com.device.cable' },
      ],
      offset: {
        width: 320,
        height: 10
      },
      form: {
        id: '',
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted() {
  },
  methods: {
    switchDevice() {
      this.emitChanged(5)
    },
    emitChanged(num) {
      let data = {};
      switch (num) {
        case 0:
          data.type = this.modeType
          break;
        case 1:
          data.type = this.deviceType
          break;
        case 2:
          data.type = this.modeMap
          break;
        case 3:
          data.setDev = this.enableMoveDevice
          data.isAll = this.batchSetPos
          break;
        case 4:
          data.setDev = this.enableMoveDevice
          data.isAll = this.batchSetPos
          break;
        case 5:
          data.name = this.form.name
          data.id = this.form.id
          break;
      }
      this.$emit('changed', { num, data });
    },
  }
}
</script>

<style>
.map-search-box {
  /* width: 382px; */
  /* height: 118px; */
  background: #ffffff;
  border-radius: 4px;
  padding: 10px;
}

.map-search-inp {
  display: flex;
}
.map-tool-drag {
  width: max-content;
  border: 1px solid #3379fb;
  border-radius: 4px;
  padding: 2px 5px;
}
.map-tool-drag-name {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.map-tool-drag >>> .ivu-switch::after {
  top: 0;
}
.map-tool-drag >>> .ivu-switch:after {
  top: 0;
}
.map-search-input {
  width: 108px;
  height: 26px;
  border-radius: 4px;
  border: 1px solid #b7c0ce;
  text-indent: 5px;
}
input.map-search-input:focus {
  border: 1px solid #b4a3b6;
  outline: none;
}
.map-search-but {
  width: 42px;
  height: 26px;
  background: #3379fb;
  border-radius: 4px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
}
.map-search-set {
  display: flex;
  align-items: center;
}
</style>