<template>
  <div class="map-container">
    <baidu-map class="map" :ak="ak" :center="center" :map-type="mapMenuItem.type" :double-click-zoom="false" @ready="mapReady" :zoom="center.zoom" :min-zoom="levelSet.min" :scroll-wheel-zoom="true" @zoomend="mapZoomChanged" @dragend="dragend" @dblclick="mapClick" @mousemove="mapMouseMove">
      <bm-navigation v-if="map" anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
      <MenuMapType v-if="map" :item="mapMenuItem" @changed="mapTypeChanged" />
      <MenuDeviceType v-if="map" :item="mapDeviceType" @changed="devictTypeChanged" />
      <MenuMapTool v-if="map && funCodes('gsp')" :item="mapTools" @click="toolClicked" />
      <MenuMapTotal v-if="map" :item="mapTotals" />
      <template v-if="map">
        <template v-if="center.zoom >= 18">
          <template v-for="(gisItem, gisidx) in mapItemList">
            <MapLuxItem v-if="gisItem.type == 299" :editLuxId="editLuxId" :item="gisItem" :key="gisidx" @click="luxItemClick" @posChanged="luxPosChanged"></MapLuxItem>
            <MapLineItem v-else-if="gisItem.type == 10" :editLineId="editLineId" :item="gisItem" :key="gisidx" @click="lineItemClick"></MapLineItem>
            <MapPowerItem v-else-if="gisItem.type == 0" :enableMoveDevice="enableMoveDevice" :item="gisItem" :key="gisidx" @click="powerItemClick" @posChanged="powerPosChanged"></MapPowerItem>
            <MapPoleItem v-else-if="gisItem.type == 14" :enableMoveDevice="enableMoveDevice" :item="gisItem" :isSingleShow="gisItem.id == singleId" :key="gisidx" @click="poleItemClick" @posChanged="polePosChanged"></MapPoleItem>
          </template>
        </template>
        <template v-if="center.zoom < 18">
          <!-- <bm-point-collection :points="poleData" shape="BMAP_POINT_SHAPE_CIRCLE" color="#79ef64"  :size="mapPointSizeType" @click="collectionPoleClick" ></bm-point-collection>
          <bm-point-collection :points="stationData" shape="BMAP_POINT_SHAPE_STAR" color="#1b5fa8" :size="mapPointSizeTypeBig" @click="collectionStationClick"></bm-point-collection>
          <bm-point-collection :points="luxData" shape="BMAP_POINT_SHAPE_WATERDROP" color="#02ba6a" :size="mapPointSizeType"></bm-point-collection> -->
          <template v-for="(item, index) in poleData">
            <MenuCollection @collectionClick="collectionClick" :zoom='center.zoom' :mapItemList='item.arr' :typeData='item' :key="'pole-coll'+index" v-if="item.arr.length>0">
            </MenuCollection>
          </template>
          <template v-for="(item, index) in stationData">
            <MenuCollection @collectionClick="collectionClick" :zoom='center.zoom' :mapItemList='item.arr' :typeData='item' :key="'box-coll'+index" v-if="item.arr.length>0">
            </MenuCollection>
          </template>
          <template v-for="(item, index) in luxData">
            <MenuCollection @collectionClick="collectionClick" :zoom='center.zoom' :mapItemList='item.arr' :typeData='item' :key="'lux-coll'+index" v-if="item.arr.length>0">
            </MenuCollection>
          </template>
        </template>
      </template>
      <bm-marker v-if="enableMoveDevice && moveDevicePos.lat != 0 && moveDevicePos.lng != 0" :dragging="false" :position="{ lng: moveDevicePos.lng, lat: moveDevicePos.lat }">
        <bm-label :content="showLang('gis.set.target')" :offset="{ width: -60, height: 35 }" :labelStyle="labelStyle" />
      </bm-marker>
    </baidu-map>
    <ModalSetDevicePos v-model="moveDevicePos.show" :pos="moveDevicePos" @posSaved="posSaved" />
    <ModalPowerInfo v-model="showPowerInfoModal" :item="editItem" />
    <ModalPoleInfo v-model="showPoleInfoModal" :item="editItem" />
    <ModalLuxInfo v-model="showLuxInfoModal" :item="editItem" />
    <ModalLineEdit v-model="showAddLineModal" :item="editItem" :stations="selectedDevices" @saved="lineAdded" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { BaiduMap, BmLabel, BmNavigation, BmMarker } from '@/components/vue-baidu-map-v3/components'
import ModalSetDevicePos from './ModalSetDevicePos'
import MenuMapType from './MenuMapType'
import MenuDeviceType from './MenuDeviceType'
import MenuMapTool from './MenuMapTool'
import MenuMapTotal from './MenuMapTotal'
// import MapGroupTotal from './MapGroupTotal'
import MapLineItem from './MapLineItem'
import MapLuxItem from './MapLuxItem'
import MapPoleItem from './MapPoleItem'
import MapPowerItem from './MapPowerItem'
import ModalPowerInfo from './ModalPowerInfo'
import ModalPoleInfo from './ModalPoleInfo'
import ModalLuxInfo from './ModalLuxInfo'
import MenuCollection from './MenuCollection'
import ModalLineEdit from '@/components/config/powerbox/line/ModalLineEdit'
export default {
  name: 'MapDevice',
  components: {
    MenuMapType,
    MenuDeviceType,
    MenuMapTool,
    MenuMapTotal,
    // MapGroupTotal,
    MenuCollection,
    MapLineItem,
    MapLuxItem,
    MapPoleItem,
    MapPowerItem,
    ModalPowerInfo,
    ModalPoleInfo,
    ModalLuxInfo,
    ModalLineEdit,

    BaiduMap,
    BmMarker,
    BmLabel,
    BmNavigation,
    ModalSetDevicePos,
    // BmPointCollection
  },
  props: {
    singleId: {
      type: Number,
      default: 0,
    },
    gainDeviceType: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      labelStyle: {
        width: '150px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        color: 'red',
        border: 0,
        fontSize: '12px'
      },
      levelSet: {
        //zoom等级设置
        min: 1, //小于此等级时，显示分组信息
        max: 22 //小于此等级时，显示光控、配电柜及线缆，大于此等级时增加单灯显示
      },
      center: { lng: 113.828333, lat: 22.770866, zoom: 17 },
      mapMenuItem: {
        type: 'BMAP_NORMAL_MAP',
        offset: {
          width: 10,
          height: 10
        }
      },
      mapDeviceType: {
        type: 'all',
        offset: {
          width: 10,
          height: 45
        }
      },
      mapTools: {
        setLine: false,
        setLux: false,
        offset: {
          width: 100,
          height: 10
        },
        pos: {
          zoom: 17,
          lat: 0,
          lng: 0
        }
      },
      mapTotals: {
        offset: {
          width: 20,
          height: 20
        },
        light: {
          total: 0,
          running: 0,
          alarm: 0
        },
        line: {
          total: 0
        },
        lux: {
          total: 0
        },
        power: {
          total: 0,
          online: 0,
          alarm: 0,
          repair: 0
        }
      },
      showItemGroup: true,
      stationData: [],
      poleData: [],
      luxData: [],
      mapGroupInfo: {
        lat: 0,
        lng: 0,
        pole: {
          total: 0,
          online: 0,
          running: 0,
          alarm: 0
        },
        light: {
          total: 0,
          online: 0,
          running: 0,
          alarm: 0
        },
        line: {
          total: 0
        },
        lux: {
          total: 0
        },
        power: {
          total: 0,
          online: 0,
          alarm: 0,
          repair: 0
        }
      },
      mapItemList: [],
      enableMoveDevice: false,
      editLineId: 0,
      enableEditLine: false,
      editLuxId: 0,
      enableEditLux: false,
      editItem: {},
      showPowerInfoModal: false,
      showPoleInfoModal: false,
      showLuxInfoModal: false,
      showAddLineModal: false,
      mapPointSizeType: 'BMAP_POINT_SIZE_SMALL',
      mapPointSizeTypeBig: 'BMAP_POINT_SIZE_SMALL',
      ak: 'MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88',
      map: null,
      BMap: null,
      mapStyle: {
        styleJson: require('@/assets/custom_map_config.json')
      },
      moveDevicePos: {
        show: false,
        lat: 0,
        lng: 0,
        groupId: 0,
        stationId: 0
      },
      isBatchSetPos: false,

      pointBatches: [], // 存放分批后的点数据
      loadedBatches: 0, // 已加载的批次数量
      timeoutId: null,
      batchSize: 4000,
    }
  },
  computed: {
    ...mapState('auth', ['user', 'config', 'agentChangeValue']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode'])
  },
  watch: {
    singleId() {
      if (this.singleId != 0) {
        this.pointToStation(this.selectedNode.id)
      }
    },
    selectedNode() {
      this.queryRoadTotal()
      let isLeaf = this.selectedNode.type == this.productCodes.station
      this.mapTools.setLine = isLeaf
      this.mapTools.setLux = !isLeaf
      if (!isLeaf) {
        this.getMapItems()
      } else {
        this.center.zoom = 18
        this.pointToStation(this.selectedNode.id)
      }
    }
  },
  mounted: function () {
    this.queryRoadTotal()
    let isLeaf = this.selectedNode.type == this.productCodes.station
    this.mapTools.setLine = isLeaf
    this.mapTools.setLux = !isLeaf
    if (!isLeaf) {
      this.getMapItems()
    } else {
      this.center.zoom = 18
      this.pointToStation(this.selectedNode.id)
    }
  },
  methods: {
    collectionClick(data) {
      let lines = this.mapItemList.filter(p => p.id == data.item.id)
      if (lines.length == 0) {
        return false;
      }
      this.editItem = lines[0]
      if (this.editItem.type == 14) {
        this.showPoleInfoModal = true
      } else {
        this.showPowerInfoModal = true
      }
    },
    mapReady: function (params) {
      this.map = params.map
      this.BMap = params.BMap
      this.map.setMapStyleV2({
        styleJson: this.mapStyle.styleJson
      })
      this.$set(this, 'center', {
        lat: this.config.lat,
        lng: this.config.lng,
        zoom: 14
      })
    },
    luxItemClick: function (params) {
      if (this.enableEditLux) {
        this.editLuxId = params.id
      } else {
        this.editItem = params
        this.showLuxInfoModal = true
      }
    },
    lineItemClick: function (params) {
      if (this.enableEditLine) {
        this.editLineId = params.id
      }
    },
    poleItemClick: function (params) {
      this.editItem = params
      this.showPoleInfoModal = true
    },
    powerItemClick: function (params) {
      this.editItem = params
      this.showPowerInfoModal = true
    },
    mapMouseMove: function (params) {
      this.mapTools.pos.lat = params.point.lat
      this.mapTools.pos.lng = params.point.lng
    },
    devictTypeChanged: function (params) {
      this.mapDeviceType.type = params.type
      // this.mapTools.setLine = params.type == 'cable';
      this.mapTools.setLux = params.type == 'lux'
      if (this.selectedNode.type == this.productCodes.station) {
        this.pointToStation(this.selectedNode.id)
      } else {
        this.getMapItems()
      }
    },
    mapTypeChanged: function (params) {
      this.mapMenuItem.type = params.type
      // this.mapType = params.type;
    },
    toolClicked: function (params) {
      this.isBatchSetPos = false;
      if (params.isBatch) {
        this.isBatchSetPos = params.isBatch
      }
      switch (params.method) {
        case 'setLux':
          this.enableEditLux = true
          break
        case 'fixLux':
          this.enableEditLux = false
          break
        case 'saveLux':
          this.saveLux()
          break
        case 'setLine':
          this.enableEditLine = true
          break
        case 'fixLine':
          this.enableEditLine = false
          break
        case 'addLine':
          this.addLine()
          break
        case 'saveLine':
          this.saveLine()
          break
        case 'delLine':
          this.delLine()
          break
        case 'setPos':
          this.enableMoveDevice = true
          break
        case 'fixPos':
          this.enableMoveDevice = false
          break
      }
      this.editLineId = 0
    },
    mapZoomChanged(e) {
      if (!this.map) return
      if (!this.selectedNode.type) return
      this.center.zoom = e.target.getZoom()
      // this.mapTools.setLine = this.center.zoom >=  this.levelSet.min;
      this.mapTools.pos.zoom = this.center.zoom
      if (this.selectedNode.type != this.productCodes.station) {
        this.getMapItems()
        if (this.center.zoom > 0 && this.center.zoom < 10) {
          this.mapPointSizeType = 'BMAP_POINT_SIZE_TINY'
          this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_SMALL'
        } else if (this.center.zoom > 9 && this.center.zoom < 13) {
          this.mapPointSizeType = 'BMAP_POINT_SIZE_SMALLER'
          this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_NORMAL'
        } else if (this.center.zoom > 12 && this.center.zoom < 15) {
          this.mapPointSizeType = 'BMAP_POINT_SIZE_SMALL'
          this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_BIG'
        } else {
          this.mapPointSizeType = 'BMAP_POINT_SIZE_NORMAL'
          this.mapPointSizeTypeBig = 'BMAP_POINT_SIZE_BIGGER'
        }
      }
    },
    dragend() {
      if (!this.map) return
      if (this.selectedNode.type != this.productCodes.station) {
        this.getMapItems()
      }
    },
    pointToStation: function (stationId) {
      let type = -1
      switch (this.mapDeviceType.type) {
        case 'powerbox':
          type = 0
          break
        case 'pole':
          type = 14
          break
        case 'lux':
          type = 299
          break
        default:
          type = -1
          break
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryGisItemByStationId`, { stationId, type: type }).then(res => {
        if (res.code == 0) {
          let stationData = [],
            poleData = [],
            luxData = []
          res.data.list.forEach(el => {
            if (el.type == 0) {
              stationData.push({ lng: el.lng, lat: el.lat, id: el.id })
            } else if (el.type == 14) {
              poleData.push({ lng: el.lng, lat: el.lat, id: el.id })
            } else if (el.type == 299) {
              luxData.push({ lng: el.lng, lat: el.lat, id: el.id })
            }
            // this.pointsData.push({ lng: el.lng, lat: el.lat })
          })
          // this.$set(this, 'stationData', stationData)
          // this.$set(this, 'poleData', poleData)
          // this.$set(this, 'luxData', luxData)

          this.stationData = this.collectionHandleData(stationData, 'box')
          this.poleData = this.collectionHandleData(poleData, 'pole')
          this.luxData = this.collectionHandleData(luxData, 'lux');
          this.showItemGroup = false;
          this.$set(this, 'mapItemList', res.data.list)
          this.$set(this, 'mapGroupInfo', {})
          this.center.lat = res.data.lat
          this.center.lng = res.data.lng
        }
      })
    },
    collectionHandleData(item, type) {
      let arr = [
        {
          color: 0,//红色0 绿色1 灰色2 黄色3
          type: type,
          arr: [],
        }, {
          color: 1,
          type: type,
          arr: [],
        }, {
          color: 2,
          type: type,
          arr: [],
        }, {
          color: 3,
          type: type,
          arr: [],
        }
      ]
      if (type == 'lux') {
        item.forEach(el => {
          if (!el.online) {
            arr[2].arr.push(el)
          } else if (el.alarm) {
            arr[0].arr.push(el)
          } else {
            arr[1].arr.push(el)
          }
        })
        return arr;
      }
      item.forEach(el => {
        if ((el.pcnt == 0 && type == 'pole') || el.status != 1 && type == 'box') {
          arr[2].arr.push(el)
        } else if (!el.online) {
          arr[2].arr.push(el)
        } else {
          if (el.alarm) {
            if (el.running) {
              arr[0].arr.push(el)
            } else {
              arr[3].arr.push(el)
            }
          } else {
            if (el.running) {
              arr[0].arr.push(el)
            } else {
              arr[2].arr.push(el)
            }
          }
        }
      })
      return arr;
    },
    getMapItems() {
      if (this.map == null) return
      let bounds = this.map.getBounds()
      let viewMapData = {
        latmin: bounds.getSouthWest().lat,
        latmax: bounds.getNorthEast().lat,
        lngmin: bounds.getSouthWest().lng,
        lngmax: bounds.getNorthEast().lng,
        groupId: 0,
        stationId: 0,
        type: -1
      }
      if (this.selectedNode.type == this.productCodes.station) {
        viewMapData.stationId = this.selectedNode.id
      } else {
        viewMapData.groupId = this.selectedNode.id
      }
      switch (this.mapDeviceType.type) {
        case 'powerbox':
          viewMapData.type = 0
          break
        case 'pole':
          viewMapData.type = 14
          break
        case 'lux':
          viewMapData.type = 299
          break
        default:
          viewMapData.type = -1
          break
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryGisItemNew`, viewMapData).then(res => {
        if (res.code == 0) {
          let minLat = 90, minLng = 180, maxLat = -90, maxLng = -180;
          res.data.forEach(el => {
            if (el.lat > 0 && el.lng > 0) {
              if (el.lat < minLat) minLat = el.lat;
              if (el.lat > maxLat) maxLat = el.lat;
              if (el.lng < minLng) minLng = el.lng;
              if (el.lng > maxLng) maxLng = el.lng;
            }
          })
          this.center.lat = (minLat + maxLat) / 2;
          this.center.lng = (minLng + maxLng) / 2;
          if (this.center.zoom < 18) {
            let stationData = [],
              poleData = [],
              luxData = []
            res.data.forEach(el => {
              if (el.type == 0) {
                stationData.push({ lng: el.lng, lat: el.lat, id: el.id })
              } else if (el.type == 14) {
                poleData.push({ lng: el.lng, lat: el.lat, id: el.id })
              } else if (el.type == 299) {
                luxData.push({ lng: el.lng, lat: el.lat, id: el.id })
              }
            })
            this.stationData = this.collectionHandleData(stationData, 'box')
            this.poleData = this.collectionHandleData(poleData, 'pole')
            this.luxData = this.collectionHandleData(luxData, 'lux')
          } else {
            this.$set(this, 'showItemGroup', res.data.type == 'group')
            if (this.showItemGroup) {
              this.$set(this, 'mapGroupInfo', res.data)
              this.$set(this, 'mapItemList', [])
            } else {
              this.$set(this, 'mapItemList', res.data)
              this.$set(this, 'mapGroupInfo', {})
            }
          }
        }
      })
    },
    queryRoadTotal() {
      let groupId = 0,
        stationId = 0
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id
      } else {
        groupId = this.selectedNode.id
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryRoadTotal`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this.mapTotals, 'light', res.data.light)
          this.$set(this.mapTotals, 'line', res.data.line)
          this.$set(this.mapTotals, 'lux', res.data.lux)
          this.$set(this.mapTotals, 'power', res.data.power)
        }
      })
    },
    batchSetPosAjax(params, type) {
      this.$axios.post(`//${this.domains.trans}/station/gis/BatchSetPos`, {
        type: type,
        id: params.id,
        lat: params.lat,
        lng: params.lng
      }).then(res => {
        if (res.code == 0) {
          this.$Message.info('设备位置设置成功')
          this.getMapItems()
        }
      })
    },
    powerPosChanged: function (params) {
      if (this.isBatchSetPos) {
        this.batchSetPosAjax(params, 0)
        return false
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/SetPos`, {
        type: 0,
        id: params.id,
        lat: params.lat,
        lng: params.lng
      }).then(res => {
        if (res.code == 0) {
          this.$Message.info('设备位置设置成功')
          let lines = this.mapItemList.filter(p => p.type == 10)
          for (let l of lines) {
            if (l.path.list.length > 0) {
              l.path.list[0].lat = params.lat
              l.path.list[0].lng = params.lng
            }
          }
        }
      })
    },
    polePosChanged: function (params) {
      if (this.isBatchSetPos) {
        this.batchSetPosAjax(params, -1)
        return false
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/SetPos`, {
        type: 14,
        id: params.id,
        lat: params.lat,
        lng: params.lng
      }).then(res => {
        if (res.code == 0) {
          this.$Message.info('设备位置设置成功')
        }
      })
    },
    luxPosChanged: function (params) {
      this.$axios.post(`//${this.domains.trans}/station/gis/SetPos`, {
        type: 299,
        id: params.id,
        lat: params.lat,
        lng: params.lng
      }).then(res => {
        if (res.code == 0) {
          this.$Message.info('设备位置设置成功')
          let items = this.mapItemList.filter(p => p.id == params.id && p.type == 299)
          if (items.length == 0) return
          items[0].lat = params.lat
          items[0].lng = params.lng
        }
      })
    },
    saveLux: function () {
      if (this.editLuxId <= 0) return
      let lines = this.mapItemList.filter(p => p.id == this.editLuxId)
      if (lines.length == 0) return
      let item = lines[0]
      this.$axios
        .post(`//${this.domains.trans}/station/gis/SetLuxRadius`, {
          id: item.id,
          radius: item.path.radius
        })
        .then(res => {
          if (res.code == 0) {
            this.$Message.info('光控半径设置成功')
            this.editLuxId = 0
          }
        })
    },
    addLine() {
      this.editItem = { isAdd: true, data: {} }
      this.showAddLineModal = true
    },
    lineAdded: function (params) {
      this.pointToStation(this.selectedNode.id)
      this.editLineId = params.data
    },
    delLine() {
      if (this.editLineId <= 0) return
      let items = this.mapItemList.filter(p => p.id == this.editLineId)
      if (items.length == 0) return
      let item = items[0]
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', item.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/config/DeleteLine`, { list: [item.id] }).then(res => {
            if (res.code == 0) {
              this.$Message.info(this.showLang('com.tips.del.success'))
              this.editLineId = 0
              this.pointToStation(this.selectedNode.id)
            }
          })
        }
      })
    },
    saveLine() {
      if (this.editLineId <= 0) return
      let lines = this.mapItemList.filter(p => p.id == this.editLineId)
      if (lines.length == 0) return
      let item = lines[0]
      this.$axios.post(`//${this.domains.trans}/station/gis/SaveLine`, { id: item.id, path: item.path.list }).then(res => {
        if (res.code != 0) return
        this.$Message.info(this.showLang('com.tips.save'))
        this.editLineId = 0
        this.pointToStation(this.selectedNode.id)
      })
    },
    mapClick: function (e) {
      if (this.enableEditLine && this.editLineId > 0) this.editLineId = 0
      if (this.enableEditLux && this.editLuxId > 0) this.editLuxId = 0
      if (this.enableMoveDevice) {
        this.moveDevicePos.lat = e.point.lat
        this.moveDevicePos.lng = e.point.lng
        this.moveDevicePos.show = true 
      }
    },
    posSaved: function (params) {
      if (params.success) {
        if (this.selectedNode.type == this.productCodes.station) {
          this.pointToStation(this.selectedNode.id)
        } else {
          this.getMapItems()
        }
      }
      this.moveDevicePos.lat = 0
      this.moveDevicePos.lng = 0
      this.moveDevicePos.show = false
    },
    getCurrentPoints() {
      let points = [];
      for (let i = 0; i < this.loadedBatches; i++) {
        points = points.concat(this.pointBatches[i]);
      }
      return points;
    },
    loadPointsInBatches() {
      this.pointBatches=[];
      // 批次划分数据
      for (let i = 0; i < Math.ceil(this.mapItemList.length / this.batchSize); i++) {
        const start = i * this.batchSize;
        const end = Math.min(start + this.batchSize, this.mapItemList.length);
        this.pointBatches.push(this.mapItemList.slice(start, end));
      }    
      this.loadNextBatch();
    },
    loadNextBatch() {
      if (this.loadedBatches < this.pointBatches.length) {
        this.timeoutId = setTimeout(() => {
          this.loadedBatches++;
          this.loadNextBatch(); // 递归调用以加载下一批
        }, 200); 
      }
    },
  }
}
</script>
<style scoped>
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
}
.map {
  width: 100%;
  height: 100%;
  margin: auto;
}
</style>
