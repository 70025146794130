<template>
  <div class="mapTotals-stat">
    <div class="mapTotals-title"><i></i>设备状态</div>
    <div class="mapTotals-top"><span>{{showLang('com.data.ggd.total')}}:</span><i>{{mapTotals.power.total}}</i></div>
    <div class="mapTotals-type">
      <div class="mapTotals-son">
        <img :src="img.mapPowerOnline" alt="">
        <span class="mapTotals-name">{{showLang('com.state.onLine')}}:</span>
        <span class="mapTotals-num">{{mapTotals.power.online}}</span>
      </div>
      <div class="mapTotals-son">
        <img :src="img.mapPowerAlarm" alt="">
        <span class="mapTotals-name">{{showLang('com.state.energize')}}:</span>
        <span class="mapTotals-num">{{mapTotals.power.running}}</span>
      </div>
      <div class="mapTotals-son">
        <img :src="img.mapPowerError" alt="">
        <span class="mapTotals-name">{{showLang('com.right.nav.alarm')}}:</span>
        <span class="mapTotals-num">{{mapTotals.power.alarm}}</span>
      </div>
    </div>
    <div class="mapTotals-type">
      <div class="mapTotals-son">
        <img :src="img.mapPowerOffline" alt="">
        <span class="mapTotals-name">{{showLang('com.state.offline')}}:</span>
        <span class="mapTotals-num">{{mapTotals.power.offline}}</span>
      </div>
      <div class="mapTotals-son">
        <img :src="img.mapPowerRepair" alt="">
        <span class="mapTotals-name">{{showLang('com.state.service')}}:</span>
        <span class="mapTotals-num">{{mapTotals.power.repair}}</span>
      </div>
      <div class="mapTotals-son">
        <img :src="img.powerbox.status.stop" alt="">
        <span class="mapTotals-name">{{showLang('com.state.deactivate')}}:</span>
        <span class="mapTotals-num">{{mapTotals.power.stop}}</span>
      </div>
    </div>
    <div class="mapTotals-line"></div>
    <div class="mapTotals-top"><span>{{showLang('com.lamp.total')}}:</span><i>{{mapTotals.light.total}}</i></div>
    <div class="mapTotals-type">
      <div class="mapTotals-son">
        <img :src="img.gis.pole.out2" alt="" class="mapTotals-light">
        <span class="mapTotals-name">{{showLang('com.state.onLine')}}:</span>
        <span class="mapTotals-num">{{mapTotals.light.online}}</span>
      </div>
      <div class="mapTotals-son">
        <img :src="img.gis.pole.lighting2" alt="" class="mapTotals-light">
        <span class="mapTotals-name">{{showLang('com.but.open.lamp')}}:</span>
        <span class="mapTotals-num">{{mapTotals.light.running}}</span>
      </div>
      <div class="mapTotals-son">
        <img :src="img.gis.pole.outP3" alt="" class="mapTotals-light">
        <span class="mapTotals-name">{{showLang('com.state.police')}}:</span>
        <span class="mapTotals-num">{{mapTotals.light.alarm}}</span>
      </div>
    </div>
    <div class="mapTotals-type">
      <div class="mapTotals-son">
        <img :src="img.gis.pole.outOff2" alt="" class="mapTotals-light">
        <span class="mapTotals-name">{{showLang('com.state.offline')}}:</span>
        <span class="mapTotals-num">{{mapTotals.light.offline}}</span>
      </div>
    </div>
    <!-- <div class="mapTotals-type">
        <div class="mapTotals-top"><span><i class="mapTotals-drop"></i>{{showLang('com.device.lightControl')}}</span><i>{{mapTotals.lux.total}}</i>
        </div>
      </div>
      <div class="mapTotals-type">
        <div class="mapTotals-top"><span><i class="mapTotals-drop"></i>{{showLang('com.device.cable')}}</span><i>{{mapTotals.line.total}}</i></div>
      </div> -->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import {
//   BmControl,
// } from '@/components/vue-baidu-map-v3/components'
export default {
  components: {
    // BmControl
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mapTotals: {
        light: {
          total: 0,
          running: 0,
          alarm: 0
        },
        line: {
          total: 0
        },
        lux: {
          total: 0
        },
        power: {
          total: 0,
          online: 0,
          alarm: 0,
          repair: 0
        }
      },
      offset: {
        width: 20,
        height: 30
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes']),
    ...mapState('group', ['groups', 'selectedDevices', 'groupTreeSelectedNode']),
  },
  watch: {
    // position: {
    //   handler() {
    //     this.$refs[`customOverlay${this.sample.name}`].reload()  // 当位置发生变化时，重新渲染，内部会调用draw
    //   },
    //   deep: true
    // }
  },
  mounted() {
    this.queryRoadTotal()
  },
  methods: {
    queryRoadTotal() {
      let groupId = 0,
        stationId = 0
      if (this.groupTreeSelectedNode.type == this.productCodes.station) {
        stationId = this.groupTreeSelectedNode.id
      } else {
        groupId = this.groupTreeSelectedNode.id
      }
      this.$axios.post(`//${this.domains.trans}/station/gis/QueryRoadTotal`, { groupId, stationId }).then(res => {
        if (res.code == 0) {
          this.$set(this.mapTotals, 'light', res.data.light)
          this.$set(this.mapTotals, 'line', res.data.line)
          this.$set(this.mapTotals, 'lux', res.data.lux)
          this.$set(this.mapTotals, 'power', res.data.power)
        }
      })
    },
  }
}
</script>

<style>
.mapTotals-stat {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 6px;
  padding: 24px 24px 24px 40px;
}
.mapTotals-title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  position: relative;
  height: 30px;
  margin-bottom: 10px;
}
.mapTotals-title i {
  position: absolute;
  width: 3px;
  height: 17px;
  background: #3379fb;
  left: -15px;
  top: 8px;
}
.mapTotals-type {
  display: flex;
  justify-content: space-between;
  margin: 18px 0;
}
.mapTotals-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #787f92;
  margin: 0 10px 0;
}
.mapTotals-num {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.mapTotals-son img {
  width: 20px;
  height: 24px;
}
.mapTotals-light {
  width: 24px;
  height: 26px;
}
.mapTotals-son {
  display: flex;
  align-items: center;
}
.mapTotals-top span {
  height: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  margin-right: 15px;
}
.mapTotals-top i {
  height: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  font-style: normal;
}
.mapTotals-line {
  width: 100%;
  height: 1px;
  background-color: #c6cdd8;
  margin: 15px 0;
}
</style>