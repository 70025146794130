<template>
  <div class="gisPage">
    <div class="gis-map">
      <mapPage />
    </div>
    <div class="gis-stat">
      <totalPage />
      <div class='gisMask2' :style="{backgroundImage: `url(${img.customized.ver1.gisMask2})`}">
      </div>
    </div>
    <div class="switch-items"></div>
  </div>
</template>
<script>

import mapPage from './map'
import totalPage from './total'
export default {
  name: 'gisPage',
  components: {
    mapPage,
    totalPage,
  },
  props: {
  },
  data() {
    return {

    }
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>
<style scoped>
.gisPage {
  width: 100%;
  height: 100%;
  position: relative;
}
.gis-stat {
  width: calc(100% - 40px);
  position: absolute;
  left: 20px;
  z-index: 20;
  bottom: -10px;
}
.gisMask2{
  width: calc(100% + 40px );
  height: 146px;
  position: absolute;
  bottom: -40px;
  left: -20px;
  z-index: 10;
  background-size: 100% 100%;
}
.gis-map {
  width: calc(100%);
  height: 100%;
}
.switch-items {
  position: absolute;
  right: 30px;
  top: 25px;
}
</style>
