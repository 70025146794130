<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <Button type="primary" size="default" style="margin-right: 5px" @click="groupControl('handSingle','调光')">调光</Button>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="areas" height="auto" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="checkbox" width="43"></vxe-column>
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="name" title="名称" width="250" sortable header-align="center">
        </vxe-column>
        <vxe-column field="code" title="组号" width="80" sortable header-align="center">
        </vxe-column>
        <vxe-column field="count" title="单灯总数" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="online" title="在线数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="running" title="亮灯数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="alarm" title="报警数量" width="100" sortable header-align="center">
        </vxe-column>
        <vxe-column field="power" title="总额定功率(W)" width="150" sortable header-align="center">
        </vxe-column>
        <vxe-column field="message" title="执行情况" sortable header-align="center">
        </vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from '@/components/control/light/args/ModalArgEdit'
export default {
  name: 'GroupLampControl',
  components: {
    ModalArgEdit
  },
  props: {
  },
  data() {
    return {
      timer: null,
      dataRefresh: 0,
      showArgModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      areas: [],
      data: [
        { name: '测试1', num1: 500, num2: 500, num3: 300, num4: 200 },
        { name: '测试2', num1: 500, num2: 500, num3: 300, num4: 200 },
        { name: '测试3', num1: 500, num2: 500, num3: 300, num4: 200 },
        { name: '测试4', num1: 500, num2: 500, num3: 300, num4: 200 },
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['deviceTypes', 'productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    // selectedNode() {
    //   this.initList();
    // },

  },
  mounted: function () {
    // this.initList();
    this.queryAreas();
    // this.timer = setInterval(this.setCmdResult, 500);
    // window.eventBus.$on('paramCommandComing', params => {
    //   // this.cmdResults[params.cmdId] = params;
    //   params.time = new Date().getTime();
    //   this.cmdResults[params.commandId] = params;
    // })
  },
  destroyed: function () {
    // window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    queryAreas: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryLightGroupMonitor`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'areas', res.data);
        }
      });
    },
    argsSaved(params) {
      this.showArgModal = false;
      let groupList = this.$refs.chkTable.getCheckboxRecords();
      let ids = groupList.map(p => p.id);
      let args = {
        code: 'handGroup',
        list: ids,
        args: params.args
      };
      console.log(params, groupList, args)
      this.$axios.post(`//${this.domains.trans}/station/command/SendLightGroupMonitor`, args).then(res => {
        if (res.code == 0) {
          for(let item of res.data.list){
            let ds = this.areas.filter(p => p.id == item.id);
            if(ds.length == 0)continue;
            this.$set(ds[0], 'message', item.message);
          }
        }
      });
    },
    groupControl() {
      let groupList = this.$refs.chkTable.getCheckboxRecords();
      if (groupList.length == 0) {
        this.$Message.warning('至少一个分组进行操作');
        return;
      }
      this.cmdItem = { paramsCode: 'handSingle', code: 'handSingle', name: '调光', pswd: true, args: {} };
      this.showArgModal = true;
    },
    calcSuccessRate() {

    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.devices.filter(p => p.cmdId == cmdId);
        if (ds.length == 0) {
          if (new Date().getTime() - this.cmdResults[cmdId].time > 100000) {
            console.log('cmd id time out');
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
  padding-bottom: 10px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
</style>