<template>
  <Modal v-model="showModal" :mask-closable="false" width="1025px">
    <div slot="header">新手引导介绍</div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
    <div class="station-alarm-tabs">
      <div v-for="(item, idx) in nav" :key="idx" :class="['station-alarm-tab', curTab == item.code ? 'tab-active' : '']"
        @click="switchTab(item.code)">{{item.name}}</div>
    </div>
    <template v-if="curTab=='s1'">
      <div class="user-container">
        <template v-if="this.appType=='road'">
          <img :src="img.novice.treeIcon2" alt="" style="width: 728px;">
          <img :src="img.novice.treeIcon3" alt="" style="width: 728px;">
        </template>
        <template v-if="this.appType=='pole'">
          <img :src="img.novice.lightAllIcon" alt="" style="width: 781px;">
        </template>
      </div>
    </template>
    <template v-if="curTab=='s2'">
      <div class="instruct-box">
        <p class="text-title">一、查询指令</p>
        <p class="text-con"><span
            class="red">1、智能控制终端：</span>召测、读取时钟、读取时间表、设备信息、卡信息、查询工作网络查询电压报警上下限、查询串口参数、查询工作网络、查询周计划、查询时段、查询倍率、查询电流报警上下限。
        </p>
        <p class="text-con"><span class="red">2、集中器：</span>召测、读取时钟、查询白名单、查询指令数量、清除指令队列、设备信息、卡信息、查询LORA工作参数、查SNID、查询工作网络。
        </p>
        <p class="text-con"><span
            class="red">3、单灯控制器：</span>召测、查询时钟、查询分组、查询计时日表、查询准时日表、查询日表模式、查询光感开灯值、联动亮度、感应参数、报警阈值、报警状态、倾斜阈值、设备信息、卡信息、查询工作网络
        </p>
        <p class="text-title">二、配置指令</p>
        <p class="text-con"><span class="red">1、智能控制终端：</span>校准时钟、下发时间表、设置电压报警上下限、设置串口参数、设置周计划、设置时段、设置倍率、设置电流报警上下限。</p>
        <p class="text-con"><span class="red">2、集中器：</span>校准时钟、设置白名单、设置LORA工作参数。</p>
        <p class="text-con"><span class="red">3、单灯控制器：</span>校准时钟、设置计时日表、设置准时日表、设置分组、设置日表类型、设置光感开灯值。</p>
        <p class="text-title">三、操作指令</p>
        <p class="text-con"><span class="red">1、智能控制终端：</span>开关控制（开灯、关灯）、开锁、重启设备</p>
        <p class="text-con"><span class="red">2、集中器：</span>重启设备</p>
        <p class="text-con"><span class="red">3、单灯控制器：</span>开灯、关灯、调光控制、组调、重启设备</p>
        <p class="text-title">四、内核指令</p>
        <p class="text-con"><span class="red">1、智能控制终端：</span>设置工作网络、初始化、在线升级（OTA）/FTP）</p>
        <p class="text-con"><span class="red">2、集中器：</span>设置工作网络、LORA组网、在线升级（OTA）</p>
        <p class="text-con"><span class="red">3、单灯控制器：</span>设置工作网络、初始化、在线升级（OTA）</p>
      </div>
    </template>
    <template v-if="curTab=='s3'">
      <iframe :src="pdfSrc" class='iframe-box'></iframe>
    </template>
  </Modal>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: 'ModalImageList',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      pdfSrc: process.env.BASE_URL + 'manual.pdf',
      showModal: this.value,
      imgArr: [],
      curTab: 's1',
      nav: [
        { code: 's1', name: '图标介绍', opCodes: "", com: "", args: {} },
        { code: 's2', name: '指令介绍', opCodes: "", com: "", args: {} },
        // { code: 's3', name: '操作手册', opCodes: "", com: "", args: {} },
      ],
    };
  },
  computed: {
    ...mapState("auth", ["organs", "customers", "appType", "organTypes", "customerId"]),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  methods: {
    switchTab(code) {
      this.curTab = code;
    },
    cancel() {
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.user-container {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
}
.user-container img {
  margin: 0 auto;
}
ul {
  background: #000;
}
ul li {
  border: 1px solid #fff;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 5px;
}
ul li img {
  max-width: 98px;
  max-height: 98px;
}
.station-alarm-tabs {
  height: 48px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 2px 10px;
  cursor: pointer;
  background-color: #fff;
  color: #324252;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
}
.tab-active {
  color: #021629;
  font-weight: bold;
}
.tab-active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 2px solid #3880fc;
}
.instruct-box {
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  padding: 0 20px 0 20px;
}
.text-title {
  font-size: 16px;
  color: #021629;
  font-weight: bold;
  margin-top: 15px;
}
.text-con {
  text-indent: 20px;
  margin-top: 5px;
}
.text-con .red {
  color: #f00;
}
.user-container {
  width: 100%;
  height: 600px;
  overflow-y: scroll;
}
.iframe-box {
  width: 100%;
  height: 600px;
}
</style>

