<template>
  <div class="info-container">
    <div class="info-header">
      <div class="info-header-title">{{showLang('com.data.real.time')}}<span v-if="timeLen > 0">[{{showLang('com.lighting.lamp.duration')}}：{{timeName}}]</span></div>
    </div>
    <template v-if="stationTabName == 'power'">
      <TunnelData v-if='selectedNode.isTunnel' />
      <PowerRealData v-else />
    </template>
    <LightIndex ref="lightList" v-else />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import PowerRealData from '../config/PowerRealData'
import TunnelData from '../config/TunnelData'
import LightIndex from '../light/Index'
export default {
  name: 'BaseGroupInfo',
  components: {
    PowerRealData,
    TunnelData,
    LightIndex,
  },
  props: {
  },
  data() {
    return {
      isShowAnalysis: true,
      timeName: '',
      timeLen: 0,
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'deviceTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['stationTabName']),
    hasPowerBox: function () {
      return this.selectedNode.type == this.productCodes.station && this.selectedNode.hasPower;
    },
    hasLight: function () {
      return this.selectedNode.type == this.productCodes.station && this.selectedNode.hasLight;
    },
  },
  watch: {
    selectedNode() {
      console.log(this.selectedNode)
      this.getStationLightOnTime();
      if (this.hasPowerBox && !this.hasLight) {
        this.$store.commit('auth/switchStationTabName', 'power');
      } else if (!this.hasPowerBox && this.hasLight) {
        this.$store.commit('auth/switchStationTabName', 'light');
      }
    },
    cmdResultRefresh() {
    },
  },
  mounted: function () {
    this.getStationLightOnTime();
    if (this.hasPowerBox && !this.hasLight) {
      this.$store.commit('auth/switchStationTabName', 'power');
    } else if (!this.hasPowerBox && this.hasLight) {
      this.$store.commit('auth/switchStationTabName', 'light');
    }
  },
  destroyed: function () {
  },
  methods: {
    getStationLightOnTime: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/GetStationLightOnTime`, { stationId: this.selectedNode.id }).then(res => {
        if (res.code == 0) {
          this.timeLen = res.data.time;
          this.timeName = res.data.name;
        }
      })
    },
    getSelectedItems: function () {
      return this.$refs.lightList.getSelectedItems();
    },
    setCmdContent: function (id, cmd, content) {
      this.$refs.lightList.setCmdContent(id, cmd, content);
    },
    doCommand: function (params) {
      this.$refs.lightList.doCommand(params);
    }
  }
}
</script>
<style scoped>
.info-container {
  flex: none;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.info-header {
  border-bottom: solid 1px #dee5ed;
  width: 100%;
  height: 38px;
  flex: none;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.info-header-title {
  width: 300px;
  flex: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #021629;
}
</style>