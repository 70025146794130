<template>
<bm-control anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: offset.x, height: offset.y }">
  <div class="float-types-container" :style="{height: `${showall ? 100 : 35}px`}">
    <div class="item-status-filter">
      <RadioGroup v-model="typeSelected" class="">
        <Radio label="全部" class="radio-item"></Radio>
        <Radio v-for="(item, idx) in poleTypeNames" :key="idx" class="radio-item" :label="item.name"></Radio>
      </RadioGroup>
    </div>
    <div class="float-types-flag" @click="switchTypeFlag">
      <img :src="showall ? img.pole.gis.menu.down : img.pole.gis.menu.up" />
    </div>
  </div>
</bm-control>
</template>
<script>
import {mapState} from 'vuex'
import { BmControl } from "@/components/vue-baidu-map-v3/components";
export default {
  name: "ToolDeviceTypeSelect",
  components: {
    BmControl,
  },
  props: {
    offset: {
      type: Object,
      default(){return {x: 0, y: 0}}
    },
    types: {
      type: Array,
      default(){return []},
    },
  },
  data() {
    return {
      typeSelected: '全部',
      showall: false,
    };
  },
  computed: {
    ...mapState('common', ['poleTypeNames'])
  },
  watch: {
    typeSelected(val){
      let type = '-1';
      for(let item of this.poleTypeNames){
        if(item.name == val){
          type = item.type;
          break;
        }
      }
      this.$emit('typeChanged', type);
    }
  },
  mounted: function () {
  },
  methods: {
    switchTypeFlag: function(){
      this.showall = !this.showall;
    },
    getStatusImgType: function (code) {
      let imgs = this.img.pole.icons[this.currentMenuName];
      if (!imgs) return this.img.pole.icons.pole[code];
      return imgs[code];
    },
    switchFilter: function (item) {
      if(this.typeSelected == item.code){
        this.typeSelected = '';
      }else{
        this.typeSelected = item.code
      }
    },
  },
};
</script>
<style scoped>
.float-types-container{
  /* border: solid 1px red; */
  background-color: white;
  width: 465px;
  height: 35px;
  display: flex;
}
.item-status-filter {
  /* border: solid 1px red; */
  padding-top: 8px;
  padding-left: 20px;
  width: 325px;
  flex: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}
.float-types-flag{
  /* border: solid 1px blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  flex: none;
  cursor: pointer;
  user-select: none;
}
.float-types-flag img{
  width: 12px;
  height: 7px;
}
.radio-item{
  border-right: dashed 1px #DBDEE2;
  padding-right: 10px;
  margin-bottom: 10px;
}
.filter-item {
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
  cursor: pointer;
  user-select: none;
}
.filter-item img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.filter-active {
  color: #007eff;
}
</style>