<template>
  <div class="text-page" :style="{backgroundImage: `url(${img.customized.ver1.titleBg})`}">
    <img :src="img.customized.ver1.arrowAi" alt="" class="arrowAi">
    <span class="text-zh">{{option.zh}}</span>
    <i class="text-line">/</i>
    <span class="text-en">{{option.en}}</span>
  </div>
</template>
<script>

export default {
  name: 'textPage',
  components: {
  },
  props: {
    option: {
      type: Object,
      default: () => { },
    }
  },
  data() {
    return {
    }
  },
  mounted: function () {
  },
  methods: {

  }
}
</script>
<style scoped>
.textPage {
  width: 100%;
  height: 100%;
}
.text-page {
  background-repeat: no-repeat;
  background-position: right 0;
  position: relative;
}
.arrowAi {
  position: absolute;
  left: 0;
}
.text-zh {
  font-family: aliheiti;
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
  text-shadow: 2px 1px 2px #093358;
  font-style: italic;
  margin-left: 35px;
  letter-spacing: 2px;
}
.text-line {
  font-size: 22px;
  color: #89bcdb;
  /* box-shadow: 1px 1px 1px 0px rgba(9, 51, 88, 0.8); */
  margin: 0 10px;
}
.text-en {
  font-family: aliheiti;
  font-weight: bold;
  font-size: 16px;
  color: #89bcdb;
  text-shadow: 2px 1px 2px rgba(9, 51, 88, 0.5);
  font-style: italic;
  background: linear-gradient(
    to right,
    rgb(177, 218, 243),
    rgba(66, 103, 132, 0.7)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 1px;
}
</style>
