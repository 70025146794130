<template>
  <div class="alarm-setting-container">
    <div class="setting-header">
      <Button size="default" type="primary" class="mr10" @click="SaveRedirect">{{showLang('com.op.save')}}</Button>
      <Button size="default" type="success" class="mr10" @click="QueryRedirect">{{showLang('com.op.refresh')}}</Button>
    </div>
    <Form ref="form" :model="form" :label-width="200" class="alarm-base-area">
      <FormItem prop="redirectCode" label="跳转码：">
        <Input v-model="form.redirectCode" style="width:500px"></Input>
      </FormItem>
      <FormItem prop="redirectUrl" label="跳转URL：">
        <Input v-model="form.redirectUrl" style="width:500px"></Input>
      </FormItem>
      <!-- https://yonghengguang.amdm.top/api/center/third/callback/AlarmRedirect?source=chaozhou001 -->
    </Form>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'artificial',
  components: {
  },
  props: {
  },
  data() {
    return {
      form: {
        "redirectCode": "",
        "redirectUrl": ""
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  mounted: function () {
    this.QueryRedirect();

  },
  methods: {
    SaveRedirect() {
      if (this.form.redirectCode == '') {
        this.$Message.error('跳转码不能为空');
        return false;
      }
      if (this.form.redirectUrl == '') {
        this.$Message.error('跳转URL不能为空');
        return false;
      }
      this.$axios.post(`//${this.domains.trans}/station/alarm/SaveRedirect`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功')
        }
      });
    },
    QueryRedirect: function () {
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryRedirect`, {}).then(res => {
        if (res.code == 0) {
          this.form.redirectCode = res.data.redirectCode;
          this.form.redirectUrl = res.data.redirectUrl;
          if (res.data.redirectUrl == '') {
            this.form.redirectUrl = window.location.origin + '/alarm/upload1'
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-setting-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.setting-header {
  border: solid 1px rgba(82, 189, 216, 0.315);
  height: 50px;
  flex: none;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
}
</style>