<template>
  <div id="testMap" class="testMap" ref="mapContainer"></div>
</template>
<script>
export default {
  components: {
  },
  watch: {
  },
  data() {
    return {
      map: null,
      mapStyle: {
        styleJson: require('@/views/customized/public/customized_ver1.json'),
      },
      cityData: ['北京市', '上海市', '广东省', '兰州', '海口', '重庆', '西安']
    }
  },
  mounted() {
    this.addScript();
  },
  methods: {
    addScript() {
      // setTimeout(() => {
      //   let BMapGL = window.BMapGL;
      //   debugger;
      //   console.log(BMapGL)
      // }, 2000);
      let that = this;
      let script = document.createElement("script");
      script.src = 'http://api.map.baidu.com/api?v=3.0&type=webgl&ak=MhPU9AQpbKZ0kyQf7bWAv7BvwoKOax88';
      script.onload = function () {
        that.initMap();
      };
      document.body.appendChild(script);
    },
    initMap() {
      let this_ = this;
      let BMapGL = window.BMapGL;
      this.map = new BMapGL.Map(this.$refs.mapContainer);
      this.map.enableScrollWheelZoom(true);// 鼠标滚轮缩放
      let point = new BMapGL.Point(116.404, 39.915);
      this.map.centerAndZoom(point, 7);
      this.map.setMapStyleV2({
        styleJson: this.mapStyle.styleJson
      })
      this.cityData.forEach(el => {
        const count = new BMapGL.Boundary();
        count.get(el, function (rs) {
          if (rs.boundaries && rs.boundaries.length > 0) {
            let hole = new BMapGL.Polygon(rs.boundaries, {
              fillColor: 'rgba(79, 156, 242, 0.7)',
              strokeColor: '#4F9CF2',
              strokeWeight: 2,
              fillOpacity: 0.1
            });
            this_.map.addOverlay(hole);
            // this_.clearCover.push(hole)
          }
        });
      });
      // const points = [
      //   { lng: 116.404, lat: 39.915 }, // 海量点数据
      // ]
      const points = [];
      for (let index = 0; index < 15000; index++) {
        let lng = 113.82872234113212 + 0.00101 * index;
        let lat = 22.77053237542187 + 0.00101 * index;
        points.push({ lng: lng, lat: lat })
      }
      const mass = new BMapGL.PointCollection(points, { size: 10, shape: 'circle' });
      this.map.addOverlay(mass);
    },
    addMarkersInBatch() {
      let BMapGL = window.BMapGL;
      let markerBatch = [];
      let totalMarkers = 20000;
      let batchSize = 2000;
      let batches = Math.ceil(totalMarkers / batchSize);
      let currentBatch = 0;
      if (currentBatch < batches) {
        let end = (currentBatch + 1) * batchSize;
        let start = currentBatch * batchSize;
        for (let i = start; i < end; i++) {
          let point = new BMapGL.Point(20.331 + i * 0.01, 0.923 + i * 0.01);
          let marker = new BMapGL.Marker3D(point, 1, {
            enableDragging: true,
            size: 50,
            shape: 'BMAP_SHAPE_RECT',
            fillColor: '#454399',
            fillOpacity: 0.6
          });
          markerBatch.push(marker);
        }

        // 一次性将一批标记添加到地图上
        this.map.addOverlays(markerBatch);

        // 清空当前批次的标记数组
        markerBatch = [];

        // 递归调用添加下一批
        setTimeout(() => {
          currentBatch++;
          this.addMarkersInBatch.call(this);
        }, 10);
      }
    }
  }
}
</script>

<style scoped>
.testMap {
  width: 100%;
  height: 100%;
}
</style>