<template>
  <div class="virtualpage">
    <div class="auth-search mt5">
      项目名称：
      <Input v-model="formItem.name" placeholder="请输入名称" style="width: 250px;margin-right:10px"></Input>
      项目城市：
      <Input v-model="formItem.area" placeholder="请输入城市" style="width: 250px;margin-right:10px"></Input>
      <Button type="primary" @click="ajaxList(true)">搜索</Button>
    </div>
    <div class="auth-tab mt10" ref="xTable1">
      <vxe-table border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}" :key="tabHeight">
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="name" title="项目名称"></vxe-column>
        <vxe-column field="area" title="项目城市"></vxe-column>
        <vxe-column field="price" title="平均电价"></vxe-column>
        <vxe-column field="price1" title="安装情况">
          <template #default="params">
            <Button size="small" type="primary" style="margin-right: 5px" @click="QueryProjectInstall(params)">安装详情</Button>
          </template>
        </vxe-column>
        <vxe-column field="price2" title="能耗情况">
          <template #default="params">
            <Button size="small" type="primary" style="margin-right: 5px" @click="QueryProjectEnergy(params)">能耗详情</Button>
          </template>
        </vxe-column>
        <vxe-column width="160" fixed="right">
          <template #header>
            <Button size="small" type="primary" style="margin-right: 5px" @click="handleAdd">新增项目</Button>
          </template>
          <template #default="params">
            <Button size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">修改</Button>
            <Button size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <p>
        <vxe-pager background :current-page.sync="formItem.index" :page-size.sync="formItem.size" :total="totalResult" :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="formPage">
        </vxe-pager>
      </p>
    </div>
    <ModalvirtualEdit v-model="virtualShow" :item="editItem" @saved="ajaxList(true)" />
    <virtualInstall v-model="showInstall" :Id="projectId" :projectName="projectName" />
    <virtualEnergy v-model="showEnergy" :Id="projectId" :projectName="projectName" />
  </div>
</template>
<script>
import ModalvirtualEdit from './virtualEdit'
import virtualEnergy from './virtualEnergy'
import virtualInstall from './virtualInstall'
import { mapState } from 'vuex'
export default {
  name: 'virtual',
  components: {
    ModalvirtualEdit,
    virtualEnergy,
    virtualInstall
  },
  props: {

  },
  data() {
    return {
      list: [],
      tabHeight: 300,
      totalResult: 0,
      formItem: {
        "index": 1,
        "size": 15,
        "name": "",
        "area": "",
      },
      projectId: 0,
      projectName: '',
      virtualShow: false,
      showInstall: false,
      showEnergy: false,
      editItem: {
        isAdd: true,
        data: {},
      }
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.ajaxList(true);
  },
  methods: {
    setTableHeight: function () {
      this.tabHeight = this.$refs.xTable1.clientHeight - 50;
    },
    formPage({ currentPage, pageSize }) {
      this.formItem.index = currentPage;
      this.formItem.size = pageSize;
      this.ajaxList(false)
    },
    ajaxList(is) {
      if (is) { this.formItem.index = 1 }
      this.$axios.post(`visual/home/QueryVirtualProject`, this.formItem).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'totalResult', res.data.count);
        }
      });
    },
    handleAdd() {
      this.editItem.isAdd = true;
      this.editItem.data = {};
      this.virtualShow = true;
    },
    handleEdit(el) {
      this.editItem.isAdd = false;
      this.editItem.data = el.row;
      this.virtualShow = true;
    },
    handleDelete(params) {
      this.$Modal.confirm({
        title: '提示',
        content: `确定要删除${params.row.name}吗？`,
        onOk: async () => {
          this.$axios.post(`visual/home/DeleteVirtualProject`, { id: params.row.id }).then(res => {
            if (res.code == 0) {
              this.ajaxList();
            }
          });
        }
      });
    },
    QueryProjectInstall(el) {
      this.projectId = el.row.id;
      this.projectName = el.row.name;
      this.showInstall = true
    },
    QueryProjectEnergy(el) {
      this.projectId = el.row.id;
      this.projectName = el.row.name;
      this.showEnergy = true
    },
  }
}
</script>
<style scoped>
.virtualpage {
  width: 100%;
  height: 100%;
}
</style>
