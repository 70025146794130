<template>
  <div class="installPage">
    <titlePage :option='title'></titlePage>
    <div class="bar-box">
      <!-- <chartsBar :option="chartsBarData" id="installPage" :key="timer"></chartsBar> -->
      <div id="chart" :key="timer"></div>
    </div>
  </div>
</template>
<script>
import titlePage from '../../public/title'
import * as echarts from "echarts";
export default {
  name: 'installPage',
  components: {
    titlePage
  },
  props: {
  },
  data() {
    return {
      title: {
        zh: '月新安装设备数趋势', en: 'TREND OF INSTALLED EQUIPMENT'
      },
      timer: 0,
      xdata: [],
      option1: {
        legend: {
          show: false,
        },
        xAxis: {
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              color: 'rgba(34, 51, 83, 0.4)'
            }
          }
        },
        grid: {
          left: '10%',
          right: '0',
          top: '15%',
          bottom: '10%',
        },
        series: [
          {
            name: '随机数据',
            type: 'pictorialBar',
            symbolRepeat: false, // 不再需要设置symbol的形状，采用自定义形状
            label: { show: true, position: "top", color: '#fff', fontWeight: 'normal' },
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#F2F4F6" },
                  { offset: 0.5, color: "#E9D9C1" },
                  { offset: 1, color: "#DDB477" }
                ])
              }
            },
            barWidth: 12,
            barGap: 0.2,
            data: this.xdata
          }
        ]
      },
      list: [],

    }
  },
  mounted: function () {
    this.QueryDeviceByMonth();
  },
  methods: {
    showEharts() {
      setTimeout(() => {
        let chart = echarts.init(document.getElementById('chart'))
        this.option1.xAxis.data = [];
        this.option1.series[0].data = [];
        this.option1.series[0].data = this.xdata;
        this.list.forEach(el => {
          this.option1.xAxis.data.push(el.name)
        });
        chart.setOption(this.option1)
      }, 200);
    },
    QueryDeviceByMonth() {
      this.$axios.post(`visual/home/QueryDeviceByMonth`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
          this.setxdata();
          this.showEharts()
        }
      })
    },
    setxdata() {
      this.xdata = [];
      this.list.forEach(el => {
        this.xdata.push(el.count)
      });
      const maxVal = Math.floor(Math.max(...this.xdata));
      this.xdata = this.xdata.map(item => {
        let str = 'path://'; //path字符串
        let step = item / maxVal * 20; //步数，即要绘制多少个圆角矩形，可以自定义
        let pos = 0; //从0位置开始
        for (let i = 0; i < step; i++) {
          str += `M0 ${pos + 10} A10 10 0 0 1 10 ${pos} H90 A10 10 0 0 1 100 ${pos + 10} V${pos + 30} A10 10 0 0 1 90 ${pos + 40} H10 A10 10 0 0 1 0 ${pos + 30} V${pos + 10} `;
          pos += 50;
        }
        str += 'z'; //闭合路径
        return {
          value: item,
          symbolClip: true, //为每个柱设置形状
          symbol: str
        }
      })
      this.timer++;
    },
  }
}
</script>
<style scoped>
.installPage {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.bar-box {
  width: 100%;
  height: 200px;
}
#chart {
  width: 100%;
  height: 250px;
}
</style>
