<template>
<bm-control :offset="{ width: offset.x, height: offset.y }">
  <div class="map-tool-container">
    <div :class="['item', mapType == 'BMAP_NORMAL_MAP' ? 'active' : '']" @click="switchMapType('BMAP_NORMAL_MAP')"> 地图 </div>
    <div :class="['item', mapType == 'BMAP_SATELLITE_MAP' ? 'active' : '']" @click="switchMapType('BMAP_SATELLITE_MAP')"> 卫星 </div>
  </div>
</bm-control>
</template>
<script>
import { BmControl } from "@/components/vue-baidu-map-v3/components";
export default {
  name: "ToolMapTypeSelect",
  components: {
    BmControl,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: String,
      default: 'BMAP_NORMAL_MAP'
    },
    offset: {
      type: Object,
      default(){return {x: 0, y: 0}}
    }
  },
  data() {
    return {
      mapType: this.value,
    };
  },
  computed: {
  },
  watch: {
    value(newVal){
      this.mapType = newVal;
    },
    mapType(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  methods: {
    switchMapType: function (type) {
      this.mapType = type;
    },
  },
};
</script>
<style scoped>
.map-tool-container {
  display: flex;
  border: solid 1px #1b5fa8;
  border-radius: 3px;
  background-color: white;
  /* height: 26px; */
}
.map-tool-container .item {
  width: 50px;
  height: 26px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: solid 1px #1b5fa8;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.map-tool-container .active {
  background-color: #1b5fa8;
  color: white;
}
</style>