<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">外接项目详情</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="200">
      <FormItem prop="name" label="项目名称" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" placeholder="项目名称" style="width:300px; margin-right: 38px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="name" label="地址" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.area" placeholder="地址" disabled style="width:200px; margin-right: 38px;"></Input>
          经度：<Input type="text" v-model="form.lng" placeholder="经度" disabled style="width:200px; margin-right: 38px;"></Input>
          纬度：<Input type="text" v-model="form.lat" placeholder="纬度" disabled style="width:200px; margin-right: 38px;"></Input>
          <Button type="primary" @click="setMap">设置</Button>
        </div>
      </FormItem>
      <FormItem prop="lightAfterPower" label="改造后单灯平均功率" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.lightAfterPower" placeholder="改造后单灯平均功率" style="width:300px; margin-right: 38px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="lightBeforePower" label="改造前单灯平均功率" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.lightBeforePower" placeholder="改造前单灯平均功率" style="width:300px; margin-right: 38px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="lightOnMinutes" label="平均每日亮灯时长/分钟" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.lightOnMinutes" placeholder="平均每日亮灯时长" style="width:300px; margin-right: 38px;"></Input>
        </div>
      </FormItem>
      <FormItem prop="price" label="项目平均电价/元" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.price" placeholder="项目平均电价" style="width:300px; margin-right: 38px;"></Input>
        </div>
      </FormItem>
      <!-- <FormItem prop="timeId" label="默认时间表" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.timeId" placeholder="默认时间表" style="width:300px; margin-right: 38px;"></Input>
        </div>
      </FormItem> -->
      <!-- <FormItem prop="timeId" label="默认时间表">
        <Select placeholder="请选择" v-model="form.timeId" style="width:300px;">
          <Option v-for="(item, idx) in timeArr" :key="idx" :value="item.id">{{item.name}}</Option>
        </Select>
      </FormItem> -->
      <!-- <FormItem prop="timeId" label="默认时间表" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.timeId" placeholder="默认时间表" style="width:300px; margin-right: 38px;"></Input>
        </div>
      </FormItem> -->
    </Form>
    <ModalSelectMapPoint v-model="showMapModal" :custCenter="{ lat: form.lat, lng: form.lng}" @posSelected="posSelected" />
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>

import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'virtualEdit',
  components: {
    ModalSelectMapPoint
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      showMapModal: false,
      timeArr: [],
      form: {
        "id": 0,
        "name": "", //项目名称
        "area": "北京市", //项目城市
        "lat": 22.12345, //项目纬度
        "lng": 122.12345, //项目经度
        "lightAfterPower": 100, //改造后单灯平均功率
        "lightBeforePower": 250, //改造前单灯平均功率
        "lightOnMinutes": 888, //平均每日亮灯时长，单位分钟
        "price": 1, //项目平均电价
        "timeId":1 //默认时间表
      },
      rules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // this.timeAjax();
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          this.$set(this, 'form', this.item.data);
        } else {
          this.form.id = 0;
          this.form.name = "", //项目名称
            this.form.area = "北京市", //项目城市
            this.form.lat = 39.86518326192369, //项目纬度
            this.form.lng = 116.42948664410353, //项目经度
            this.form.lightAfterPower = 100, //改造后单灯平均功率
            this.form.lightBeforePower = 250, //改造前单灯平均功率
            this.form.lightOnMinutes = 888, //平均每日亮灯时长，单位分钟
            this.form.price = 1, //项目平均电价
            this.form.timeId = 1 //默认时间表
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['newLightTypes', 'deviceTypes', 'productCodes', 'bigTypes', 'smallTypes']),
    ...mapGetters('auth', ['showLang']),

  },
  mounted: function () {
  },
  methods: {
    posSelected(el) {
      this.form.lng = el.lng;
      this.form.lat = el.lat;
      this.form.area = el.city;
    },
    setMap() {
      this.showMapModal = true;
    },
    timeAjax() {
      this.$axios.post(`visual/home/QueryTimeList`, this.form).then(res => {
        if (res.code == 0) {
          this.$set(this, 'timeArr', res.data);
        }
      })
    },
    ok: async function () {
      // if(this.form.timeId==0){
      //   this.$Message.error('请选择时间表');
      //   return false
      // }
      this.$axios.post(`visual/home/SaveVirtualProject`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
        }
      })
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>